import React, { Component } from 'react';
import { connect } from 'react-redux';
import Slider from 'rc-slider';
import SelectBox from 'components/SelectBox/SelectBox';
import CODES from 'assets/json/CountryCodes.json';
import MODES from 'assets/json/Modes.json';
import INMOTION from 'assets/json/Inmotion.json';
import NOMOTION from 'assets/json/Nomotion.json';
import REPORTING from 'assets/json/Reporting.json';
import SAMPLING from 'assets/json/Sampling.json';
import ALERT_INTERVALS from 'assets/json/AlertIntervals.json';
import ALERT_INCREMENTS from 'assets/json/AlertIncrements.json';
import { toCelsius, toFahrenheit, formatPhone, roundOff, getDate, getSplitTimeConfig, getShortCode, getLastTwoValues } from 'utility/utility';
import 'rc-slider/assets/index.css';
import MetricConfiguration from 'components/Configuration/MetricConfiguration';
import TrashIcon from 'assets/images/Trash';
import EditIcon from 'assets/images/EditIcon';
import defaultProfile from 'assets/json/defaultProfile.json';
import SweetAlert from '../SweetAlert/SweetAlert';
import GeofenceLocationIcon from 'assets/images/geo_fence_loc';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { DateRange } from 'react-date-range';
import { debounce } from 'lodash';
import { getShipmentLocation } from "redux-store/actions";
const LOCATION_MAP = {origin: 'Origin', stop: 'Stop', destination: 'Destination'};
class ShipmentProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEmptyProductsMessage: false,
            defaultTimeInterval:'30',
            defaultTimeIncrement:'20',
            config: {
                maxGeofences: 10,
                maxCarrierTypes: 100,
                maxUsers: 15,
                maxStops: 10,
                maxReportUserLimit: 20,
            },
            shipmentProfileDetails: {
                config: {
                    geofences: [],
                    legs: [{leg_type: 'origin'},{leg_type: 'destination'}],
                    report_configuration: {
                        report_type: 'CSV',
                        range_type: "FULL",
                        date_range: {startDate: null, endDate: null, key: 'selection'},
                        // from_date: new Date(this.props.shipmentCreatedDate),
                        // to_date: new Date(),
                        sensors: {
                            "Location": true, 
                            "Mode": true, 
                            "Temperature": true, 
                            "MKT": true, 
                            "Humidity": true, 
                            "Light": true, 
                            "Shock": true, 
                            "Tilt": true, 
                            "Battery": true,
                            "all": true
                        },
                        time_zone_diff: moment().utcOffset(),
                        time_zone_code: moment.tz.guess(),
                        occurrence: "ShipmentCompletion",
                        notification_users: []
                    },
                },
            },
            users: this.props.users || [],
            selectedUsers: [],
            addUserSearch: '',
            maxUserLimit: 20,
            showDateRange: false,
            timeZones: momentTZ.tz.names().map(tz => {return {label: `(GMT${moment.tz(tz).format('Z')}) ${tz}`, id: tz, value: moment.tz(tz)._offset}}),
            switchToRoundTrip: false,
            showConfigurationProfileDetails: false,
        }
    }

    componentDidMount () {
        this.setState({shipmentProfileDetails: this.props.SHIPMENT_PROFILE_DETAILS});
    }
    
    handleShipmentProfileInputChange = (e) => {
        const shipmentProfileDetails = this.props.SHIPMENT_PROFILE_DETAILS;
        shipmentProfileDetails.config.updated = true;
        const fieldName = e.target.name;
        let val = e.target.value;

        if(fieldName === 'receiver_country_code'){ 
            val = val.replace(/[\+\s]/g, '');
        } else if(fieldName === 'receiver_contact_number'){ 
            const value = val.replace(/[^a-zA-Z0-9]/g, '');
            val = parseInt(value);
        }
        
        shipmentProfileDetails.config[fieldName] = val;
        this.setState({shipmentProfileDetails});
    }

    toggleCustomGeofence = (id, type) => {
        const {shipmentProfileDetails} = this.state;
        shipmentProfileDetails.config.updated = true;
        const geofenceIndex = shipmentProfileDetails.config.geofences.findIndex(gf => gf.id === id);
        if(shipmentProfileDetails.config.id && shipmentProfileDetails.config.geofences[geofenceIndex].id) {
            shipmentProfileDetails.config.geofences[geofenceIndex].delete = type;
        } else {
            shipmentProfileDetails.config.geofences.splice(geofenceIndex, 1);
        }        
        this.setState({shipmentProfileDetails});
    }

    /*START: STOPS*/
    addStop = () => {
        const {shipmentProfileDetails} = this.state;
        let stopsCount = shipmentProfileDetails.config.legs.filter(s => !s.delete).length-1;
        let newStopName = `Stop ${stopsCount}`;
        const stopNames = shipmentProfileDetails.config.legs.map(s => { if(!s.delete) { return (s.stop_name||'').toLowerCase()} return ''; });

        while(stopNames.indexOf(newStopName.toLowerCase())>=0) {
            newStopName = `Stop ${stopsCount++}`
        }

        const lastLegIndex = shipmentProfileDetails.config.legs.findLastIndex(s => !s.delete && s.leg_type !== 'destination');
        const legConfig = JSON.parse(JSON.stringify(shipmentProfileDetails.config.legs[lastLegIndex]));
        legConfig.due_date = getSplitTimeConfig(0);
        
        const newLeg = {
            mode: 'road',
            ...legConfig,

            edit_config: true,
            edit: true,
            leg_type: "stop",
            stop_name: newStopName,
            formatted_address: '',
            latitude: '',
            longitude: '',
        };

        delete(newLeg.id);

        shipmentProfileDetails.config.legs.splice(shipmentProfileDetails.config.legs.length-1, 0, newLeg);
        this.setState({shipmentProfileDetails});
    }

    getLocations = debounce(async (searchStr, legIndex, callback) => {
        const {shipmentProfileDetails} = this.state;
        const setSuggestions = (addressSuggestions) => {
            if (legIndex === 'geofence_location') {
                this.setState({
                    geofenceSuggestions: addressSuggestions.features
                });
            } else {
                shipmentProfileDetails.config.legs[legIndex].addressSuggestions = addressSuggestions.features;
                this.setState({shipmentProfileDetails});
            }
        };
        if (searchStr && searchStr.length >= 3) {
            this.props.getShipmentLocation({
                location: searchStr,
                onComplete: (data) => {
                    if (data.hasOwnProperty('predictions')) {
                        if (data.predictions && data.predictions.length) {
                            const locationSuggestions = data.predictions.map(element => {
                                const item = {
                                    text: element.description,
                                    place_name: element.description,
                                    properties: { short_code: '' },
                                    short_address: element.terms.length ? getLastTwoValues(element.terms) : ''
                                };
                                return item;
                            });
                            setSuggestions({ features: locationSuggestions });
                        } else {
                            setSuggestions({ features: [] });
                        }
                    } else {
                        if (
                            data.features &&
                            data.features.length > 0
                        ) {
                            setSuggestions(data);
                        } else {
                            setSuggestions({ features: [] });
                        }
                    }
                }
            });
        } else {
            setSuggestions({ features: [] });
        }
        
    }, 250);

    handleInputChange = (e) => {
        const fieldType = e.target.dataset.type;

        if(fieldType) {
            const val = e.target.value;
            const {shipmentProfileDetails} = this.state;
            const index = e.target.dataset.index;
            const legs = shipmentProfileDetails.config.legs;
            if((fieldType === 'stop' || fieldType === 'origin' || fieldType === 'destination')) {                
                legs[index].formatted_address = val;
                legs[index].latitude = "";
                legs[index].longitude = "";
                legs[index].short_address = "";
            } else if(fieldType === 'stop_name'){
                shipmentProfileDetails.config.legs[index].stop_name = val;
            }
            this.setState({shipmentProfileDetails});
        }       
    }

    setNewLocation = async (location, legIndex) => {
        //console.log(location, index);
        setTimeout(async () => {
            const {shipmentProfileDetails} = this.state;

            if(location.center) {
                shipmentProfileDetails.config.legs[legIndex].latitude = location.center[1] + '';
                shipmentProfileDetails.config.legs[legIndex].longitude = location.center[0] + '';
            } else {
                this.props.getShipmentLocation({
                    location : location.place_name,
                    geolocation: true,
                    onComplete: (DATA) => {
                        const { results } = DATA;
                        const locationDetails = {latitude: '', longitude: ''};
                        if(results.length) {
                            locationDetails.latitude = results[0].geometry.location.lat;
                            locationDetails.longitude = results[0].geometry.location.lng;
                        }                        

                        shipmentProfileDetails.config.legs[legIndex].latitude = locationDetails.latitude + '';
                        shipmentProfileDetails.config.legs[legIndex].longitude = locationDetails.longitude + '';
                    }
                });
            }
            shipmentProfileDetails.config.legs[legIndex].short_address = getShortCode(location);
            shipmentProfileDetails.config.legs[legIndex].formatted_address = location.place_name;

            delete(shipmentProfileDetails.config.legs[legIndex].edited);
            delete(shipmentProfileDetails.config.legs[legIndex].edit);

            if(shipmentProfileDetails.config.round_trip) {
                if(shipmentProfileDetails.config.legs[legIndex].leg_type === 'destination') {
                    shipmentProfileDetails.config.legs[0].latitude = shipmentProfileDetails.config.legs[legIndex].latitude + '';
                    shipmentProfileDetails.config.legs[0].longitude = shipmentProfileDetails.config.legs[legIndex].longitude + '';
                    shipmentProfileDetails.config.legs[0].formatted_address = location.place_name;
                    shipmentProfileDetails.config.legs[0].short_address = getShortCode(location);
                    
                    delete(shipmentProfileDetails.config.legs[0].edited);
                    delete(shipmentProfileDetails.config.legs[0].edit);
                    
                } else if(shipmentProfileDetails.config.legs[legIndex].leg_type === 'origin') {
                    const destIndex = shipmentProfileDetails.config.legs.length-1;
                    shipmentProfileDetails.config.legs[destIndex].latitude = shipmentProfileDetails.config.legs[legIndex].latitude + '';
                    shipmentProfileDetails.config.legs[destIndex].longitude = shipmentProfileDetails.config.legs[legIndex].longitude + '';
                    shipmentProfileDetails.config.legs[destIndex].formatted_address = location.place_name;
                    shipmentProfileDetails.config.legs[destIndex].short_address = getShortCode(location);
                    
                    delete(shipmentProfileDetails.config.legs[destIndex].edited);
                    delete(shipmentProfileDetails.config.legs[destIndex].edit);
                }
                
            }

            shipmentProfileDetails.config.updated = true;
            shipmentProfileDetails.config.legs.map(leg => {
                leg.addressSuggestions = [];
            });
            if(shipmentProfileDetails.errors?.[`legs.${legIndex}.formatted_address`]) {
                delete(shipmentProfileDetails.errors[`legs.${legIndex}.formatted_address`]);
            }

            this.setState({shipmentProfileDetails});
        }, 10);

    };

    modifySourceDestination = async (e, legIndex) => {
        const eleClass = e.target.className;
        const isDueDate = eleClass.indexOf('tol dhm') >= 0;
        const {shipmentProfileDetails} = this.state;
        shipmentProfileDetails.config.legs[legIndex] = shipmentProfileDetails.config.legs[legIndex] || {};

        if(eleClass === 'done-icn') {

            if(shipmentProfileDetails.config.legs[legIndex].lat && shipmentProfileDetails.config.legs[legIndex].long && shipmentProfileDetails.config.legs[legIndex].formatted_address && shipmentProfileDetails.config.legs[legIndex].short_address) {
                if(shipmentProfileDetails.config.legs[legIndex].edited) {}
                else {
                    shipmentProfileDetails.config.legs[legIndex].edit = false;
                    shipmentProfileDetails.config.legs[legIndex].error = false;
                }    
            } else {
                shipmentProfileDetails.config.legs[legIndex].edit = true;
                shipmentProfileDetails.config.legs[legIndex].error = true;
            }
            
        } else {
            if(isDueDate) {} 
            else {
                shipmentProfileDetails.config.legs[legIndex].edit = true;
            }
        }
        shipmentProfileDetails.config.updated = true;
        await this.setState({shipmentProfileDetails});

        // if(shipmentProfileDetails.config.legs[legIndex].edit && !isDueDate ) {
        //     document.getElementById(`${shipmentProfileDetails.config.legs[legIndex].leg_type}_location_${legIndex}`).focus();
        // }
        document.getElementById('search_config').value = '';
    }

    switchToRoundTrip = async (confirm) => {
        const {shipmentProfileDetails} = this.state;
        const isRoundTrip = shipmentProfileDetails.config.round_trip;

        const legs = shipmentProfileDetails.config.legs || [];
        let legsCount = 0;

        legs.forEach(leg => {
            if(leg.delete !== 1){
                legsCount++;
            }
        });
        
        /*IT is round trip need to switch to regular*/
        if(isRoundTrip) {
            //debugger;
            const LEGS = legs.filter(s=>!s.delete);
            if(LEGS?.length && (!LEGS[0].error || !LEGS[0].nameEmptyError)) {
                shipmentProfileDetails.config.legs[shipmentProfileDetails.config.legs.length-1].latitude = LEGS[1].latitude + '';
                shipmentProfileDetails.config.legs[shipmentProfileDetails.config.legs.length-1].longitude = LEGS[1].longitude + '';
                shipmentProfileDetails.config.legs[shipmentProfileDetails.config.legs.length-1].formatted_address = LEGS[1].formatted_address;

                let shortTextAddress = null;
                await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${LEGS[0].longitude},${LEGS[0].latitude}.json?access_token=${process.env.REACT_APP_mapboxAccessCode}`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                }).then(response => response.json())
                .then(addressSuggestions => {                    
                    shortTextAddress = getShortCode(addressSuggestions.features[0]);
                });
                //console.log('shortTextAddress: ', shortTextAddress);
                shipmentProfileDetails.config.legs[shipmentProfileDetails.config.legs.length-1].short_address = LEGS[1].short_address || shortTextAddress;
            }

            legs.forEach((leg, index) => {
                if(leg.delete !== 1 && leg.leg_type === 'stop'){
                    if((shipmentProfileDetails.config.device_configuration_profile_id || shipmentProfileDetails.configuration_profile_id)) {
                        leg.delete = 1;
                    }                    
                }
            });
        } 
        /*IT is regular need to switch to round trip*/
        else {
            //const stop = {...this.state.shipmentProfileDetails.destination};
            if(legsCount === 0 && !confirm) {
                legs.forEach((leg, index) => {
                    if(leg.delete !== 1 && leg.leg_type==='stop'){
                        if((shipmentProfileDetails.config.device_configuration_profile_id || shipmentProfileDetails.configuration_profile_id)) {
                            leg.delete = 1;
                        }
                    }
                });
                if(shipmentProfileDetails.config.legs?.length > 1 && (shipmentProfileDetails.config.legs[shipmentProfileDetails.config.legs.length-1].latitude+'') === (shipmentProfileDetails.config.legs[0].latitude+'') && (shipmentProfileDetails.config.legs[shipmentProfileDetails.config.legs.length-1].longitude+'') === (shipmentProfileDetails.config.legs[0].longitude+'')) {}
                else {
                    legs.splice(1, 0, {
                        ...shipmentProfileDetails.config.legs[0],
                        formatted_address: shipmentProfileDetails.config.legs[shipmentProfileDetails.config.legs.length-1].formatted_address,
                        short_address: shipmentProfileDetails.config.legs[shipmentProfileDetails.config.legs.length-1].short_address,
                        latitude: shipmentProfileDetails.config.legs[shipmentProfileDetails.config.legs.length-1].latitude + '', 
                        longitude: shipmentProfileDetails.config.legs[shipmentProfileDetails.config.legs.length-1].longitude + '',
                        stop_name: "Stop 1",
                        leg_type: "stop"
                    });
                    delete(legs[1].id);
                }

            } else {
                if(confirm) {
                    legs.forEach((leg, index) => {
                        if(leg.delete !== 1 && leg.leg_type === 'stop'){
                            if((shipmentProfileDetails.config.device_configuration_profile_id || shipmentProfileDetails.configuration_profile_id)) {
                                leg.delete = 1;
                            }
                        }
                    });
                    legs.splice(1, 0, {
                        ...shipmentProfileDetails.config.legs[0],
                        formatted_address: shipmentProfileDetails.config.legs[shipmentProfileDetails.config.legs.length-1].formatted_address,
                        short_address: shipmentProfileDetails.config.legs[shipmentProfileDetails.config.legs.length-1].short_address,
                        latitude: shipmentProfileDetails.config.legs[shipmentProfileDetails.config.legs.length-1].latitude + '', 
                        longitude: shipmentProfileDetails.config.legs[shipmentProfileDetails.config.legs.length-1].longitude + '',
                        stop_name: "Stop 1",
                        leg_type: "stop",
                    });
                    delete(legs[1].id);
                } else {
                    this.setState({switchToRoundTrip: true});
                    return false;
                }                
            }
            shipmentProfileDetails.config.legs[shipmentProfileDetails.config.legs.length-1].latitude = shipmentProfileDetails.config.legs[0].latitude;
            shipmentProfileDetails.config.legs[shipmentProfileDetails.config.legs.length-1].longitude = shipmentProfileDetails.config.legs[0].longitude;
            shipmentProfileDetails.config.legs[shipmentProfileDetails.config.legs.length-1].formatted_address = shipmentProfileDetails.config.legs[0].formatted_address;
            shipmentProfileDetails.config.legs[shipmentProfileDetails.config.legs.length-1].short_address = shipmentProfileDetails.config.legs[0].short_address;

        }

        if(shipmentProfileDetails.config.legs[shipmentProfileDetails.config.legs.length-1].formatted_address && shipmentProfileDetails.config.legs[0].formatted_address) {
        } else {
            shipmentProfileDetails.config.legs[shipmentProfileDetails.config.legs.length-1].edit = true;
            shipmentProfileDetails.config.legs[shipmentProfileDetails.config.legs.length-1].error = true;

            if(shipmentProfileDetails.config.legs[1].formatted_address) {}
            else {
                shipmentProfileDetails.config.legs[1].edit = true;
                shipmentProfileDetails.config.legs[1].error = true;
            }
        }

        shipmentProfileDetails.config.round_trip = isRoundTrip ? 0 : 1;
        //shipmentProfileDetails.stops = stops;  
        shipmentProfileDetails.config.updated = true;      

        this.setState({shipmentProfileDetails, switchToRoundTrip: false});
    }

    closeCarrierTypePopup = (e) => {
        setTimeout(() => {
            if(e.target.closest('.sel-item')){}
            else {
                const {shipmentProfileDetails} = this.state;
                shipmentProfileDetails.config.legs.map(leg => {
                    delete(leg.showCarrierTypePopup);
                    //delete(leg.showCarrierUpdate);
                });
                this.setState({shipmentProfileDetails});
                document.body.removeEventListener('click', this.closeCarrierTypePopup);
            }
        }, 10);
    }

    toggleLegConfig = (legIndex) => {
        const {shipmentProfileDetails} = this.state;
        shipmentProfileDetails.config.legs[legIndex].edit_config = !shipmentProfileDetails.config.legs[legIndex].edit_config;
        this.setState({shipmentProfileDetails});
    }

    stepChangeValue = (e, direction) => {
        if(this.props.disableEdit) {}
        else{
            const {shipmentProfileDetails} = this.state;
            let value = shipmentProfileDetails.config.geolocation_distance || 0;

            if(
                (value >= 10000 && direction === 'p') || 
                (value <= 100 && direction === 'm')
            ){}
            else {
                if(direction === 'p') {
                    value++;
                } else if(direction === 'm') {
                    value--;
                }
            }
            shipmentProfileDetails.config.geolocation_distance = value;
            shipmentProfileDetails.config.updated = true;
            this.setState({shipmentProfileDetails});
        }
    }

    render () {
        const SHIPMENT_PROFILE_DETAILS = {...defaultProfile, ...this.state.shipmentProfileDetails, ...this.props.SHIPMENT_PROFILE_DETAILS};
        const CONFIGURATION_PROFILE_DETAILS = {...this.props.CONFIGURATION_PROFILE_DETAILS};

        const REPORT_USERS = this.state.users.filter(user => {
            let validUser = `${user.email}`.toLowerCase().indexOf(SHIPMENT_PROFILE_DETAILS.config.report_configuration?.addUserSearch?.toLowerCase()) >= 0;
            if(validUser && user.deleted === 0) return user;
            return false;
        }).slice(0,6);

        const LEG_USERS = [];
        for(let legIndex=0; legIndex<SHIPMENT_PROFILE_DETAILS?.config?.legs?.length||0; legIndex++){
            LEG_USERS[legIndex] = this.state.users.filter(user => {
                let validUser = `${user.email}`.toLowerCase().indexOf(SHIPMENT_PROFILE_DETAILS.config.legs[legIndex]?.addUserSearch?.toLowerCase()) >= 0;
                if(validUser && user.deleted === 0) return user;
                return false;
            }).slice(0,6);
        }
        
        return (
            <>
            <SweetAlert
                show={this.state.showEmptyProductsMessage}
                title={'No active product profile available. Create a product profile to enable this feature'}
                status={'warning'}
                className="empty-products-warning"
                onClose={() => {
                    this.setState({ showEmptyProductsMessage: false });
                }}
            />
            <SweetAlert
                    title={<>Round trip can only have one stop. Enabling this will delete the additional stops. <br/>Please confirm to proceed?</>}
                    status='warning'
                    className="round-trip-s-p-warning"
                    show={this.state.switchToRoundTrip}
                    showCancelBtn={true}
                    cancelBtnText="CANCEL"
                    confirmBtnText="CONFIRM"
                    onCancelClick={() => {
                        this.setState({
                            switchToRoundTrip: false
                        })
                    }}
                    onConfirmClick={()=>{
                        this.switchToRoundTrip(true);                 
                    }}
                />
            <div className="fld-sec ship-details-sec">
                <div className="i-sec">
                    <input
                        id="shipment_description"
                        type="text"
                        autoComplete="off"
                        className="i-fld"
                        placeholder=" "
                        onChange={this.handleShipmentProfileInputChange}
                        name="shipment_description"
                        maxLength="32"
                        value={SHIPMENT_PROFILE_DETAILS.config.shipment_description || ''}
                        readOnly={this.props.disableEdit}
                    />
                    <label htmlFor="shipment_description">Description <span>(Character limit 32)</span></label>
                    {
                        SHIPMENT_PROFILE_DETAILS.config.errors?.shipment_description ?
                        <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors.shipment_description}</div>
                        : ''
                    }                                                
                </div>
                <div className="i-sec" style={{paddingBottom: '16px'}}>
                    <div className="f-j-s-b">
                        <SelectBox
                            css={'sort-lbl i-fld p-dc'}
                            popCss={'left'}
                            name="receiver_country_code"
                            id="receiver_country_code"
                            value={SHIPMENT_PROFILE_DETAILS.config.receiver_country_code || '1'}
                            onChange={e => this.handleShipmentProfileInputChange({target: {name: 'receiver_country_code', value: e.id}})}
                            list={CODES.map(c=>({label: c.dial_code, id: c.dial_code.replace(/[\+\s]/g,'')}))}
                            disable={this.props.disableEdit}
                        >
                        </SelectBox>
                        <input
                            id="receiver_contact"
                            type="text"
                            className="i-fld contact"
                            autoComplete="off"
                            placeholder=" "
                            maxLength="19"
                            onChange={this.handleShipmentProfileInputChange}
                            name="receiver_contact_number"
                            value={formatPhone(SHIPMENT_PROFILE_DETAILS.config.receiver_contact_number)}
                            readOnly={this.props.disableEdit}
                        />
                        <label htmlFor="receiver_contact">Receiver contact number</label>
                    </div>
                    {
                        SHIPMENT_PROFILE_DETAILS.config.errors?.receiver_contact_number ?
                        <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors.receiver_contact_number}</div>
                        : ''
                    }
                </div>
                <div className="i-sec">
                    <input
                        id="receiver_email"
                        type="text"
                        autoComplete="off"
                        className="i-fld"
                        placeholder=" "
                        onChange={this.handleShipmentProfileInputChange}
                        name="receiver_email"
                        maxLength="70"
                        value={SHIPMENT_PROFILE_DETAILS.config.receiver_email || ''}
                        readOnly={this.props.disableEdit}
                    />
                    <label htmlFor="receiver_email">Receiver email</label>
                    {
                        SHIPMENT_PROFILE_DETAILS.config.errors?.receiver_email ?
                        <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors.receiver_email}</div>
                        : ''
                    }                    
                </div>
                <div className="i-sec b-b">
                    <div className="r-t">
                        <div className="fld-lbl">Round trip</div>
                        <div className={`status-bar${SHIPMENT_PROFILE_DETAILS.config.round_trip ? ' a' : ''}${this.props.disableEdit ? ' d': ''}`}
                            onClick={ (e) => {
                                e.stopPropagation();
                                if(this.props.disableEdit) {}
                                else {
                                    this.switchToRoundTrip();
                                }                                
                            }}
                        > {/* SHIPMENT_PROFILE_DETAILS.config.round_trip ? '' : ' d t-tip'*/}{/*SHIPMENT_PROFILE_DETAILS.config.round_trip ? '' : <span className='t-txt'>Will be enabled after source and destination added.</span>*/}</div>
                    </div>
                    {
                        SHIPMENT_PROFILE_DETAILS.config.errors?.round_trip ?
                        <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors.round_trip}</div>
                        : ''
                    }
                </div>
                <div className="i-sec b-b" style={{paddingTop:'20px'}}>
                    <div className="r-t">
                        <div className="fld-lbl" style={{width: "280px"}}>Device configuration profile</div>
                        <div className="d-f-a-c">
                            <SelectBox
                                useProps
                                allowSearch
                                css={'d-c-ip'}
                                list={this.props.configurationProfileList.map(dcp => { return {id: dcp.id, label: dcp.profile_name}})}
                                value={SHIPMENT_PROFILE_DETAILS.config.device_configuration_profile_id || ''}
                                onChange={(o) => this.handleShipmentProfileInputChange({target: {value: o.id, name: 'device_configuration_profile_id'}})}
                                disable={this.props.disableEdit}
                            >
                            </SelectBox>
                            {
                                this.props.disableEdit ? 
                                <>
                                {
                                CONFIGURATION_PROFILE_DETAILS.show ?
                                <span 
                                    className="material-icons a-arr"
                                    style={{marginLeft:'10px'}}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.props.getConfigurationProfileDetails({});
                                    }}
                                >remove_circle_outline</span>
                                :
                                <span 
                                    className="material-icons a-arr"
                                    style={this.props.configurationProfileLoading ? {marginLeft: '10px', opacity: 0.5, cursor: 'not-allowed'} : {marginLeft:'10px'}}
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        if(this.props.configurationProfileLoading){}
                                        else {
                                            this.props.getConfigurationProfileDetails({id:SHIPMENT_PROFILE_DETAILS.config.device_configuration_profile_id || SHIPMENT_PROFILE_DETAILS.config.configuration_profile_id});
                                        }
                                    }}
                                >add_circle_outline</span>
                                }
                                </>
                                :
                                <span
                                    className="material-icons-outlined visibility"
                                    style={this.props.configurationProfileList?.length && SHIPMENT_PROFILE_DETAILS.config.device_configuration_profile_id ? {marginLeft: '20px'} : {marginLeft: '20px', cursor: 'not-allowed', opacity: '0.6'}}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if(this.props.configurationProfileList?.length && SHIPMENT_PROFILE_DETAILS.config.device_configuration_profile_id) {
                                            this.props.showDeviceConfiguration(SHIPMENT_PROFILE_DETAILS);
                                        }
                                    }}
                                >
                                    visibility
                                </span>
                            }
                        </div>
                    </div>
                    {
                        SHIPMENT_PROFILE_DETAILS.config.errors?.device_configuration_profile_id ?
                        <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors.device_configuration_profile_id}</div>
                        : ''
                    }
                    {this.props.configurationProfileLoading ? <div className="loading abs" /> : ''}
                    {
                    CONFIGURATION_PROFILE_DETAILS.show ?
                    <div className="ship-profile-config-det">                    
                    <MetricConfiguration 
                        CONFIGURATION_PROFILE_DETAILS={{...CONFIGURATION_PROFILE_DETAILS}}
                        key={`${CONFIGURATION_PROFILE_DETAILS.id}_${CONFIGURATION_PROFILE_DETAILS.company_id}`}
                        showProductProfile={false}
                        isShipmentConfiguration={true}
                        isProductProfileShipment={CONFIGURATION_PROFILE_DETAILS.product_profile_id}
                        productProfileList={this.props.productProfileList}
                        disableEdit={true}
                    />
                    </div>
                    : <></>
                    }
                </div>
            </div>
            <div className="fld-sec v-scroll config ship-det">
                <div className="i-sec sec-hdr" style={{paddingLeft:"32px"}}>
                    Legs
                </div>
                <div className="ship-src-dest vert">
                    <div className="ship-path">
                        {
                            SHIPMENT_PROFILE_DETAILS.config.legs.map((leg, legIndex) => {
                                if(leg.delete) {
                                    return <></>;
                                }
                                return (
                                    <>
                                    <div 
                                        className={`i-address ${leg.leg_type==='origin' ? 'src' : leg.leg_type==='destination' ? 'dest': 'stp'} editable`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if(!leg.edit && !this.props.disableEdit) {
                                                this.modifySourceDestination(e, legIndex);
                                            }
                                        }}
                                    >
                                        {leg.leg_type==='origin' ? <div className="ship-src"/> : leg.leg_type==='destination' ? <><div className="ship-dest"><span className="icn loc-icn"/></div><div className="w-brdr"/></> : <div className={`stop-box${SHIPMENT_PROFILE_DETAILS.config.round_trip ? ' r-t' : ''}`}/>}
                                        <div className="l i-loc">
                                            <div className="l2">
                                            {
                                            leg.leg_type==='origin' ? 'ORIGIN' : 
                                            leg.leg_type==='destination' ? "DESTINATION": 
                                            !(leg.edit || (SHIPMENT_PROFILE_DETAILS.config.errors && SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.stop_name`])) ?
                                            leg.stop_name || `Stop ${leg.stop_name}`
                                            : <></>
                                            }
                                            </div>
                                            {leg.leg_type==='stop' ? 
                                                <span 
                                                    className="del-icn" 
                                                    title="Delete"
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        if(this.props.disableEdit) {}
                                                        else {
                                                            const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                            shipmentProfileDetails.config.updated = true;
                                                            shipmentProfileDetails.config.legs[legIndex].delete = 1;
                                                            this.setState({shipmentProfileDetails});
                                                        }
                                                    }}
                                                ></span> : <></>
                                            }
                                            {
                                            leg.edit || (SHIPMENT_PROFILE_DETAILS.config.errors && SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.stop_name`]) ?
                                            <>
                                            {
                                            leg.leg_type === 'stop' ?
                                            <div className="i-fld-c">
                                                <input 
                                                    type="text" 
                                                    autoComplete="off"
                                                    className="i-fld" 
                                                    placeholder="Stop Name" 
                                                    id={`stop_name_${legIndex}`} 
                                                    name={`stop_name_${legIndex}`} 
                                                    value={leg.stop_name}
                                                    onChange={this.handleInputChange}
                                                    data-index={legIndex}
                                                    data-type={'stop_name'}
                                                    maxLength="32"
                                                    readOnly={this.props.disableEdit}
                                                />
                                                {
                                                    SHIPMENT_PROFILE_DETAILS.config.errors && SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.stop_name`] ?
                                                    <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.stop_name`]}</div>
                                                    : ''
                                                }
                                            </div>
                                            : <></>
                                            }
                                            <div className="i-fld-c">
                                                <input 
                                                    type="text" 
                                                    autoComplete="off"
                                                    className="i-fld" 
                                                    placeholder={`${LOCATION_MAP[leg.leg_type]} Location`}
                                                    id={`${leg.leg_type}_location_${legIndex}`}
                                                    name={`${leg.leg_type}_location_${legIndex}`}
                                                    onChange={(e) => {
                                                            this.handleInputChange(e);
                                                            this.getLocations(e.target.value, legIndex);
                                                        }
                                                    }
                                                    value={leg.formatted_address}
                                                    data-index={legIndex}
                                                    data-type={leg.leg_type}
                                                    readOnly={this.props.disableEdit}
                                                />
                                                {leg.loading ? <div className="loading abs" style={{zIndex:999999}}/> : ''}
                                            </div>
                                            {
                                                leg.formatted_address?.length ?
                                                <span 
                                                    className="done-icn" 
                                                    title="Done"
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        if(this.props.disableEdit){}
                                                        else {
                                                            const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                            shipmentProfileDetails.config.updated = true;
                                                            if(shipmentProfileDetails.config.legs[legIndex].latitude && shipmentProfileDetails.config.legs[legIndex].longitude) {
                                                                shipmentProfileDetails.config.legs[legIndex].edit = false;
                                                                if(shipmentProfileDetails.errors) {
                                                                    delete(shipmentProfileDetails.errors[`legs.${legIndex}.formatted_address`]);
                                                                }
                                                            } else {
                                                                shipmentProfileDetails.errors = shipmentProfileDetails.errors || {};
                                                                shipmentProfileDetails.errors[`legs.${legIndex}.formatted_address`] = 'Stop location is not valid.';
                                                                shipmentProfileDetails.config.legs[legIndex].addressSuggestions = [];
                                                            }
                                                            this.setState({shipmentProfileDetails});
                                                        }
                                                    }}
                                                ></span> 
                                                :''
                                            }
                                            {
                                                leg.addressSuggestions?.length ?
                                                <ul className="loc-s-list">
                                                    {
                                                        leg.addressSuggestions?.map((address, index) => {
                                                            return (
                                                                <li
                                                                    key={`loc_suggestion_${index}`}
                                                                    title={address.place_name}
                                                                    onClick={ (e) => {
                                                                        e.stopPropagation();
                                                                        this.setNewLocation(address, legIndex);
                                                                    }}

                                                                >{address.place_name}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                                : 
                                                ''
                                            }
                                            {
                                                SHIPMENT_PROFILE_DETAILS.config.errors && (SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.formatted_address`] || SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.latitude`] || SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.longitude`] || SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.leg_type`] || SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.id`]) ?
                                                <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.formatted_address`] || SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.latitude`] || SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.longitude`] || SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.leg_type`] || SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.id`]}</div>
                                                : ''
                                            }
                                            </>
                                            :
                                            <div className="l3">{leg.formatted_address || '---'}</div>
                                            }
                                        </div>
                                    {
                                    leg.leg_type !== 'origin' ?
                                    <div className="time-sec i-sec" key={`time-sec-i-sec-${legIndex}`}>
                                        <div className="i-flds">
                                            <div className="lbl" style={{fontSize:"12px", cursor:'text'}}>Anticipated arrival: <i className="material-icons t-tip">info<span className="t-txt">Expected transit time for the shipment to reach the {leg.leg_type==='destination'?'destination': 'location'}</span></i></div>
                                            <div className="ip D">
                                                <input
                                                    className="tol dhm"
                                                    id={`stop_${legIndex}_a_a_d_ip`}
                                                    type="text"
                                                    maxLength="3"
                                                    placeholder="-"
                                                    autoComplete="off"
                                                    value={leg.due_date?.d}
                                                    onChange={(e) => {
                                                        const val = e.target.value;
                                                        const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                        shipmentProfileDetails.config.updated = true;
                                                        shipmentProfileDetails.config.legs[legIndex][`due_date`].d = (val+'').match(/^(36[0-5]|3[0-5]?[0-9]?|[0-2]?[0-9]?[0-9]?)$/) || val === '' ? val : shipmentProfileDetails.config.legs[legIndex][`due_date`].d;
                                                        this.setState({shipmentProfileDetails});
                                                    }}
                                                    readOnly={this.props.disableEdit}
                                                />
                                                <label className="metrics-label" htmlFor={`${leg.leg_type}_${legIndex}_a_a_d_ip`}>
                                                    Days
                                                </label>
                                            </div>
                                            <div className="ip H">
                                                <input
                                                    className="tol dhm"
                                                    id={`stop_${legIndex}_a_a_h_ip`}
                                                    type="text"
                                                    maxLength="2"
                                                    placeholder="-"
                                                    autoComplete="off"
                                                    value={leg.due_date?.h}
                                                    onChange={(e) => {
                                                        const val = e.target.value;
                                                        const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                        shipmentProfileDetails.config.updated = true;
                                                        shipmentProfileDetails.config.legs[legIndex][`due_date`].h = (val+'').match(/^(2[0-3]|1[0-9]|0[0-9]|[0-9])$/) || val === '' ? val : shipmentProfileDetails.config.legs[legIndex][`due_date`].h;
                                                        this.setState({shipmentProfileDetails});
                                                    }}
                                                    readOnly={this.props.disableEdit}
                                                />
                                                <label className="metrics-label" htmlFor={`${leg.leg_type}_${legIndex}_a_a_h_ip`}>
                                                    Hours
                                                </label>
                                            </div>
                                            <div className="ip M">
                                                <input
                                                    className="tol dhm"
                                                    id={`${leg.leg_type}_${legIndex}_a_a_m_ip`}
                                                    type="text"
                                                    maxLength="2"
                                                    placeholder="-"
                                                    autoComplete="off"
                                                    value={leg.due_date?.m}
                                                    onChange={(e) => {
                                                        const val = e.target.value;
                                                        const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                        shipmentProfileDetails.config.updated = true;
                                                        shipmentProfileDetails.config.legs[legIndex][`due_date`].m = (val+'').match(/^[0-5]?[0-9]$/) || val === '' ? val : shipmentProfileDetails.config.legs[legIndex][`due_date`].m;
                                                        this.setState({shipmentProfileDetails});
                                                    }}
                                                    readOnly={this.props.disableEdit}
                                                />
                                                <label className="metrics-label" htmlFor={`${leg.leg_type}_${legIndex}_a_a_m_ip`}>
                                                    Minutes
                                                </label>
                                            </div>
                                            {/* <span 
                                                className="material-icons clear-duration"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                    shipmentProfileDetails.config.updated = true;
                                                    shipmentProfileDetails.stops[i].dueDate.d = '';
                                                    shipmentProfileDetails.stops[i].dueDate.h = '';
                                                    shipmentProfileDetails.stops[i].dueDate.m = '';
                                                    this.setState({shipmentProfileDetails});
                                                }}
                                            >clear</span> */}
                                        </div>
                                        {
                                            SHIPMENT_PROFILE_DETAILS.config.errors && SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.due_date`] ?
                                            <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.due_date`]}</div>
                                            :
                                            ''
                                        }
                                    </div>
                                    : <></>
                                    }
                                    </div>
                                    {
                                        leg.leg_type !== 'destination' ?
                                        <>
                                        {
                                            leg.edit_config ?
                                            <div className="leg-sec">
                                                <span 
                                                    className="material-icons i-config-icn"
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        this.toggleLegConfig(legIndex);
                                                    }}
                                                >settings</span>
                                                <div className="i-sec s-row">
                                                    <div className="i-sec i-mode">
                                                        <label className="i-sec-lbl" htmlFor={`mode-${legIndex}`}>
                                                            Mode
                                                        </label>
                                                        <SelectBox
                                                            style={{ width: '112px' }}
                                                            useProps
                                                            list={MODES}
                                                            value={leg.mode || 'auto'}
                                                            onChange={o => {
                                                                const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                                if(shipmentProfileDetails.config.legs[legIndex].mode !== o.id) {
                                                                    shipmentProfileDetails.config.legs[legIndex].carrier_name = '';
                                                                    shipmentProfileDetails.config.legs[legIndex].carrier = '';
                                                                }
                                                                shipmentProfileDetails.config.legs[legIndex].mode = o.id;
                                                                shipmentProfileDetails.config.updated = true;
                                                                if(o.id !== 'Water'){  
                                                                    leg.mmsi = null;
                                                                    leg.container_id = null;
                                                                }
                                                                if(o.id !== 'Air'){
                                                                    leg.awb = null;
                                                                }
                                                                this.setState({shipmentProfileDetails});
                                                            }}
                                                            disable={this.props.disableEdit}
                                                        />
                                                        {
                                                            SHIPMENT_PROFILE_DETAILS.config.errors && SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.mode`] ?
                                                            <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.mode`]}</div>
                                                            : <></>
                                                        }
                                                    </div>
                                                    {
                                                        leg.mode === 'Water' ?
                                                        <>
                                                        <div className="i-sec">
                                                            <label className="i-sec-lbl" htmlFor={`mmsi-${legIndex}`}>
                                                                MMSI
                                                            </label>
                                                            <input
                                                                className="ip"
                                                                id={`mmsi-${legIndex}`}
                                                                type="text"
                                                                placeholder=""
                                                                maxLength="32"
                                                                autoComplete="off"
                                                                value={leg.mmsi}
                                                                onChange={(e) => {
                                                                    const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                                    shipmentProfileDetails.config.legs[legIndex].mmsi = e.target.value;
                                                                    shipmentProfileDetails.config.updated = true;
                                                                    this.setState({shipmentProfileDetails});
                                                                }}
                                                                readOnly={this.props.disableEdit}
                                                            />
                                                            {
                                                                SHIPMENT_PROFILE_DETAILS.config.errors && SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.mmsi`] ?
                                                                <div className="error_msg" style={{top:'56px'}}>{SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.mmsi`]}</div>
                                                                : <></>
                                                            }
                                                        </div>
                                                        {/*<div className="i-sec i-or">or</div>
                                                        <div className="i-sec">
                                                            <label className="i-sec-lbl" htmlFor={`container-id-${legIndex}`}>
                                                                Container id
                                                            </label>
                                                            <input
                                                                className="ip"
                                                                id={`container-id-${legIndex}`}
                                                                type="text"
                                                                placeholder=""
                                                                maxLength="32"
                                                                autoComplete="off"
                                                                value={leg.container_id}
                                                                onChange={(e) => {
                                                                    const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                                    shipmentProfileDetails.config.legs[legIndex].container_id = e.target.value;
                                                                    shipmentProfileDetails.config.updated = true;
                                                                    this.setState({shipmentProfileDetails});
                                                                }}
                                                                readOnly={this.props.disableEdit}
                                                            />
                                                            {
                                                                SHIPMENT_PROFILE_DETAILS.config.errors && SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.container_id`] ?
                                                                <div className="error_msg" style={{top:'56px'}}>{SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.container_id`]}</div>
                                                                : <></>
                                                            }
                                                            </div>*/}
                                                        </>
                                                        :
                                                        leg.mode === 'Air' ?
                                                        <>
                                                        <div>
                                                            <label className="i-sec-lbl" htmlFor={`awb-${legIndex}`}>
                                                                AWB #
                                                            </label>
                                                            <input
                                                                className="ip"
                                                                id={`awb-${legIndex}`}
                                                                type="text"
                                                                placeholder=""
                                                                maxLength="32"
                                                                autoComplete="off"
                                                                value={leg.awb}
                                                                onChange={(e) => {
                                                                    const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                                    shipmentProfileDetails.config.legs[legIndex].awb = e.target.value;
                                                                    shipmentProfileDetails.config.updated = true;
                                                                    this.setState({shipmentProfileDetails});
                                                                }}
                                                                readOnly={this.props.disableEdit}
                                                            />
                                                            {
                                                                SHIPMENT_PROFILE_DETAILS.config.errors && SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.awb`] ?
                                                                <div className="error_msg" style={{top:'56px'}}>{SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.awb`]}</div>
                                                                : <></>
                                                            }
                                                        </div>
                                                        </>
                                                        : ''
                                                    }
                                                </div>
                                                <div className="i-sec i-carrier-sec">
                                                    <label className="i-sec-lbl" htmlFor={`mode-${legIndex}`}>
                                                        Carrier
                                                    </label>
                                                    <input
                                                        className="ip"
                                                        id={`awb-${legIndex}`}
                                                        type="text"
                                                        placeholder=""
                                                        maxLength="32"
                                                        autoComplete="off"
                                                        value={leg.carrier_name}
                                                        onChange={(e) => {
                                                            document.body.removeEventListener('click', this.closeCarrierTypePopup);
                                                            document.body.addEventListener('click', this.closeCarrierTypePopup);
                                                            const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                            shipmentProfileDetails.config.legs[legIndex].carrier_name = e.target.value;
                                                            shipmentProfileDetails.config.legs[legIndex].showCarrierTypePopup = true;
                                                            shipmentProfileDetails.config.updated = true;
                                                            this.setState({shipmentProfileDetails});
                                                        }}
                                                        readOnly={this.props.disableEdit}
                                                    />
                                                    {
                                                        leg.showCarrierUpdate ?
                                                        <span 
                                                            className={`add-new-u carrier${leg.carrier_name ? '' : ' d'}`}
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                if(this.props.disableEdit){}
                                                                else {
                                                                    if(leg.carrier_name?.length) {
                                                                        this.props.saveCarrierType({
                                                                            id: leg.carrier, 
                                                                            carrier_name: leg.carrier_name,
                                                                            onComplete: (data) => {
                                                                                const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                                                shipmentProfileDetails.config.legs[legIndex].showCarrierUpdate = false;
                                                                                this.props.onAddCarrierType({data: data, shipmentProfileDetails});
                                                                            }
                                                                        });
                                                                    }
                                                                }
                                                            }}
                                                        >Update</span>
                                                        :
                                                        (
                                                            this.props.carrierTypeList?.filter(c => c.mode === leg.mode && c.carrier_name === leg.carrier_name).length || this.props.disableEdit ?
                                                            <></>
                                                            :
                                                            <span 
                                                                className={`add-new-u carrier${leg.carrier_name ? '' : ' d'}`}
                                                                onClick={e => {
                                                                    e.stopPropagation();
                                                                    if(this.props.disableEdit) {}
                                                                    else {
                                                                        if(leg.carrier_name?.length) {
                                                                            this.props.saveCarrierType({
                                                                                mode: leg.mode, 
                                                                                carrier_name: leg.carrier_name,
                                                                                onComplete: (data) => {
                                                                                    const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                                                    shipmentProfileDetails.config.legs[legIndex].showCarrierUpdate = false;
                                                                                    this.props.onAddCarrierType({data: data, shipmentProfileDetails, legIndex});
                                                                                }
                                                                            });
                                                                        }
                                                                    }
                                                                }}
                                                            >Add New</span>
                                                        )
                                                    }
                                                    { leg.showCarrierTypePopup && !this.props.disableEdit?
                                                    <ul className="search-cat-pop pro-type">
                                                        {this.props.carrierTypeList.filter(c => c.mode === leg.mode && c.carrier_name?.toLowerCase().indexOf(leg.carrier_name?.toLowerCase())>=0).map((carrier, i) => (
                                                            <li
                                                                className={`sel-item prod-t-item`}
                                                                key={`carrier-type-${legIndex}-${i}`}
                                                                data-id={carrier.carrier_name}
                                                            >
                                                                <div 
                                                                    className="prod-lbl" 
                                                                    data-type="add"
                                                                    value={carrier.carrier_name}
                                                                    key={`p-t-i-${i}`}
                                                                    onClick={e => {
                                                                        e.stopPropagation();
                                                                        const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                                        shipmentProfileDetails.config.legs[legIndex].carrier_name = carrier.carrier_name;
                                                                        shipmentProfileDetails.config.legs[legIndex].carrier = carrier.id;
                                                                        shipmentProfileDetails.config.legs[legIndex].showCarrierTypePopup = false;
                                                                        delete(shipmentProfileDetails.config.legs[legIndex].showCarrierUpdate);
                                                                        this.setState({shipmentProfileDetails});
                                                                    }}
                                                                >{carrier.carrier_name}</div>
                                                                <div style={{width:'48px', marginRight: '6px'}}>
                                                                    <span 
                                                                        className="prod-action e" 
                                                                        data-type="edit"
                                                                        onClick={e => {
                                                                            e.stopPropagation();
                                                                            const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                                            shipmentProfileDetails.config.legs[legIndex].carrier_name = carrier.carrier_name;
                                                                            shipmentProfileDetails.config.legs[legIndex].carrier = carrier.id;
                                                                            shipmentProfileDetails.config.legs[legIndex].showCarrierUpdate = true;
                                                                            shipmentProfileDetails.config.legs[legIndex].showCarrierTypePopup = false;
                                                                            this.setState({shipmentProfileDetails});
                                                                        }}
                                                                    >{EditIcon({fill: '#979797'})}</span>
                                                                    <span 
                                                                        className="prod-action d" 
                                                                        data-type="delete"
                                                                        onClick={async e => {
                                                                            e.stopPropagation();
                                                                            if(this.props.carrierTypeSaving) {}
                                                                            else {
                                                                                //console.log('deleting carrier type');
                                                                                await this.props.deleteCarrierType({
                                                                                    id: carrier.id,
                                                                                    onComplete: (data, error) => {
                                                                                        if(data.success || !error) {
                                                                                            const carrierTypeList = [...this.props.carrierTypeList];
                                                                                            const cIndex = carrierTypeList.findIndex(c => c.id === carrier.id);
                                                                                            carrierTypeList.splice(cIndex, 1);
                                                                                            this.props.getCarrierTypeListSuccess(carrierTypeList);
                                                                                            //console.log(false, false, data, 'delete', '<<<<<<<<<------------');

                                                                                            const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                                                            shipmentProfileDetails.config.legs.map(leg => {
                                                                                                if(leg.carrier === carrier.carrier_name) {
                                                                                                    leg.carrier = '';
                                                                                                    leg.carrier_name = '';
                                                                                                    leg.showCarrierTypePopup = false;
                                                                                                    leg.showCarrierUpdate = false;
                                                                                                }
                                                                                            });                                                                                            
                                                                                            
                                                                                            this.setState({shipmentProfileDetails});
                                                                                            this.props.onDeleteCarrierType({message: data.message, success: true, shipmentProfileDetails});
                                                                                        } else {
                                                                                            this.props.onDeleteCarrierType({message: data.message, success: false});
                                                                                        }                                            
                                                                                    },
                                                                                });
                                                                            }
                                                                        }}
                                                                    >{TrashIcon({fill:'#979797'})}</span>
                                                                </div>
                                                            </li>
                                                        ))
                                                        }                                                       
                                                    </ul>
                                                    : <></>
                                                    }
                                                    {
                                                        SHIPMENT_PROFILE_DETAILS.config.errors && SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.carrier`] ?
                                                        <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.carrier`]}</div>
                                                        : <></>
                                                    }
                                                </div>
                                                <div className="i-sec">
                                                    <div htmlFor="profile_name" className="fld-lbl" style={{marginRight: '20px', position: 'unset', width: 'unset'}}>Communication interval <i className="material-icons t-tip">info<span className="t-txt">Configure device communication. InMotion and NoMotion applies for TRAK-X devices. Sampling and Reporting applies for TRAK-X1, TRAK-NXT devices</span></i></div>
                                                    <div className="config-sec-2-col">
                                                        <div className="config-sec-col-l">
                                                            <div className="fld-lbl-2">TRAK-X:</div>
                                                            <div className="f-l">
                                                                <div className="fld-lbl">Inmotion:</div>
                                                                <SelectBox
                                                                    style={{ width: '160px' }}
                                                                    useProps
                                                                    list={INMOTION}
                                                                    value={leg.in_motion_interval || ''}
                                                                    onChange={o => {
                                                                        const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                                        shipmentProfileDetails.config.legs[legIndex].in_motion_interval = o.id;
                                                                        shipmentProfileDetails.config.updated = true;
                                                                        this.setState({shipmentProfileDetails});
                                                                    }}
                                                                    disable={this.props.disableEdit}
                                                                />
                                                                {
                                                                    SHIPMENT_PROFILE_DETAILS.config.errors && SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.in_motion_interval`] ?
                                                                    <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.in_motion_interval`]}</div>
                                                                    : <></>
                                                                }
                                                            </div>
                                                            <div className="f-l">
                                                                <div className="fld-lbl">Nomotion:</div>
                                                                <SelectBox
                                                                    style={{ width: '160px' }}
                                                                    useProps
                                                                    list={NOMOTION}
                                                                    value={leg.no_motion_interval || ''}
                                                                    onChange={o => {
                                                                        const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                                        shipmentProfileDetails.config.legs[legIndex].no_motion_interval = o.id;
                                                                        shipmentProfileDetails.config.updated = true;
                                                                        this.setState({shipmentProfileDetails});
                                                                    }}
                                                                    disable={this.props.disableEdit}
                                                                />
                                                                {
                                                                    SHIPMENT_PROFILE_DETAILS.config.errors && SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.no_motion_interval`] ?
                                                                    <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.no_motion_interval`]}</div>
                                                                    : <></>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="config-sec-col-r">
                                                            <div className="fld-lbl-2">TRAK-X1, TRAK-NXT:</div>
                                                            <div className="f-l">
                                                                <div className="fld-lbl">Sampling:</div>
                                                                <SelectBox
                                                                    style={{ width: '160px' }}
                                                                    useProps
                                                                    list={SAMPLING}
                                                                    value={leg.monitor_period || ''}
                                                                    onChange={o => {
                                                                        const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                                        shipmentProfileDetails.config.legs[legIndex].monitor_period = o.id;
                                                                        shipmentProfileDetails.config.updated = true;
                                                                        this.setState({shipmentProfileDetails});
                                                                    }}
                                                                    disable={this.props.disableEdit}
                                                                />
                                                                {
                                                                    SHIPMENT_PROFILE_DETAILS.config.errors && SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.monitor_period`] ?
                                                                    <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.monitor_period`]}</div>
                                                                    : <></>
                                                                }
                                                            </div>
                                                            <div className="f-l">
                                                                <div className="fld-lbl">Reporting:</div>
                                                                <SelectBox
                                                                    style={{ width: '160px' }}
                                                                    useProps
                                                                    list={REPORTING}
                                                                    value={leg.reporting_period || ''}
                                                                    onChange={o => {
                                                                        const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                                        shipmentProfileDetails.config.legs[legIndex].reporting_period = o.id;
                                                                        shipmentProfileDetails.config.updated = true;
                                                                        this.setState({shipmentProfileDetails});
                                                                    }}
                                                                    disable={this.props.disableEdit}
                                                                />
                                                                {
                                                                    SHIPMENT_PROFILE_DETAILS.config.errors && SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.reporting_period`] ?
                                                                    <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.reporting_period`]}</div>
                                                                    : <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="i-sec" style={{paddingTop:'8px'}}>
                                                    <div className="d-f-a-c">
                                                        <div className={`status-bar s${leg.allow_sensor_data ? ' a' : ''}${this.props.disableEdit ? ' d': ''}`}
                                                            onClick={ (e) => {
                                                                e.stopPropagation();
                                                                if(this.props.disableEdit) {}
                                                                else {
                                                                    const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                                    shipmentProfileDetails.config.legs[legIndex].allow_sensor_data = leg.allow_sensor_data ? false : true;
                                                                    shipmentProfileDetails.config.updated = true;
                                                                    this.setState({shipmentProfileDetails});
                                                                }
                                                            }}
                                                        ></div>
                                                        <div className="fld-lbl" style={{marginLeft: '16px'}}>Enable sensor recording and alerts</div>
                                                    </div>
                                                    {
                                                        SHIPMENT_PROFILE_DETAILS.config.errors && SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.allow_sensor_data`] ?
                                                        <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.allow_sensor_data`]}</div>
                                                        : <></>
                                                    }
                                                </div>
                                                <div className="i-sec n-b" style={{alignItems: "flex-start"}}>
                                                    <div className="i-sec-lbl">Alert additional users</div>
                                                    <div className="i-fld notify-users">
                                                    {
                                                    leg.notify_additional_users?.length ?
                                                        <div className="selected-items">
                                                            <ul className="group-selected-companies no-scroll" key={`selected-users-list-${this.props.shipmentID}-${legIndex}`}>
                                                                {leg.notify_additional_users.map((user, uIndex) => (
                                                                    <li
                                                                        key={`ship-user-${uIndex}`}
                                                                        className={`group-selected-companies-li${this.props.disableEdit ? ' d': ''}`}
                                                                        title={user}
                                                                    >
                                                                        {user}
                                                                        {
                                                                            this.props.disableEdit ?
                                                                            <></>
                                                                            :
                                                                            <span
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                                                    let userIndex = shipmentProfileDetails.config.legs[legIndex].notify_additional_users.findIndex(u => u === user);
                                                                                    if(userIndex >= 0) {
                                                                                        shipmentProfileDetails.config.legs[legIndex].notify_additional_users.splice(userIndex, 1);
                                                                                    }
                                                                                    
                                                                                    this.setState({shipmentProfileDetails});
                                                                                }}
                                                                            >x</span>
                                                                        }
                                                                    </li>
                                                                )
                                                                )}
                                                            </ul>
                                                            {
                                                                leg.notify_additional_users.filter(u => !u.delete).length > 0 && !this.props.disableEdit ?
                                                                <span
                                                                    className="group-select-delete-all"
                                                                    onClick={() => {
                                                                        const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                                        if(shipmentProfileDetails.config.legs[legIndex].notify_additional_users?.length) {
                                                                            shipmentProfileDetails.config.legs[legIndex].notify_additional_users.length = [];
                                                                            this.setState({shipmentProfileDetails});
                                                                            document.getElementById(`add_additional_users_${legIndex}`).focus();
                                                                        }
                                                                    }}
                                                                >Clear all</span> : ''
                                                            }                            
                                                        </div> : ''
                                                    }
                                                    <input
                                                        type="text"
                                                        className="ip-fld"
                                                        placeholder="Add Email..."
                                                        maxLength="70"
                                                        autoComplete='off'
                                                        name="users"
                                                        id={`add_additional_users_${legIndex}`}
                                                        value={leg.addUserSearch || ''}
                                                        onChange={(e) => {
                                                            const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                            shipmentProfileDetails.config.legs[legIndex].addUserSearch = e.target.value;
                                                            this.setState({shipmentProfileDetails});
                                                        }}
                                                        disabled={(leg.notify_additional_users?.length > 0 && leg.notify_additional_users.length >= this.state.config.maxUsers) || this.props.disableEdit ? true : false}
                                                    />
                                                    {LEG_USERS[legIndex].length >= this.state.config.maxUsers ? <div className="error_msg">* Maximum user limit({this.state.config.maxUsers}) reached!</div> : <></>}
                                                    {                                                        
                                                        leg.addUserSearch?.length >= 1 ?
                                                        <ul className="search-cat-pop" style={{ top: 'unset', bottom: '40px' }}>                                            
                                                            {LEG_USERS[legIndex]?.map((user, i) => (
                                                                <li
                                                                    className={`sel-item`}
                                                                    key={`search-user-${i}`}
                                                                    data-id={user.id}
                                                                    title={user.email}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                                        const userIndex = leg.notify_additional_users?.findIndex(u => u === user.email) || -1;
                                                                        if(userIndex >= 0) {
                                                                        } else {
                                                                            shipmentProfileDetails.config.legs[legIndex].notify_additional_users = shipmentProfileDetails.config.legs[legIndex].notify_additional_users || [];
                                                                            shipmentProfileDetails.config.legs[legIndex].notify_additional_users.push(user.email);
                                                                        }
                                                                        shipmentProfileDetails.config.legs[legIndex].addUserSearch = '';
                                                                        this.setState({shipmentProfileDetails});
                                                                        document.getElementById(`add_additional_users_${legIndex}`).focus();
                                                                    }
                                                                    }
                                                                >
                                                                    {user.email}
                                                                </li>
                                                            ))}
                                                            {
                                                                LEG_USERS[legIndex].length === 0 ?
                                                                <>
                                                                <li
                                                                    className="no-item"
                                                                    key={`search-user-no-users`}>No users found...</li>
                                                                </>
                                                                : ''
                                                            }
                                                        </ul>
                                                        : ''
                                                    }
                                                    {((leg.notify_additional_users?.length || 0) < this.state.config.maxUsers && LEG_USERS[legIndex].length === 0 && new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i).test(leg.addUserSearch)) && !this.props.disableEdit ?
                                                    <span 
                                                        className="add-new-u u"
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                            shipmentProfileDetails.config.legs[legIndex].notify_additional_users = shipmentProfileDetails.config.legs[legIndex].notify_additional_users || [];
                                                            const userIndex = shipmentProfileDetails.config.legs[legIndex].notify_additional_users.findIndex( u => u === leg.addUserSearch);
                                                            if(userIndex >= 0 ) {
                                                                
                                                            } else {
                                                                shipmentProfileDetails.config.legs[legIndex].notify_additional_users.push(leg.addUserSearch);
                                                            }
                                                            
                                                            shipmentProfileDetails.config.legs[legIndex].addUserSearch = '';
                                                            this.setState({shipmentProfileDetails});
                                                            document.getElementById(`add_additional_users_${legIndex}`).focus();
                                                        }}
                                                    >Add Email</span> : ''}
                                                    </div>
                                                    {
                                                        SHIPMENT_PROFILE_DETAILS.config.errors && SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.notify_additional_users`] ?
                                                        <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}.notify_additional_users`]}</div>
                                                        : <></>
                                                    }
                                                </div>
                                            </div>
                                            : 
                                            <div 
                                                className="s-p-edit-config"
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    this.toggleLegConfig(legIndex);
                                                }}
                                            >
                                                <span className="material-icons i-config-icn">settings</span>
                                                <span className="lbl">{this.props.disableEdit ? 'View' : 'Edit'} Leg-{legIndex+1} Configuration</span>
                                            </div>
                                        }
                                        </>
                                        : 
                                        <></>
                                    }
                                    {
                                        SHIPMENT_PROFILE_DETAILS.config.errors && SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}`] ?
                                        <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors[`legs.${legIndex}`]}</div>
                                        : <></>
                                    }
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
                {
                    SHIPMENT_PROFILE_DETAILS.config.errors && SHIPMENT_PROFILE_DETAILS.config.errors[`legs`] ?
                    <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors[`legs`]}</div>
                    : <></>
                }
                {
                    SHIPMENT_PROFILE_DETAILS.config.legs?.length < (this.state.config.maxStops + 2) && !this.props.disableEdit && !SHIPMENT_PROFILE_DETAILS.config.round_trip ?
                    <div style={{margin:'32px'}}>
                        <div 
                            className={`c-btn s`}
                            onClick={(e)=>{
                                e.stopPropagation();
                                this.addStop();                                         
                            }}
                        >+ Add Stop</div>
                    </div>
                    : <></>
                }
            </div>
            <></>
            <div className="fld-sec">
                <div className="i-sec d-f-a-c">
                    <div className={`status-bar${SHIPMENT_PROFILE_DETAILS.config.auto_complete === 1 ? ' a' : ''}${this.props.disableEdit ? ' d': ''}`}
                        onClick={ (e) => {
                            e.stopPropagation();
                            if(this.props.disableEdit) {}
                            else {
                                const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                if(SHIPMENT_PROFILE_DETAILS.config.auto_complete === 1) {
                                    shipmentProfileDetails.config.auto_complete = 0;
                                    shipmentProfileDetails.config.x_duration = {d:'',h:'',m:''};
                                } else {
                                    shipmentProfileDetails.config.auto_complete = 1;
                                    shipmentProfileDetails.config.x_duration = {d:'',h:1,m:''};
                                }
                                shipmentProfileDetails.config.updated = true;
                                this.setState({shipmentProfileDetails});
                            }
                            
                        }}
                    ></div>
                    <div className="fld-lbl" style={{marginLeft: '20px'}}>Shipment Autocomplete</div>
                </div>
                {
                    SHIPMENT_PROFILE_DETAILS.config.auto_complete === 1 ?
                    <div style={{marginLeft: '64px'}}>
                        <div className="time-sec i-sec" key={`time-sec-i-sec-autocomplete`}>
                            <div className="i-flds" style={{marginLeft:'0'}}>
                                <div className="lbl" style={{fontSize:"12px", cursor:'text',width:'unset', paddingRight:'20px'}}>Delay timer to auto-complete shipment after reaching destination</div>
                                <div className="ip D">
                                    <input
                                        className="tol dhm"
                                        id={`autocomplete_x_a_a_d_ip`}
                                        type="text"
                                        maxLength="3"
                                        placeholder="-"
                                        autoComplete="off"
                                        value={SHIPMENT_PROFILE_DETAILS.config.x_duration.d}
                                        onChange={(e) => {
                                            const val = e.target.value;
                                            const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                            shipmentProfileDetails.config.updated = true;
                                            shipmentProfileDetails.config.x_duration.d = (val+'').match(/^(36[0-5]|3[0-5]?[0-9]?|[0-2]?[0-9]?[0-9]?)$/) || val === '' ? val : shipmentProfileDetails.config.x_duration.d;
                                            this.setState({shipmentProfileDetails});
                                        }}
                                        readOnly={this.props.disableEdit}
                                    />
                                    <label className="metrics-label" htmlFor={`autocomplete_x_a_a_d_ip`}>
                                        Days
                                    </label>
                                </div>
                                <div className="ip H">
                                    <input
                                        className="tol dhm"
                                        id={`autocomplete_x_a_a_h_ip`}
                                        type="text"
                                        maxLength="2"
                                        placeholder="-"
                                        autoComplete="off"
                                        value={SHIPMENT_PROFILE_DETAILS.config.x_duration.h}
                                        onChange={(e) => {
                                            const val = e.target.value;
                                            const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                            shipmentProfileDetails.config.updated = true;
                                            shipmentProfileDetails.config.x_duration.h = (val+'').match(/^(2[0-3]|1[0-9]|0[0-9]|[0-9])$/) || val === '' ? val : shipmentProfileDetails.config.x_duration.h;
                                            this.setState({shipmentProfileDetails});
                                        }}
                                        readOnly={this.props.disableEdit}
                                    />
                                    <label className="metrics-label" htmlFor={`autocomplete_x_a_a_h_ip`}>
                                        Hours
                                    </label>
                                </div>
                                <div className="ip M">
                                    <input
                                        className="tol dhm"
                                        id={`autocomplete_x_a_a_m_ip`}
                                        type="text"
                                        maxLength="2"
                                        placeholder="-"
                                        autoComplete="off"
                                        value={SHIPMENT_PROFILE_DETAILS.config.x_duration.m}
                                        onChange={(e) => {
                                            const val = e.target.value;
                                            const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                            shipmentProfileDetails.config.updated = true;
                                            shipmentProfileDetails.config.x_duration.m = (val+'').match(/^[0-5]?[0-9]$/) || val === '' ? val : shipmentProfileDetails.config.x_duration.m;
                                            this.setState({shipmentProfileDetails});
                                        }}
                                        readOnly={this.props.disableEdit}
                                    />
                                    <label className="metrics-label" htmlFor={`autocomplete_xa_a_m_ip`}>
                                        Minutes
                                    </label>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    : 
                    ''
                }
                {
                    SHIPMENT_PROFILE_DETAILS.config.errors?.auto_complete ?
                    <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors.auto_complete}</div>
                    : <></>
                }
                {
                    SHIPMENT_PROFILE_DETAILS.config.errors?.x_duration ?
                    <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors.x_duration}</div>
                    : <></>
                }
            </div>
            <div className="fld-sec">
                <div className="fld-lbl">Origin/Destination/Stop geofence <i className="material-icons t-tip">info<span className="t-txt">Geofence radius to trigger "Left origin/stop" and "Reached destination/stop" notification</span></i></div>
                <div className="dist-slider-comp">
                    <div className="d-slider">
                        <div>
                            <div className="ip-p-m">
                                <div 
                                    className={`ip-m${SHIPMENT_PROFILE_DETAILS.config.geolocation_distance <= 100 || this.props.disableEdit? ' d': ''}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if(this.props.disableEdit){}
                                        else{
                                            this.stepChangeValue(e, 'm');
                                        }
                                    }}
                                >-</div>
                                <input 
                                    className="ip" 
                                    placeholder="-" 
                                    value={SHIPMENT_PROFILE_DETAILS.config.geolocation_distance} 
                                    maxLength={5}
                                    onChange={(e) => {
                                        const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                        const val = e.target.value;
                                        shipmentProfileDetails.config.geolocation_distance = isNaN(val) || val < 0 || val === '-0' ? shipmentProfileDetails.geolocation_distance : val;
                                        shipmentProfileDetails.config.updated = true;
                                        this.setState({ shipmentProfileDetails });
                                    }}
                                    disabled={this.props.disableEdit}
                                />
                                <div 
                                    className={`ip-p${SHIPMENT_PROFILE_DETAILS.config.geolocation_distance >= 10000 || this.props.disableEdit? ' d': ''}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if(this.props.disableEdit){}
                                        else {
                                            this.stepChangeValue(e, 'p');
                                        }
                                        
                                    }}
                                >+</div>
                            </div>
                        </div>
                        <div>
                            <Slider
                                className="simple-slider"
                                min={100}
                                max={10000}
                                step={100}
                                value={SHIPMENT_PROFILE_DETAILS.config.geolocation_distance||0}
                                // tipFormatter={value =>
                                //     `${value/1000}km`
                                // }
                                // tipProps={{
                                //     placement: 'bottom',
                                //     visible: true,
                                // }}
                                // allowCross={false}
                                onChange={e => {
                                    const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                    shipmentProfileDetails.config.geolocation_distance = e;
                                    shipmentProfileDetails.config.updated = true;
                                    this.setState({shipmentProfileDetails});
                                }}
                                disabled={this.props.disableEdit}
                            />
                        </div>
                    </div>
                    <div className="dist-rep mi">Miles(mi) <span style={{fontWeight:'600'}}>{roundOff((SHIPMENT_PROFILE_DETAILS.config.geolocation_distance||0)/1000/1.609, 2)}</span></div>
                    <div className="dist-rep m">Meters(m) <span style={{fontWeight:'600'}}>{SHIPMENT_PROFILE_DETAILS.config.geolocation_distance||0}</span></div>
                    <div className="dist-rep km">Kilometers(km) <span style={{fontWeight:'600'}}>{roundOff((SHIPMENT_PROFILE_DETAILS.config.geolocation_distance||0)/1000, 1)}</span></div>
                </div>
                {SHIPMENT_PROFILE_DETAILS.config.errors?.geolocation_distance ?
                <span className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors.geolocation_distance}</span>
                :""}
            </div>
            <div className="fld-sec">
                <div className="i-sec d-f-a-c">
                    <div className={`status-bar${SHIPMENT_PROFILE_DETAILS.config.geofence_alert === 1 ? ' a' : ''}${this.props.disableEdit ? ' d': ''}`}
                        onClick={ (e) => {
                            e.stopPropagation();
                            if(this.props.disableEdit) {}
                            else {
                                const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                if(SHIPMENT_PROFILE_DETAILS.config.geofence_alert === 1) {
                                    shipmentProfileDetails.config.geofence_alert = 0;
                                    shipmentProfileDetails.config.geofence_time_interval = 0;
                                    shipmentProfileDetails.config.geofence_time_increment = 0;                                                        
                                } else {
                                    shipmentProfileDetails.config.geofence_alert = 1;
                                    shipmentProfileDetails.config.geofence_time_interval = this.state.defaultTimeInterval;
                                    shipmentProfileDetails.config.geofence_time_increment = 0;
                                }
                                shipmentProfileDetails.config.updated = true;
                                this.setState({shipmentProfileDetails});
                            }                            
                        }}
                    ></div>
                    <div className="fld-lbl" style={{marginLeft: '20px'}}>Time based geofence alert for destination</div>
                </div>
                {
                    SHIPMENT_PROFILE_DETAILS.config.geofence_alert === 1 ?
                    <div style={{marginLeft: '64px'}}>
                        <div className="temperature">Destination ETA  <span className="c-info">(applicable only for road based shipments using TRAK-X)</span></div>
                        <div className="geofenceAlertOptions">
                            <div className="geofenceAlert" style={{display:"flex"}}>
                            <input
                                type="radio"
                                value={'interval'}
                                checked={SHIPMENT_PROFILE_DETAILS.config.geofence_time_interval ? true : false}
                                onChange={(e)=>{
                                    if(SHIPMENT_PROFILE_DETAILS.config.geofence_alert === 1){
                                        const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                        shipmentProfileDetails.config.geofence_time_interval = this.state.defaultTimeInterval;
                                        shipmentProfileDetails.config.geofence_time_increment = 0;
                                        shipmentProfileDetails.config.updated = true;
                                        this.setState({shipmentProfileDetails});
                                    }
                                }}
                                className="metrics-checkbox"
                                id="geofenceAlertSlider"
                                name="geofenceAlertType"
                                disabled={this.props.disableEdit}
                            />
                            <label className="metrics-label" htmlFor="geofenceAlertSlider">
                                <span>Alert when the shipment is</span>
                                <select
                                    disabled={(SHIPMENT_PROFILE_DETAILS.config.geofence_time_interval?false:true)||this.props.disableEdit}
                                    style={{ marginLeft: '6px',marginRight:"4px", marginTop: '16px',width:"180px",borderRadius:"3px" ,padding:"6px 20px 5px 10px"}}
                                    className="select-shipment-communicationInterval"
                                    value={SHIPMENT_PROFILE_DETAILS.config.geofence_time_interval}
                                    onChange={e => {
                                        const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                        shipmentProfileDetails.config.geofence_time_interval = e.target.value;
                                        shipmentProfileDetails.config.geofence_time_increment = 0;
                                        shipmentProfileDetails.config.updated = true;
                                        this.setState({shipmentProfileDetails});
                                    }}
                                >
                                    {
                                        ALERT_INTERVALS.map((option, index) => (
                                            <option value={option.id} key={`ALERT-INTERVALS-${index}`}>{option.label}</option>
                                        ))
                                    }
                                </select>
                            <span>away</span>
                            </label>
                            
                            </div>
                            <div className="geofenceAlert1" style={{display:"flex"}}>
                            <input
                                type="radio"
                                value={'increment'}
                                checked={SHIPMENT_PROFILE_DETAILS.config.geofence_time_increment ? true : false}
                                onChange={(e)=>{
                                    if(SHIPMENT_PROFILE_DETAILS.config.geofence_alert === 1){
                                        const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                        shipmentProfileDetails.config.geofence_time_interval = 0;
                                        shipmentProfileDetails.config.geofence_time_increment = this.state.defaultTimeIncrement;                                                                
                                        //shipmentProfileDetails.config.geofenceAlertType = e.target.value;
                                        shipmentProfileDetails.config.updated = true;
                                        this.setState({shipmentProfileDetails});
                                    }                                                    
                                }}
                                className="metrics-checkbox"
                                id="geofenceAlertSelect"
                                name="geofenceAlertType"
                                disabled={this.props.disableEdit}
                            />
                            <label className="metrics-label" htmlFor="geofenceAlertSelect">
                                <span>Alert at (3) increments of </span>
                                <select
                                    disabled={(SHIPMENT_PROFILE_DETAILS.config.geofence_time_increment?false:true)||this.props.disableEdit}
                                    style={{ marginLeft: '6px',marginRight:"4px", marginTop: '16px',width:"180px",borderRadius:"3px" ,padding:"6px 20px 5px 10px"}}
                                    className="select-shipment-communicationInterval"
                                    value={SHIPMENT_PROFILE_DETAILS.config.geofence_time_increment}
                                    onChange={e => {
                                        const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                        shipmentProfileDetails.config.geofence_time_interval = 0;
                                        shipmentProfileDetails.config.geofence_time_increment = e.target.value;                                                                
                                        shipmentProfileDetails.config.updated = true;
                                        this.setState({shipmentProfileDetails});
                                    }}
                                >
                                    {
                                        ALERT_INCREMENTS.map((option, index) => (
                                            <option value={option.id} key={`ALERT-INCREMENT-${index}`}>{option.label}</option>
                                        ))
                                    }
                                </select>
                                <span>away </span>
                            </label>
                            </div>
                        </div>
                    </div>
                    : 
                    ''
                }
                {SHIPMENT_PROFILE_DETAILS.config.errors?.geofence_time_interval?
                <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors.geofence_time_interval}</div>
                :""}
                {SHIPMENT_PROFILE_DETAILS.config.errors?.geofence_time_increment?
                <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors.geofence_time_increment}</div>
                :""}
            </div>
            <div className="fld-sec">
                <div className="i-sec d-f-a-c">
                    <div className={`status-bar${SHIPMENT_PROFILE_DETAILS.config.custom_geofence ? ' a' : ''}${this.props.disableEdit ? ' d': ''}`}
                        onClick={ (e) => {
                            e.stopPropagation();
                            if(this.props.disableEdit) {}
                            else {
                                const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                shipmentProfileDetails.config.custom_geofence = !shipmentProfileDetails.config.custom_geofence;
                                shipmentProfileDetails.config.updated = true;
                                if(shipmentProfileDetails.config.custom_geofence){}
                                else {
                                    if(shipmentProfileDetails.config.geofences.length) {
                                        shipmentProfileDetails.config.geofences.forEach(gf => {
                                            gf.delete = 1;
                                        });
                                    }
                                }
                                this.setState({shipmentProfileDetails});
                            }
                        }}
                    ></div>
                    <div className="fld-lbl" style={{marginLeft: '20px'}}>Create custom geofence <span className="c-info">(enables geofence for a specific location)</span></div>
                </div>
                <div className="geofenceAlertOptions" style={{marginLeft: '64px'}}>
                    {
                        SHIPMENT_PROFILE_DETAILS.config.geofences?.filter(gf => { if(gf.delete) { return false; } return true; })?.length < this.state.config.maxGeofences && !(this.props.disableEdit) && SHIPMENT_PROFILE_DETAILS.config.custom_geofence?
                        <div className="geo-add">
                            <div 
                                className={`c-btn s${SHIPMENT_PROFILE_DETAILS.config.custom_geofence || SHIPMENT_PROFILE_DETAILS?.config?.geofences?.length ? '': ' dis'}`}
                                onClick={(e)=>{
                                    e.stopPropagation();
                                    if(SHIPMENT_PROFILE_DETAILS.config.custom_geofence || SHIPMENT_PROFILE_DETAILS?.config?.geofences?.filter(g=>!g.delete)?.length) {

                                        let newGeoIndex = SHIPMENT_PROFILE_DETAILS.config.geofences.length+1;
                                        let geofence_name = `Geofence ${newGeoIndex}`;
                                        const geofenceNames = SHIPMENT_PROFILE_DETAILS.config.geofences?.map(c => c.geofence_name);
                                        while(geofenceNames.indexOf(geofence_name) >= 0) {
                                            geofence_name = `Geofence ${++newGeoIndex}`;
                                        }

                                        this.props.showGeofencePop(SHIPMENT_PROFILE_DETAILS, {
                                            showGeofencePop: true,
                                            geofenceTitle: 'Create geofence',
                                            geofenceData: {
                                                new: true, 
                                                radius: 1000, 
                                                geofence: [], 
                                                geofence_name, 
                                                type: 'simple_radius',
                                                existingGeoNames: geofenceNames,
                                                geofenceIndex: null
                                            },
                                            geofenceIndex: null
                                        });
                                    }                                                            
                                }}
                            >+ Add Geofence</div>
                        </div>
                        : ''
                    }
                    {   SHIPMENT_PROFILE_DETAILS.config.custom_geofence && SHIPMENT_PROFILE_DETAILS?.config?.geofences?.filter(g=>!g.delete)?.length?
                        SHIPMENT_PROFILE_DETAILS.config.geofences?.map((geofence, index) => {
                            return (
                                <div className={`geo-list-item${geofence.delete ? ' rem': ''}`} key={`geofence-item-${geofence.profile_id}-${geofence.id}-${index}`}>
                                    <div className="gf-col-0">{GeofenceLocationIcon({fill: '#ddd', className: 'geo-icn'})}</div>
                                    <div className="gf-col-1">{geofence.geofence_name || '--'}</div>
                                    <div className="gf-col-2">{geofence.address || '--'}</div>
                                    <div className="gf-col-3">{geofence.type || '--'}</div>
                                    <div className="gf-col-4">
                                        {this.props.disableEdit ? <></> :
                                        (<>
                                            {
                                                geofence.delete ?
                                                <span 
                                                    className="material-icons a-icn d"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.toggleCustomGeofence(geofence.id, 0);
                                                    }}
                                                >restore</span>
                                                :
                                                <>
                                                <span 
                                                    className="material-icons a-icn e" 
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        //this.editCustomGeofence(geofence.id);
                                                        const existingGeoNames = SHIPMENT_PROFILE_DETAILS.config.geofences?.map(c => c.geofence_name);
                                                        
                                                        this.props.showGeofencePop(SHIPMENT_PROFILE_DETAILS, {
                                                            showGeofencePop: true,
                                                            geofenceTitle: 'Edit geofence',
                                                            geofenceData: {
                                                                ...geofence,
                                                                existingGeoNames,
                                                                type: geofence.radius ? 'simple_radius' : 'custom_shape',
                                                                geofenceIndex: index
                                                            },
                                                            geofenceIndex: index
                                                        });
                                                    }}
                                                >edit</span>
    
                                                <span 
                                                    className="material-icons a-icn d"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.toggleCustomGeofence(geofence.id, 1);
                                                    }}
                                                >clear</span>
                                                </>
                                            }
                                        </>)
                                        }
                                    </div>
                                </div>
                            )
                        })
                        : ''
                    }                            
                </div>
                {SHIPMENT_PROFILE_DETAILS.config.errors?.custom_geofence?
                <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors.custom_geofence}</div>
                :""}
                {SHIPMENT_PROFILE_DETAILS.config.errors?.geofences?
                <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors.geofences}</div>
                :""}
            </div>
            <div className="fld-sec">
                <div className="i-sec d-f-a-c">
                    <div className={`status-bar${SHIPMENT_PROFILE_DETAILS.config.shipment_report ? ' a' : ''}${this.props.disableEdit ? ' d': ''}`}
                        onClick={ (e) => {
                            e.stopPropagation();
                            if(this.props.disableEdit) {}
                            else {
                                const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                shipmentProfileDetails.config.shipment_report = shipmentProfileDetails.config.shipment_report ? 0 : 1;                            
                                this.setState({shipmentProfileDetails});
                            }
                        }}
                    ></div>
                    <div className="fld-lbl" style={{marginLeft: '20px'}}>Auto report generation<span className="c-info">(enables to create shipment reports)</span></div>
                </div>
                {
                    SHIPMENT_PROFILE_DETAILS.config.shipment_report ?
                    <div className="s-r-conf" style={{paddingLEft:'65px'}}>
                        <div className="i-sec">
                            <div className="i-lbl">Report Type:</div>
                            <div className="i-fld f">
                                <div className="i-fld-box">
                                    <div className={`status-bar s${SHIPMENT_PROFILE_DETAILS.config.report_configuration.report_type === "PDF" ? ' a': ''}`}
                                        onClick={ (e) => {
                                            e.stopPropagation();
                                            if(this.props.disableEdit) {}
                                            else {
                                                const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                shipmentProfileDetails.config.report_configuration.report_type = "PDF";
                                                this.setState({shipmentProfileDetails});
                                            }
                                        }}
                                    ></div>
                                    <div className="i-fld-lbl">PDF</div>
                                </div>
                                <div className="i-fld-box">
                                    <div className={`status-bar s${SHIPMENT_PROFILE_DETAILS.config.report_configuration.report_type === "PDF-RAW" ? ' a': ''}`}
                                        onClick={ (e) => {
                                            e.stopPropagation();
                                            if(this.props.disableEdit) {}
                                            else {
                                                const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                shipmentProfileDetails.config.report_configuration.report_type = "PDF-RAW";
                                                this.setState({shipmentProfileDetails});
                                            }
                                        }}
                                    ></div>
                                    <div className="i-fld-lbl">PDF + Raw data</div>
                                </div>
                                <div className="i-fld-box">
                                    <div 
                                        className={`status-bar s${SHIPMENT_PROFILE_DETAILS.config.report_configuration.report_type === "CSV" ? ' a': ''}${this.props.disableEdit ? ' d': ''}`}
                                        onClick={ (e) => {
                                            e.stopPropagation();
                                            if(this.props.disableEdit) {}
                                            else {
                                                const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                shipmentProfileDetails.config.report_configuration.report_type = "CSV";
                                                this.setState({shipmentProfileDetails});
                                            }
                                        }}
                                    ></div>
                                    <div className="i-fld-lbl">CSV</div>
                                </div>
                            </div>
                            {
                                SHIPMENT_PROFILE_DETAILS.config.errors?.report_configuration?.report_type ? 
                                <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors.report_configuration.report_type}</div>
                                : ''
                            }
                        </div>
                        <div className="i-sec" style={{alignItems: "baseline"}}>
                            <div className="i-lbl">Sensor Data:</div>
                            <div 
                                className="i-fld f"
                                onClick={e => {
                                    e.stopPropagation();
                                    if(e.target.className.indexOf('check-box') >= 0 && !this.props.disableEdit){
                                        const type = e.target.dataset['sensor'];
                                        const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                        if(type === 'Temperature') {
                                            shipmentProfileDetails.config.report_configuration.sensors[type] = !shipmentProfileDetails.config.report_configuration.sensors[type];
                                            shipmentProfileDetails.config.report_configuration.sensors.MKT = shipmentProfileDetails.config.report_configuration.sensors[type];
                                        } else if (type === 'Location'){
                                            shipmentProfileDetails.config.report_configuration.sensors[type] = !shipmentProfileDetails.config.report_configuration.sensors[type];
                                            shipmentProfileDetails.config.report_configuration.sensors.Mode = shipmentProfileDetails.config.report_configuration.sensors[type];
                                        } else {
                                            if((type === 'MKT' && shipmentProfileDetails.config.report_configuration.sensors.Temperature) || (type === 'Mode' && shipmentProfileDetails.config.report_configuration.sensors.Location) || (type !== 'Mode' && type !== 'MKT')) {
                                                shipmentProfileDetails.config.report_configuration.sensors[type] = !shipmentProfileDetails.config.report_configuration.sensors[type];
                                            }                                                            
                                        }
                                        if(shipmentProfileDetails.config.report_configuration.sensors[type]){
                                            let selectAll = true;
                                            for(let i in shipmentProfileDetails.config.report_configuration.sensors) {
                                                if(!shipmentProfileDetails.config.report_configuration.sensors[i] && i !== 'all') {
                                                    selectAll = false;
                                                }
                                            }
                                            shipmentProfileDetails.config.report_configuration.sensors.all = selectAll;
                                        } else {
                                            shipmentProfileDetails.config.report_configuration.sensors.all = false;
                                        }
                                        
                                        this.setState({shipmentProfileDetails});
                                    }
                                }}                                            
                            >
                                <div className={`col-0`}>
                                    <div className="sensor-item">
                                        <div className={`check-box${SHIPMENT_PROFILE_DETAILS.config.report_configuration.sensors.Location ? ' a': ''}`} data-sensor="Location">Location</div><div></div>
                                        <div className={`check-box c${SHIPMENT_PROFILE_DETAILS.config.report_configuration.sensors.Mode ? ' a': ''}${SHIPMENT_PROFILE_DETAILS.config.report_configuration.sensors.Location?'': ' d'}`} data-sensor="Mode">Mode</div>
                                    </div>
                                    <div className="sensor-item">
                                        <div className={`check-box${SHIPMENT_PROFILE_DETAILS.config.report_configuration.sensors.Humidity ? ' a': ''}`} data-sensor="Humidity">Humidity</div>
                                    </div>
                                    <div className="sensor-item">
                                        <div className={`check-box${SHIPMENT_PROFILE_DETAILS.config.report_configuration.sensors.Shock ? ' a': ''}`} data-sensor="Shock">Shock</div>
                                    </div>
                                    <div className="sensor-item">
                                        <div className={`check-box${SHIPMENT_PROFILE_DETAILS.config.report_configuration.sensors.Battery ? ' a': ''}`} data-sensor="Battery">Battery</div>
                                    </div>
                                </div>
                                <div className={`col-0`}>
                                    <div className="sensor-item">
                                        <div className={`check-box${SHIPMENT_PROFILE_DETAILS.config.report_configuration.sensors.Temperature ? ' a': ''}`} data-sensor="Temperature">Temperature</div><div></div>
                                        <div className={`check-box c${SHIPMENT_PROFILE_DETAILS.config.report_configuration.sensors.MKT ? ' a': ''}${SHIPMENT_PROFILE_DETAILS.config.report_configuration.sensors.Temperature ? '': ' d'}`} data-sensor="MKT">MKT</div>
                                    </div>
                                    <div className="sensor-item">
                                        <div className={`check-box${SHIPMENT_PROFILE_DETAILS.config.report_configuration.sensors.Light ? ' a': ''}`} data-sensor="Light">Light</div>
                                    </div>
                                    <div className="sensor-item">
                                        <div className={`check-box${SHIPMENT_PROFILE_DETAILS.config.report_configuration.sensors.Tilt ? ' a': ''}`} data-sensor="Tilt">Tilt</div>
                                    </div>
                                    <div className="sensor-item">
                                        <div className="i-fld-box">
                                            <div 
                                                className={`status-bar s${SHIPMENT_PROFILE_DETAILS.config.report_configuration.sensors.all ? ' a': ''}`}
                                                onClick={ (e) => {
                                                    e.stopPropagation();
                                                        const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                        
                                                        if(shipmentProfileDetails.config.report_configuration.sensors.all) {
                                                            for(let i in shipmentProfileDetails.config.report_configuration.sensors) {
                                                                shipmentProfileDetails.config.report_configuration.sensors[i] = false;
                                                            }
                                                        } else {
                                                            for(let i in shipmentProfileDetails.config.report_configuration.sensors) {
                                                                shipmentProfileDetails.config.report_configuration.sensors[i] = true;
                                                            }
                                                        }
                                                        
                                                        this.setState({shipmentProfileDetails});
                                                }}
                                            ></div>
                                            <div className="i-fld-lbl">Select All</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                SHIPMENT_PROFILE_DETAILS.config.errors?.['report_configuration.sensors'] ? 
                                <div className="error_msg" style={{position:'absolute', left:0}}>{SHIPMENT_PROFILE_DETAILS.config.errors['report_configuration.sensors']}</div>
                                : ''
                            }
                        </div>
                        <div className="i-sec n-b">
                            <div className="i-lbl">Date Range:</div>
                            <div className="i-fld" style={{display: 'flex'}}>
                                <SelectBox
                                    style={{width: '200px'}}
                                    popCss={'left'}
                                    css={'sort-lbl'}
                                    list={[{label:'Full Shipment Duration', id:'FULL'}, {label:'Custom', id:'CUSTOM'}]}
                                    value={SHIPMENT_PROFILE_DETAILS.config.report_configuration.range_type}
                                    onChange={(o) => {
                                        const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                        shipmentProfileDetails.config.report_configuration.range_type = o.id;
                                        let {showDateRange} = this.state;
                                        if(shipmentProfileDetails.config.report_configuration.range_type === 'CUSTOM') {
                                            showDateRange = true;
                                        } else {
                                            showDateRange = false;
                                        }
                                        this.setState({shipmentProfileDetails, showDateRange});
                                    }}
                                    disable={true}
                                ></SelectBox>
                                {
                                    this.state.showDateRange ?
                                    <div className="custom-date-range-box">
                                        <DateRange
                                            editableDateInputs={true}
                                            onChange={item => {
                                                const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                shipmentProfileDetails.config.report_configuration.date_range = item.selection;
                                                this.setState({shipmentProfileDetails});
                                            }}
                                            minDate={new Date()}
                                            maxDate={new Date()}
                                            ranges={[SHIPMENT_PROFILE_DETAILS.config.report_configuration.date_range]}
                                        />
                                        <div 
                                            className="c-btn"
                                            onClick={e => {
                                                e.stopPropagation();
                                                const showDateRange = false;
                                                this.setState({showDateRange});
                                            }}
                                        >Ok</div>
                                    </div>
                                    :
                                    SHIPMENT_PROFILE_DETAILS.config.report_configuration.range_type === 'CUSTOM' ?
                                    <span
                                        className="date-range-txt"
                                        onClick={e => {
                                            e.stopPropagation();
                                            const showDateRange = true;
                                            this.setState({showDateRange});
                                        }}
                                    >{moment(SHIPMENT_PROFILE_DETAILS.config.report_configuration.date_range.startDate).format('MM/DD/YYYY')} - {moment(SHIPMENT_PROFILE_DETAILS.config.report_configuration.date_range.endDate).format('MM/DD/YYYY')}</span>
                                    : ''
                                }
                            </div>
                            {
                                SHIPMENT_PROFILE_DETAILS.config.errors?.report_configuration?.range_type ? 
                                <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors.range_type}</div>
                                :
                                SHIPMENT_PROFILE_DETAILS.config.errors?.report_configuration?.to_date ? 
                                <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors.report_configuration.to_date}</div>
                                :
                                SHIPMENT_PROFILE_DETAILS.config.errors?.report_configuration?.from_date ? 
                                <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors.report_configuration.from_date}</div>
                                : ''
                            }
                        </div>
                        <div className="i-sec n-b">
                            <div className="i-lbl">Report Timezone:</div>
                            <div className="i-fld">
                                <SelectBox
                                    id={'report_tz'}
                                    allowSearch={true}
                                    style={{width:'300px'}}
                                    popCss={'left'}
                                    css={'sort-lbl'}
                                    list={this.state.timeZones}
                                    value={SHIPMENT_PROFILE_DETAILS.config.report_configuration.time_zone_code}
                                    onChange={(o) => {
                                        const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                        shipmentProfileDetails.config.report_configuration.time_zone_diff = o.obj.value;
                                        shipmentProfileDetails.config.report_configuration.time_zone_code = o.id;
                                        this.setState({shipmentProfileDetails});
                                    }}
                                    disabled={this.props.disableEdit}
                                ></SelectBox>
                            </div>
                            {
                                SHIPMENT_PROFILE_DETAILS.config.errors?.report_configuration?.time_zone_diff ? 
                                <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors.report_configuration.time_zone_diff}</div>
                                : ''
                            }
                        </div>
                        <div className="i-sec n-b">
                            <div className="i-lbl">Generate:</div>
                            <div className="i-fld f">
                                <div 
                                    className="i-fld-box"
                                    onClick={e => {
                                        e.stopPropagation();
                                        return false;
                                        if(e.target.className.indexOf('radio-box') >= 0){
                                            const occurrence = e.target.dataset['occurrence'];
                                            const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                            shipmentProfileDetails.config.report_configuration.occurrence = occurrence;
                                            this.setState({shipmentProfileDetails});
                                        }
                                    }}
                                >
                                    <div className={`col-0`}>
                                        <div className={`radio-box${SHIPMENT_PROFILE_DETAILS.config.report_configuration.occurrence === 'Immediate' ? ' a': ''}`} data-occurrence="Immediate" style={{opacity: '0.6', cursor: 'not-allowed'}}>Immediately</div>
                                    </div>
                                    <div className={`col-0`}>
                                        <div className={`radio-box${SHIPMENT_PROFILE_DETAILS.config.report_configuration.occurrence === 'ShipmentCompletion' ? ' a': ''}`} data-occurrence="ShipmentCompletion">After shipment completion</div>
                                    </div>
                                </div>
                            </div>
                            {
                                SHIPMENT_PROFILE_DETAILS.config.errors?.report_configuration?.occurrence ? 
                                <div className="error_msg">{SHIPMENT_PROFILE_DETAILS.config.errors.report_configuration.occurrence}</div>
                                : ''
                            }
                        </div>
                        <div className="i-sec n-b" style={{alignItems: "flex-start"}}>
                            <div className="i-lbl">Add Email:</div>
                            <div className="i-fld notify-users">
                            {
                            SHIPMENT_PROFILE_DETAILS.config.report_configuration.notification_users?.length ?
                                <div className="selected-items">
                                    <ul className="group-selected-companies no-scroll" key={`selected-users-list-${SHIPMENT_PROFILE_DETAILS.config.id}`}>
                                        {SHIPMENT_PROFILE_DETAILS.config.report_configuration.notification_users.map((user, uIndex) => (
                                            <li
                                                key={`ship-user-${user.userId}`}
                                                className={`group-selected-companies-li`}
                                                title={user}
                                            >
                                                {user}
                                                {
                                                    this.props.disableEdit ?
                                                    <></>
                                                    :
                                                    <span
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                            let userIndex = shipmentProfileDetails.config.report_configuration.notification_users.findIndex(u => u === user);
                                                            if(userIndex >= 0) {
                                                                shipmentProfileDetails.config.report_configuration.notification_users.splice(userIndex, 1);
                                                            }
                                                            
                                                            this.setState({shipmentProfileDetails});
                                                        }}
                                                    >x</span>
                                                }
                                            </li>
                                        )
                                        )}
                                    </ul>
                                    {
                                        SHIPMENT_PROFILE_DETAILS.config.report_configuration.notification_users?.length > 0 && !this.props.disabledEdit ?
                                        <span
                                            className="group-select-delete-all"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                if(shipmentProfileDetails.config.report_configuration.notification_userss?.length) {
                                                    shipmentProfileDetails.config.report_configuration.notification_users.length = [];
                                                    this.setState({shipmentProfileDetails});
                                                    document.getElementById(`add_ship_report_users`).focus();
                                                }
                                            }}
                                        >Clear all</span> : ''
                                    }                            
                                </div> : ''
                            }
                            <input
                                type="text"
                                className="ip-fld"
                                placeholder="Add Email..."
                                maxLength="70"
                                autoComplete='off'
                                name="users"
                                id="add_ship_report_users"
                                value={SHIPMENT_PROFILE_DETAILS.config.report_configuration.addUserSearch}
                                onChange={(e) => {
                                    const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                    shipmentProfileDetails.config.report_configuration.addUserSearch = e.target.value;
                                    this.setState({shipmentProfileDetails});
                                }}
                                disabled={(SHIPMENT_PROFILE_DETAILS.config.report_configuration.notification_users?.length < this.state.config.maxReportUserLimit ? false : true)||this.props.disabledEdit}
                            />
                            {SHIPMENT_PROFILE_DETAILS.config.report_configuration.notification_users?.length >= this.state.config.maxReportUserLimit ? <div className="error_msg" style={{position: 'absolute'}}>* Maximum user limit({this.state.config.maxReportUserLimit}) reached!</div> : <></>}
                            {
                                SHIPMENT_PROFILE_DETAILS.config.errors && SHIPMENT_PROFILE_DETAILS.config.errors.report_configuration?.notification_users ?
                                <div className="error_msg" style={{position: 'absolute'}}>{SHIPMENT_PROFILE_DETAILS.config.errors.report_configuration.notification_users}</div>
                                : <></>
                            } 
                            {                                                        
                                SHIPMENT_PROFILE_DETAILS.config.report_configuration.addUserSearch?.length >= 1 ?
                                <ul className="search-cat-pop" style={{ top: 'unset', bottom: '40px' }}>                                            
                                    {REPORT_USERS?.map((user, i) => (
                                        <li
                                            className={`sel-item`}
                                            key={`search-user-${i}`}
                                            data-id={user.id}
                                            title={user.email}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                                const userIndex = SHIPMENT_PROFILE_DETAILS.config.report_configuration.notification_users?.findIndex(u => u === user.email) || -1;
                                                if(userIndex >= 0) {
                                                } else {
                                                    shipmentProfileDetails.config.report_configuration.notification_users = shipmentProfileDetails.config.report_configuration.notification_users || [];
                                                    shipmentProfileDetails.config.report_configuration.notification_users.push(user.email);
                                                }
                                                shipmentProfileDetails.config.report_configuration.addUserSearch = '';
                                                this.setState({shipmentProfileDetails});
                                                document.getElementById(`add_ship_report_users`).focus();
                                            }
                                            }
                                        >
                                            {user.email}
                                        </li>
                                    ))}
                                    {
                                        REPORT_USERS.length === 0 ?
                                        <>
                                        <li
                                            className="no-item"
                                            key={`search-user-no-users`}>No users found...</li>
                                        </>
                                        : ''
                                    }
                                </ul>
                                : ''
                            }
                            {((SHIPMENT_PROFILE_DETAILS.config.report_configuration.notification_users?.length || 0) < this.state.config.maxReportUserLimit && REPORT_USERS.length === 0 && new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i).test(SHIPMENT_PROFILE_DETAILS.config.report_configuration.addUserSearch)) && !this.props.disableEdit ?
                            <span 
                                className="add-new-u u"
                                onClick={e => {
                                    e.stopPropagation();
                                    const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                    shipmentProfileDetails.config.report_configuration.notification_users = shipmentProfileDetails.config.report_configuration.notification_users || [];
                                    const userIndex = shipmentProfileDetails.config.report_configuration.notification_users.findIndex( u => u === SHIPMENT_PROFILE_DETAILS.config.report_configuration.addUserSearch);
                                    if(userIndex >= 0 ) {
                                        
                                    } else {
                                        shipmentProfileDetails.config.report_configuration.notification_users.push(SHIPMENT_PROFILE_DETAILS.config.report_configuration.addUserSearch);
                                    }
                                    
                                    shipmentProfileDetails.config.report_configuration.addUserSearch = '';
                                    this.setState({shipmentProfileDetails});
                                    document.getElementById(`add_ship_report_users`).focus();
                                }}
                            >Add Email</span> : ''}
                            </div>        
                        </div>
                    </div>
                : <></>
                }
            </div>
            {
                this.props.disableEdit ? <></> :
                <div className="c-btn-sec s-b">
                    <div className="btn-sec-l">
                        <div className={`status-bar${SHIPMENT_PROFILE_DETAILS.config.apply_changes_to_active_shipments ? ' a' : ''}`}
                            onClick={ (e) => {
                                e.stopPropagation();
                                const shipmentProfileDetails = {...SHIPMENT_PROFILE_DETAILS};
                                shipmentProfileDetails.config.apply_changes_to_active_shipments = shipmentProfileDetails.config.apply_changes_to_active_shipments ? false : true;
                                shipmentProfileDetails.config.updated = true;
                                this.setState({shipmentProfileDetails});
                            }}
                        ></div>
                        <div className="fld-lbl" style={{marginLeft: '20px'}}>Apply these changes to Live shipments</div>
                    </div>
                    <div className="btn-sec-r">
                        <div 
                            className="c-btn g"
                            onClick={e => {
                                e.stopPropagation();
                                this.props.cancelShipmentProfile();
                            }}
                        >Cancel</div>
                        <div 
                            className="c-btn"
                            onClick={e => {
                                e.stopPropagation();
                                if(this.props.shipmentProfileLoading || this.props.shipmentProfilesaving || this.props.shipmentProfileListLoading) {}
                                else{
                                    this.props.saveShipmentProfile(this.state.shipmentProfileDetails);
                                }
                            }}
                        >Save</div>
                    </div>
                </div>
            }                       
            </>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

export default connect(mapStateToProps, {
    getShipmentLocation    
})(ShipmentProfile);
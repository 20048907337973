import * as actionTypes from './actionTypes';
//import environment from '../../environment';
import { AXIOS } from '../../utility/Axios';
import { sendLogout, sendNetworkError, sendInternalServerError } from './company';
import { getNewToken } from './assetTracking';
import {googleAnalyticActions} from '../../init-ga';
const apis = require(`config/${process.env.REACT_APP_CUSTOMER_ID}/apis.js`).default;

//const getToken = () => localStorage.getItem('access_token');
const userRole = () => localStorage.getItem('roles').toLowerCase();

export const getDashboardDetailsStart = () => {
    return {
        type: actionTypes.GET_DASHBOARD_DETAILS_START,
    };
};
export const getDashboardDetailsSuccess = data => {
    return {
        type: actionTypes.GET_DASHBOARD_DETAILS_SUCCESS,
        data: data,
    };
};
export const getDashboardDetailsFailed = error => {
    return {
        type: actionTypes.GET_DASHBOARD_DETAILS_FAILED,
        error,
    };
};

export const getDashboardDetails = (data) => {
    /*To be modified after login integration.....*/
    return dispatch => {
        dispatch(getDashboardDetailsStart());
        const requestUrl = apis.dashboard.get_dashboard_details;
        AXIOS({
            method: 'get',
            url: requestUrl,
            responseType: 'json',
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
            .then(response => {
                if (response === undefined) {
                    dispatch(sendLogout(true));
                } else if(response.networkError) {
                    dispatch(sendNetworkError(true));
                } else {
                    dispatch(getDashboardDetailsSuccess(response.data.data));
                    if (data.callback && typeof data.callback === 'function') {
                        data.callback(response.data.data);
                    }
                }
            })
            .catch(err => {
                dispatch(getDashboardDetailsFailed(err));
            });
    };
};

export const getConfigurationListStart = () => {
    return {
        type: actionTypes.GET_CONFIGURATION_LIST_START,
    };
};
export const getConfigurationListSuccess = data => {
    return {
        type: actionTypes.GET_CONFIGURATION_LIST_SUCCESS,
        data: data,
    };
};
export const getConfigurationListFailed = error => {
    return {
        type: actionTypes.GET_CONFIGURATION_LIST_FAILED,
        error,
    };
};

export const getConfigurationList = () => {
    return dispatch => {
        dispatch(getConfigurationListStart());
        const requestUrl = apis.dashboard.get_dashboard_config_list;
        AXIOS({
            method: 'get',
            url: requestUrl,
            responseType: 'json',
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
            .then(response => {
                if (response === undefined) {
                    dispatch(sendLogout(true));
                } else if(response.networkError) {
                    dispatch(sendNetworkError(true));
                } else {
                    dispatch(getConfigurationListSuccess(response.data.data));
                }
            })
            .catch(err => {
                dispatch(getConfigurationListFailed(err));
            });
    };
};

export const setConfigurationStart = () => {
    return {
        type: actionTypes.SET_CONFIGURATION_START,
    };
};
export const setConfigurationSuccess = data => {
    return {
        type: actionTypes.SET_CONFIGURATION_SUCCESS,
        data: data,
    };
};
export const setConfigurationFailed = error => {
    return {
        type: actionTypes.SET_CONFIGURATION_FAILED,
        error,
    };
};

export const setConfiguration = data => {
    return dispatch => {
        dispatch(setConfigurationStart());
        const requestUrl = apis.dashboard.set_dashboard_config;
        AXIOS({
            method: 'post',
            url: requestUrl,
            responseType: 'json',
            data: data,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
            .then(response => {
                if (response === undefined) {
                    dispatch(sendLogout(true));
                } else if(response.networkError) {
                    dispatch(sendNetworkError(true));
                } else {
                    dispatch(setConfigurationSuccess(response.data.data));
                }
            })
            .catch(err => {
                dispatch(setConfigurationFailed(err));
            });
    };
};

export const setConfigurationOrderStart = () => {
    return {
        type: actionTypes.SET_CONFIGURATION_ORDER_START,
    };
};
export const setConfigurationOrderSuccess = data => {
    return {
        type: actionTypes.SET_CONFIGURATION_ORDER_SUCCESS,
        data: data,
    };
};
export const setConfigurationOrderFailed = error => {
    return {
        type: actionTypes.SET_CONFIGURATION_ORDER_FAILED,
        error,
    };
};

export const setConfigurationOrder = data => {
    return dispatch => {
        dispatch(setConfigurationOrderStart());
        const requestUrl = apis.dashboard.set_dashboard_config_order;
        AXIOS({
            method: 'post',
            url: requestUrl,
            responseType: 'json',
            data: data,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
            .then(response => {
                if (response === undefined) {
                    dispatch(sendLogout(true));
                } else if(response.networkError) {
                    dispatch(sendNetworkError(true));
                } else {
                    dispatch(setConfigurationOrderSuccess(response.data.data));
                }
            })
            .catch(err => {
                dispatch(setConfigurationOrderFailed(err));
            });
    };
};

export const getSearchCategoryStart = () => {
    return {
        type: actionTypes.GET_SEARCH_CATEGORY_START,
    };
};
export const getSearchCategorySuccess = data => {
    return {
        type: actionTypes.GET_SEARCH_CATEGORY_SUCCESS,
        data: data,
    };
};
export const getSearchCategoryFailed = error => {
    return {
        type: actionTypes.GET_SEARCH_CATEGORY_FAILED,
        error,
    };
};

export const getSearchCategory = queryString => {
    return dispatch => {
        dispatch(getSearchCategoryStart());
        //const requestUrl=`dashboard/configure`;
        // AXIOS({
        //     url: requestUrl,
        //     responseType: 'json',
        //     data: data,
        //     onInternalServerError: () => {
        //         dispatch(sendInternalServerError(true));
        //     }
        // }).then((response)=>{
        const str = queryString.toLowerCase();
        let result = [];

        if (str.indexOf('px') === 0) {
            result = [
                {
                    categoryId: 'shipmentID',
                    label: ['Search ','',' in shipments'],
                    index: 1,
                    targetString: queryString,
                },
            ];
        } else if (str.indexOf('tx') === 0 || str.indexOf('tn') === 0) {
            result = [
                {
                    categoryId: 'deviceID',
                    label: ['Search shipments linked to ', ''],
                    index: 1,
                    targetString: queryString,
                },
            ];
        } else if (Number(str)) {
            result = [
                {
                    categoryId: 'shipmentID,deviceID',
                    label: ['Search ', '',' in device shipments'],
                    index: 1,
                    targetString: queryString,
                },
                {
                    categoryId: 'trackingId',
                    label: ['Search ','',' in (USPS, FedEx, UPS) shipments'],
                    index: 1,
                    targetString: queryString,
                }
            ]
        } else {
            if (userRole() === 'super admin' || userRole() === 'group admin') {
                result.push({
                    categoryId: 'company',
                    label: ['Shipments created by company ', ''],
                    index: 1,
                    targetString: queryString,
                });
            }
            result.push(
                ...[
                    {
                        categoryId: 'destination',
                        label: ['Shipments arriving at ', ''],
                        index: 1,
                        targetString: queryString,
                    },
                    {
                        categoryId: 'source',
                        label: ['Shipments starting from ', ''],
                        index: 1,
                        targetString: queryString,
                    },
                    {
                        categoryId: 'trackingId',
                        label: ['Search ', '',' in (USPS, FedEx, UPS) shipments'],
                        index: 1,
                        targetString: queryString,
                    }
                ]                               
            );
            if (userRole() === 'super admin' || userRole() === 'group admin' || userRole() === 'company admin') {
                result.push({
                    categoryId: 'userName',
                    label: ['Shipments created by user ', ''],
                    index: 1,
                    targetString: queryString,
                });
            }
        }
        result.push({
            categoryId: 'shipment_description',
            label: ['Shipments with description ', ''],
            index: 1,
            targetString: queryString,
        });

        dispatch(getSearchCategorySuccess(result));
        // }).catch(err=>{
        //     dispatch(getSearchCategoryFailed(err));

        // });
    };
};

export const getShipmentListMapStart = () => {
    return {
        type: actionTypes.GET_SHIPMENT_LIST_MAP_START
    };
};
export const getShipmentListMapSuccess = (data) => {
    return {
        type: actionTypes.GET_SHIPMENT_LIST_MAP_SUCCESS,
        data: data
    };
};
export const getShipmentListMapFailed = error => {
    return {
        type: actionTypes.GET_SHIPMENT_LIST_MAP_FAILED,
        error,
    };
};

export const getShipmentListStart = (page) => {
    return {
        type: actionTypes.GET_SHIPMENT_LIST_START,
        page: page
    };
};
export const getShipmentListSuccess = (data, isNextPage, meta, filtersCount) => {
    googleAnalyticActions.addEvent("Shipment","Shipment search")
    return {
        type: actionTypes.GET_SHIPMENT_LIST_SUCCESS,
        data: data,
        isNextPage: isNextPage,
        meta: meta,
        filtersCount: filtersCount,
    };
};
export const getShipmentListFailed = error => {
    return {
        type: actionTypes.GET_SHIPMENT_LIST_FAILED,
        error,
    };
};

export const getShipmentList = data => {
    return dispatch => {
        const params = {
            mode: 'web',
        };
        //console.log('data: ', data);
        const config = Object.assign(data, params);
        
        if(data.nolimit === true) {
            dispatch(getShipmentListMapStart());
        } else {
            if (data.isNextPage) {
                dispatch(getShipmentListSuccess([], true, {}));
            } else {
                dispatch(getShipmentListStart(config.page));
            }
        }
        
        const newConfig = { ...config };
        if (newConfig) {
            delete newConfig.callback;
            delete newConfig.callbackBeforeUpdate;
            delete newConfig.headerText;
            delete newConfig.shipmentId;
            delete newConfig.total;
            delete newConfig.statusKeys;
        }
		if (userRole() !== 'super admin' && userRole() !== 'group admin') {
			delete newConfig.company;
		}

        const requestUrl = apis.dashboard.get_shipment_list;
        AXIOS({
            url: requestUrl,
            responseType: 'json',
            Params: newConfig,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
            .then(response => {
                if (response === undefined) {
                    dispatch(sendLogout(true));
                } else if(response.networkError) {
                    dispatch(sendNetworkError(true));
                } else {
                    if (data.callbackBeforeUpdate && typeof data.callbackBeforeUpdate === 'function') {
                        data.callbackBeforeUpdate(response.data.data ? response.data.data[0] : []);
                    }
                    if(data.nolimit === true) {
                        dispatch(
                            getShipmentListMapSuccess(
                                response.data.data
                            )
                        );
                    } else {
                        dispatch(
                            getShipmentListSuccess(
                                response.data.data,
                                false,
                                response.data.meta,
                                response.data.filtersCount || response.data.secondaryStatusCount
                            )
                        );
                    }
                    
                    if (data.callback && typeof data.callback === 'function') {
                        data.callback(response.data.data ? response.data.data[0] : []);
                    }
                }
            })
            .catch(err => {
                if(data.nolimit === true) {
                    dispatch(getShipmentListMapFailed(err));
                } else {
                    dispatch(getShipmentListFailed(err));
                }
            });
    };
};

export const getDashboardShipmentListStart = (page) => {
    return {
        type: actionTypes.GET_DASHBOARD_SHIPMENT_LIST_START,
        page: page
    };
};
export const getDashboardShipmentListSuccess = (data, activeCount, individualStatus) => {
    return {
        type: actionTypes.GET_DASHBOARD_SHIPMENT_LIST_SUCCESS,
        data,
        activeCount,
        individualStatus
    };
};
export const getDashboardShipmentListFailed = error => {
    return {
        type: actionTypes.GET_DASHBOARD_SHIPMENT_LIST_FAILED,
        error,
    };
};

export const getDashboardShipmentList = data => {
    return dispatch => {
        const params = {
            mode: 'web',
        };
        //console.log('data: ', data);
        const config = Object.assign(data, params);
        
        dispatch(getDashboardShipmentListStart());

        
        const newConfig = { ...config };
        if (newConfig) {
            delete newConfig.callback;
            delete newConfig.callbackBeforeUpdate;
            delete newConfig.headerText;
            delete newConfig.shipmentId;
            delete newConfig.total;
            delete newConfig.statusKeys;
        }
		if (userRole() !== 'super admin' && userRole() !== 'group admin') {
			delete newConfig.company;
		}

        const requestUrl = apis.dashboard.get_dashboard_shipment_list;
       
        AXIOS({
            url: requestUrl,
            responseType: 'json',
            Params: newConfig,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
            .then(response => {
                if (response === undefined) {
                    dispatch(sendLogout(true));
                } else if(response.networkError) {
                    dispatch(sendNetworkError(true));
                } else {
                    if (data.callbackBeforeUpdate && typeof data.callbackBeforeUpdate === 'function') {
                        data.callbackBeforeUpdate(response.data.data ? response.data.data[0] : []);
                    }

                    dispatch(
                        getDashboardShipmentListSuccess(
                            response.data.data,
                            response.data.activeCount,
                            response.data.individualStatus
                        )
                    );

                    
                    if (data.callback && typeof data.callback === 'function') {
                        data.callback(response.data.data ? response.data.data[0] : []);
                    }
                }
            })
            .catch(err => {
                dispatch(getDashboardShipmentListFailed(err));
            });
    };
};

export const getCompanyListStart = () => {
    return {
        type: actionTypes.GET_COMPANY_LIST_START,
    };
};
export const getCompanyListSuccess = data => {
    return {
        type: actionTypes.GET_COMPANY_LIST_SUCCESS,
        data: data,
    };
};
export const getCompanyListFailed = error => {
    return {
        type: actionTypes.GET_COMPANY_LIST_FAILED,
        error,
    };
};

export const getCompanyList = data => {
    return dispatch => {
        const params = {
            limit: 50000,
        };
        const config = Object.assign(data||{}, params);
        dispatch(getCompanyListStart());
        const requestUrl = apis.dashboard.get_company_list;
        AXIOS({
            url: requestUrl,
            responseType: 'json',
            Params: config,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
        .then(response => {
            if (response === undefined) {
                if(window.location.pathname.indexOf('/assets') >= 0) {
                    dispatch(getNewToken({
                        onComplete: () => {
                            dispatch(getCompanyList());
                        }
                    }));
                } else {
                    dispatch(sendLogout(true));
                }
            } else if(response.networkError) {
                dispatch(sendNetworkError(true));
            } else {
                dispatch(getCompanyListSuccess(response.data.data));
            }
        })
        .catch(err => {
            dispatch(getCompanyListFailed(err));
        });
    };
};

export const getShipmentDetailsStart = () => {
    return {
        type: actionTypes.GET_SHIPMENT_DETAILS_START,
    };
};
export const getShipmentDetailsSuccess = data => {
    return {
        type: actionTypes.GET_SHIPMENT_DETAILS_SUCCESS,
        data: data,
    };
};
export const getShipmentDetailsFailed = error => {
    return {
        type: actionTypes.GET_SHIPMENT_DETAILS_FAILED,
        error,
    };
};

export const getShipmentDetails = data => {
    return dispatch => {
        dispatch(getShipmentDetailsStart());
        let requestUrl = apis.dashboard.get_device_shipment_details(data.id);
        if(data.carrier) {
            requestUrl = apis.dashboard.get_carrier_shipment_details(data.id);
        }

        AXIOS({
            url: requestUrl,
            responseType: 'json',
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
            .then(response => {
                //console.log('-------------response-----------______>>>>>>>>>>> ', response, response.response);
                if (response === undefined) {
                    dispatch(sendLogout(true));
                } else if(response.networkError) {
                    dispatch(sendNetworkError(true));
                } else if(response?.data?.data) {
                    if (data.callbackBeforeUpdate && typeof data.callbackBeforeUpdate === 'function') {
                        data.callbackBeforeUpdate(response.data.data ? response.data.data : {});
                    }
                    const DATA = {...response.data.data};
                    dispatch(getShipmentDetailsSuccess(DATA));
                } else {
                    dispatch(getShipmentDetailsFailed(response.response));
                    if (data.callbackOnComplete && typeof data.callbackOnComplete === 'function') {
                        data.callbackOnComplete(response.response);
                    }
                }
            })
            .catch(err => {
                dispatch(getShipmentDetailsFailed(err));
            });
    };
};

export const getShipmentTimelineStart = () => {
    return {
        type: actionTypes.GET_SHIPMENT_TIMELINE_START,
    };
};
export const getShipmentTimelineSuccess = data => {
    return {
        type: actionTypes.GET_SHIPMENT_TIMELINE_SUCCESS,
        data: data,
    };
};
export const getShipmentTimelineFailed = error => {
    return {
        type: actionTypes.GET_SHIPMENT_TIMELINE_FAILED,
        error,
    };
};

export const getShipmentTimeline = id => {
    return dispatch => {
        dispatch(getShipmentTimelineStart());
        const requestUrl = apis.dashboard.get_shipment_log(id);
        AXIOS({
            url: requestUrl,
            responseType: 'json',
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
            .then(response => {
                if (response === undefined) {
                    dispatch(sendLogout(true));
                } else if(response.networkError) {
                    dispatch(sendNetworkError(true));
                } 
                else if (response.message && response.message.match(/failed|500/i)) {
                    dispatch(getShipmentTimelineSuccess({error: 'No Data Found...'}));
                }
                else {
                    const data = { ...response.data.data, error: '' };
                    // if (data.events) {
                    //     data.events.reverse();
                    // }
                    // data.events.unshift({
                    //     "title": "Time above high temperature threshold",
                    //     "value": "43.5",
                    //     "representation": "°C",
                    //     "startTime": "2021-08-02 16:28:09",
                    //     "startLocation": {
                    //         "longitude": null,
                    //         "latitude": null,
                    //         "textAddress": null
                    //     },
                    //     "duration": 138900,
                    //     "eventStatus": null,
                    //     "eventType": "METRIC"
                    // });
                    // data.events.unshift({
                    //     "title": "Time below low temperature threshold",
                    //     "value": "-10.5",
                    //     "representation": "°C",
                    //     "startTime": "2021-08-02 16:28:09",
                    //     "startLocation": {
                    //         "longitude": null,
                    //         "latitude": null,
                    //         "textAddress": null
                    //     },
                    //     "duration": (2*60)+(15*60),
                    //     "eventStatus": null,
                    //     "eventType": "METRIC"
                    // });
            //         data.events.unshift(
            //             {
            //                 "title": "Shipment Delayed",
            //                 "datetime": '2021-09-08 22:12:37',
            //                 "location": {
            //                     "longitude": "78.4037405",
            //                     "latitude": "17.4060012",
            //                     "textAddress": "McDonald's, Shaikpet, Hyderabad, Telangana, India"
            //                 },
            //                 "duration": 150,
            //                 "eventStatus": "DUE DATE",
            //                 "eventType": "SHIPMENT"
            //             },
            //             {
            //                 "title": "Shipment Delayed",
            //                 "eventStatus": "DUE DATE",
            //                 "eventValue": "Stop 1",
            //                 "datetime": '2021-09-08 15:23:02',
            //                 "location": {
            //                     "longitude": "78.403147",
            //                     "latitude": "17.406421",
            //                     "textAddress": "Passport Service Kendra, Tolichowki, Hyderabad, Telangana, India"
            //                 },
            //                 "duration": 120,
            //                 "eventType": "STOP"
            //             },
            //         );
                    dispatch(getShipmentTimelineSuccess(data));
                }
            })
            .catch(err => {
                dispatch(getShipmentTimelineFailed(err));
            });
    };
};

export const getShipmentRouteStart = () => {
    return {
        type: actionTypes.GET_SHIPMENT_ROUTE_START,
    };
};
export const getShipmentRouteSuccess = data => {
    return {
        type: actionTypes.GET_SHIPMENT_ROUTE_SUCCESS,
        data: data,
    };
};
export const getShipmentRouteFailed = error => {
    return {
        type: actionTypes.GET_SHIPMENT_ROUTE_FAILED,
        error,
    };
};

export const getShipmentRoute = data => {
    return dispatch => {
        dispatch(getShipmentRouteStart());
        let requestUrl = apis.dashboard.get_shipment_geo_json(data.id);
        if(data.shipmentType === 'tracking') {
            requestUrl = apis.dashboard.get_shipment_tracking_map(data.id);
        }
        
        AXIOS({
            url: requestUrl,
            responseType: 'json',
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
            .then(response => {
                if (response === undefined) {
                    dispatch(sendLogout(true));
                } else if(response.networkError) {
                    dispatch(sendNetworkError(true));
                } else {
                    if (data.callback && typeof data.callback === 'function') {
                        data.callback(response.data);
                    }
                    dispatch(getShipmentRouteSuccess(response.data.data));
                    
                }
            })
            .catch(err => {
                dispatch(getShipmentRouteFailed(err));
            });
    };
};

export const createNewShipmentStart = () => {
    return {
        type: actionTypes.CREATE_NEW_SHIPMENT_START,
    };
};
export const createNewShipmentSuccess = data => {
    return {
        type: actionTypes.CREATE_NEW_SHIPMENT_SUCCESS,
        data: data,
    };
};
export const createNewShipmentFailed = error => {
    return {
        type: actionTypes.CREATE_NEW_SHIPMENT_FAILED,
        error,
    };
};

export const createNewShipment = data => {
    return dispatch => {
        dispatch(createNewShipmentStart());
        const requestUrl = apis.dashboard.create_new_shipment;
        AXIOS({
            url: requestUrl,
            method: 'post',
            responseType: 'json',
            data: data.data,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
            .then(response => {
                if (response === undefined) {
                    dispatch(sendLogout(true));
                } else if(response.networkError) {
                    dispatch(sendNetworkError(true));
                } else {
                    dispatch(createNewShipmentSuccess(response.data));
                    if (data.callback && typeof data.callback === 'function') {
                        data.callback(response.data);
                    }
                }
            })
            .catch(err => {
                dispatch(createNewShipmentFailed(err));
                if (data.callback && typeof data.callback === 'function') {
                    data.callback(err);
                }
            });
    };
};

export const createNewDeviceShipmentStart = () => {
    return {
        type: actionTypes.CREATE_NEW_DEVICE_SHIPMENT_START,
    };
};
export const createNewDeviceShipmentSuccess = data => {
    return {
        type: actionTypes.CREATE_NEW_DEVICE_SHIPMENT_SUCCESS,
        data: data,
    };
};
export const createNewDeviceShipmentFailed = error => {
    return {
        type: actionTypes.CREATE_NEW_DEVICE_SHIPMENT_FAILED,
        error,
    };
};

export const createNewDeviceShipment = data => {
    return dispatch => {
        dispatch(createNewDeviceShipmentStart());
        const requestUrl = apis.dashboard.create_new_device_shipment(data.data.px_shipment_id?`/${data.data.px_shipment_id}`:'');
        const DATA = data.data;	
        if(data.data.px_shipment_id){	
            delete(DATA.tracker_id);	
        }
        AXIOS({
            url: requestUrl,
            method: (data.data.px_shipment_id ? 'put' : 'post'),
            responseType: 'json',
            data: DATA,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
        .then(response => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if(response.networkError) {
                dispatch(sendNetworkError(true));
            } else if(response?.response?.data){
                dispatch(createNewDeviceShipmentSuccess(response.response.data));
                if (data.callback && typeof data.callback === 'function') {
                    data.callback(response.response.data);
                }
            }else{
                dispatch(createNewDeviceShipmentSuccess(response.data));
                if (data.callback && typeof data.callback === 'function') {
                    data.callback(response.data);
                }
            }
        })
        .catch(err => {
            dispatch(createNewDeviceShipmentFailed(err));
            if (data.callback && typeof data.callback === 'function') {
                data.callback(err);
            }
        });
    };
};

export const getShipmentTrackerDataStart = () => {
    return {
        type: actionTypes.GET_SHIPMENT_TRACKER_DATA_START,
    };
};
export const getShipmentTrackerDataSuccess = data => {
    return {
        type: actionTypes.GET_SHIPMENT_TRACKER_DATA_SUCCESS,
        data
    };
};
export const getShipmentTrackerDataFailed = error => {
    return {
        type: actionTypes.GET_SHIPMENT_TRACKER_DATA_FAILED,
        error,
    };
};

export const getShipmentTrackerData = id => {
    return dispatch => {
        dispatch(getShipmentTrackerDataStart());
        const requestUrl = apis.dashboard.get_shipment_tracker_data(id);
        AXIOS({
            url: requestUrl,
            responseType: 'json',
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
            .then(response => {
                if (response === undefined) {
                    dispatch(sendLogout(true));
                } else if(response.networkError) {
                    dispatch(sendNetworkError(true));
                } else {
                    const DATA = [...response.data.data].reverse();
                    dispatch(getShipmentTrackerDataSuccess(DATA));
                }
            })
            .catch(err => {
                dispatch(getShipmentTrackerDataFailed(err));
            });
    };
};

export const getCarriersStart = () => {
    return {
        type: actionTypes.GET_CARRIERS_START,
    };
};
export const getCarriersSuccess = data => {
    return {
        type: actionTypes.GET_CARRIERS_SUCCESS,
        data: data,
    };
};
export const getCarriersFailed = error => {
    return {
        type: actionTypes.GET_CARRIERS_FAILED,
        error,
    };
};

export const getCarriers = id => {
    return dispatch => {
        dispatch(getCarriersStart());
        const requestUrl = apis.dashboard.get_carriers_list;
        AXIOS({
            url: requestUrl,
            responseType: 'json',
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
            .then(response => {
                if (response === undefined) {
                    dispatch(sendLogout(true));
                } else if(response.networkError) {
                    dispatch(sendNetworkError(true));
                } else {
                    dispatch(getCarriersSuccess(response.data?.carriers || []));
                }
            })
            .catch(err => {
                dispatch(getCarriersFailed(err));
            });
    };
};

export const getNotificationsStart = () => {
    return {
        type: actionTypes.GET_NOTIFICATIONS_START,
    };
};
export const getNotificationsSuccess = data => {
    return {
        type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
        data: data,
    };
};
export const getNotificationsFailed = error => {
    return {
        type: actionTypes.GET_NOTIFICATIONS_FAILED,
        error,
    };
};

export const getNotifications = page => {
    return dispatch => {
        dispatch(getNotificationsStart());
        const requestUrl = apis.dashboard.get_notification_list(page);
        AXIOS({
            url: requestUrl,
            responseType: 'json',
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
            .then(response => {
                if (response === undefined) {
                    dispatch(sendLogout(true));
                } else if(response.networkError) {
                    dispatch(sendNetworkError(true));
                } else {
                    const DATA = {...response.data};
                    // DATA.data.unshift({
                    //     "id": 161573,
                    //     "userID": 729,
                    //     "shipmentID": "PX3773",
                    //     "eventType": "METRIC",
                    //     "eventName": "TEMPERATURE",
                    //     "eventStatus": "MINOR",
                    //     "title": "Cold Warning",
                    //     "body": "LOWER-LIMIT-1 < 20°C for 1 min PX3773 ↓ 3.8°C",
                    //     "read": 0,
                    //     "readDate": null,
                    //     "symbol": "Downward",
                    //     "deviation": "3.8°C",
                    //     "createdDate": "2022-03-08 07:40:13",
                    //     "shipmentCreatedDate": "2022-03-08 07:36:05"
                    // },
                    // {
                    //     "id": 161572,
                    //     "userID": 729,
                    //     "shipmentID": "PX3773",
                    //     "eventType": "METRIC",
                    //     "eventName": "TEMPERATURE",
                    //     "eventStatus": "MAJOR",
                    //     "title": "Heat Deviation",
                    //     "body": "UPPER-LIMIT-3 > 41°C for 0.5 min PX3773 ↑ 0.2°C",
                    //     "read": 0,
                    //     "readDate": null,
                    //     "symbol": "Upward",
                    //     "deviation": "0.2°C",
                    //     "createdDate": "2022-03-08 07:39:09",
                    //     "shipmentCreatedDate": "2022-03-08 07:36:05"
                    // },
                    // {
                    //     "id": 161571,
                    //     "userID": 729,
                    //     "shipmentID": "PX3773",
                    //     "eventType": "METRIC",
                    //     "eventName": "TEMPERATURE",
                    //     "eventStatus": "MAJOR",
                    //     "title": "Heat Deviation",
                    //     "body": "UPPER-LIMIT-2 > 40°C for 0.5 min PX3773 ↑ 1.2°C",
                    //     "read": 0,
                    //     "readDate": null,
                    //     "symbol": "Upward",
                    //     "deviation": "1.2°C",
                    //     "createdDate": "2022-03-08 07:38:48",
                    //     "shipmentCreatedDate": "2022-03-08 07:36:05"
                    // },
                    // {
                    //     "id": 161570,
                    //     "userID": 729,
                    //     "shipmentID": "PX3773",
                    //     "eventType": "METRIC",
                    //     "eventName": "TEMPERATURE",
                    //     "eventStatus": "MINOR",
                    //     "title": "Heat Warning",
                    //     "body": "UPPER-LIMIT-1 > 35°C for 1.5 min PX3773 ↑ 5.0°C",
                    //     "read": 0,
                    //     "readDate": null,
                    //     "symbol": "Upward",
                    //     "deviation": "5.0°C",
                    //     "createdDate": "2022-03-08 07:38:26",
                    //     "shipmentCreatedDate": "2022-03-08 07:36:05"
                    // });
                    // DATA.data.unshift(
                    //     {
                    //         body: "Your shipment PX2287 to Raj has experienced Temperature above threshold",
                    //         createdDate: "2021-07-27 20:55:27",
                    //         deviation: "1%RH",
                    //         threshold: '-10°C',
                    //         eventName: "TEMPERATURE",
                    //         eventStatus: "MAJOR",
                    //         eventType: "METRIC",
                    //         id: 157423,
                    //         read: 0,
                    //         readDate: null,
                    //         shipmentCreatedDate: "2021-07-27 13:55:09",
                    //         shipmentID: "PX2287",
                    //         symbol: "Downward ",
                    //         title: "Time below low temperature threshold",
                    //         userID: 745,
                    //         duration: 438900
                    //     }
                    // );
                    // DATA.data.unshift(
                    //     {
                    //         body: "Your shipment PX2287 to Raj has experienced Temperature above threshold",
                    //         createdDate: "2021-07-27 20:55:27",
                    //         deviation: "1%RH",
                    //         threshold: '40°C',
                    //         eventName: "TEMPERATURE",
                    //         eventStatus: "MAJOR",
                    //         eventType: "METRIC",
                    //         id: 157423,
                    //         read: 0,
                    //         readDate: null,
                    //         shipmentCreatedDate: "2021-07-27 13:55:09",
                    //         shipmentID: "PX2287",
                    //         symbol: "Upward ",
                    //         title: "Time above high temperature threshold",
                    //         userID: 745,
                    //         duration: 138900
                    //     },
                    //     {
                //             "id": 156627,
                //             "userID": 729,
                //             "shipmentID": "PX2984",
                //             "eventType": "SHIPMENT",
                //             "eventName": "DUE DATE",
                //             "eventStatus": "DUE DATE",
                //             "title": "Shipment Delayed",
                //             "body": "Time elapsed from the anticipated arrival at destination 09-08-2021 03:09",
                //             "read": "0",
                //             "readDate":"",
                //             "symbol": "",
                //             "deviation": "",
                //             "createdDate": "2021-09-08 15:23:49",
                //             "shipmentCreatedDate": "2021-09-08 15:20:39"
                    //             },
                    //     {
                    //                 "id": 156625,
                    //                 "userID": 729,
                    //                 "shipmentID": "PX2984",
                    //                 "eventType": "STOP",
                    //                 "eventName": "DUE DATE",
                    //                 "eventStatus": "DUE DATE",
                    //                 "title": "Shipment Delayed",
                    //                 "body": "Time elapsed from the anticipated arrival at Stop 1 09-08-2021 03:09",
                    //                 "read": 0,
                    //                 "readDate": null,
                    //                 "symbol": "",
                    //                 "deviation": "",
                    //                 "createdDate": "2021-09-08 15:23:13",
                    //                 "shipmentCreatedDate": "2021-09-08 15:20:39"
                    //             });
                    
                    dispatch(getNotificationsSuccess(DATA));
                }
            })
            .catch(err => {
                dispatch(getNotificationsFailed(err));
            });
    };
};

export const getUnreadNotificationsCountStart = () => {
    return {
        type: actionTypes.GET_UNREAD_NOTIFICATIONS_START,
    };
};
export const getUnreadNotificationsCountSuccess = data => {
    return {
        type: actionTypes.GET_UNREAD_NOTIFICATIONS_SUCCESS,
        data: data,
    };
};
export const getUnreadNotificationsCountFailed = error => {
    return {
        type: actionTypes.GET_UNREAD_NOTIFICATIONS_FAILED,
        error,
    };
};

export const getUnreadNotificationsCount = id => {
    return dispatch => {
        dispatch(getUnreadNotificationsCountStart());
        const requestUrl = apis.dashboard.get_unread_notification_count;
        AXIOS({
            url: requestUrl,
            responseType: 'json',
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
            .then(response => {
                if (response === undefined) {
                    if(window.location.pathname.indexOf('/assets') >= 0) {
                        dispatch(getNewToken({
                            onComplete: () => {
                                dispatch(getUnreadNotificationsCount());
                            }
                        }));
                    } else {
                        dispatch(sendLogout(true));
                    }
                } else if(response.networkError) {
                    dispatch(sendNetworkError(true));
                } else {
                    dispatch(getUnreadNotificationsCountSuccess(response.data.data));
                }
            })
            .catch(err => {
                dispatch(getUnreadNotificationsCountFailed(err));
            });
    };
};

export const setNotificationReadStart = () => {
    return {
        type: actionTypes.SET_NOTIFICATION_READ_START,
    };
};
export const setNotificationReadSuccess = data => {
    return {
        type: actionTypes.SET_NOTIFICATION_READ_SUCCESS,
        data: data,
    };
};
export const setNotificationReadFailed = error => {
    return {
        type: actionTypes.SET_NOTIFICATION_READ_FAILED,
        error,
    };
};

export const setNotificationRead = data => {
    return dispatch => {
        dispatch(setNotificationReadStart());
        const requestUrl = apis.dashboard.set_notification_read;
        AXIOS({
            method: 'post',
            url: requestUrl,
            responseType: 'json',
            data: data,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
            .then(response => {
                if (response === undefined) {
                    dispatch(sendLogout(true));
                } else if(response.networkError) {
                    dispatch(sendNetworkError(true));
                } else {
                    dispatch(setNotificationReadSuccess(response.data.data));
                }
            })
            .catch(err => {
                dispatch(setNotificationReadFailed(err));
            });
    };
};

export const setShipmentUpdateStart = () => {
    return {
        type: actionTypes.SET_SHIPMENT_UPDATE_START,
    };
};
export const setShipmentUpdateSuccess = data => {
    return {
        type: actionTypes.SET_SHIPMENT_UPDATE_SUCCESS,
        data: data,
    };
};
export const setShipmentUpdateFailed = error => {
    return {
        type: actionTypes.SET_SHIPMENT_UPDATE_FAILED,
        error,
    };
};

export const setShipmentUpdate = data => {
    return async dispatch => {
        dispatch(setShipmentUpdateStart());
        const requestUrl = apis.dashboard.update_shipment_status;
        await AXIOS({
            method: 'post',
            url: requestUrl,
            responseType: 'json',
            data: data.data,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
            .then(response => {
                if (response === undefined) {
                    dispatch(sendLogout(true));
                } else if(response.networkError) {
                    dispatch(sendNetworkError(true));
                } 
                else if (response.response){
                    if(response.response.data && response.response.data.error){
                        dispatch(setShipmentUpdateFailed(response.response.data.error))
                    
                    }
                    else{
                    dispatch(setShipmentUpdateFailed(`Could not process request. Please contact ${apis.help.api_help_email}`))
                    }
                }
                else {
                    dispatch(setShipmentUpdateSuccess(response.data.data));
                    setTimeout(() => {
                        dispatch(setShipmentUpdateSuccess(''));
                    }, 2000);
                    if (data.callback && typeof data.callback === 'function') {
                        data.callback({});
                    }
                }
            })
            // .catch(err => {
            //     dispatch(setShipmentUpdateFailed(err));
            // });
    };
};

export const setShipmentLiveData = (details, timeline, trackerData, list, resetChart) => {
    return {
      type: actionTypes.SET_LIVE_DATA,
      details,
      timeline,
      trackerData,
      list,
      resetChart
    }
};
export const updateShipmentDetailsStart = () => {
    return {
        type: actionTypes.UPDATE_SHIPMENT_DETAILS_START,
    };
};
export const updateShipmentDetailsSuccess = data => {
    return {
        type: actionTypes.UPDATE_SHIPMENT_DETAILS_SUCCESS,
        data: data,
    };
};
export const updateShipmentDetailsFailed = error => {
    return {
        type: actionTypes.UPDATE_SHIPMENT_DETAILS_FAILED,
        error,
    };
};

export const updateShipmentDetails = data => {
    return async dispatch => {
        dispatch(updateShipmentDetailsStart());
        //const requestUrl = `shipments/${data.data.px_shipment_id}`;
        const requestUrl = apis.dashboard.update_shipment_details;
        await AXIOS({
            //method: 'put',
            method: 'post',
            url: requestUrl,
            responseType: 'json',
            data: data.data,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
            .then(response => {
                if (response === undefined) {
                    dispatch(sendLogout(true));
                } else if(response.networkError) {
                    dispatch(sendNetworkError(true));
                } 
                else if (response.response){
                    if(response.response.data && response.response.data.errors){
                        dispatch(updateShipmentDetailsFailed(response.response.data.errors));
                        if (data.callback && typeof data.callback === 'function') {
                            data.callback(response.response.data);
                        }
                    } else if(response.response.data && response.response.data.error){
                        dispatch(updateShipmentDetailsFailed(response.response.data.error));
                        if (data.callback && typeof data.callback === 'function') {
                            data.callback(response.response.data);
                        }
                    } else{
                        dispatch(updateShipmentDetailsFailed(`Could not process request. Please contact ${apis.help.api_help_email}`))
                    }
                }
                else {
                    dispatch(updateShipmentDetailsSuccess(response.data.data));
                    if (data.callback && typeof data.callback === 'function') {
                        data.callback(response.data);
                    }
                }
            });
    };
};

export const saveGeoFenceStart = () => {
    return {
        type: actionTypes.SAVE_GEO_FENCE_START,
    };
};
export const saveGeoFenceSuccess = (data, geofence) => {
    return {
        type: actionTypes.SAVE_GEO_FENCE_SUCCESS,
        data: data,
        geofence: geofence
    };
};
export const saveGeoFenceFailed = error => {
    return {
        type: actionTypes.SAVE_GEO_FENCE_FAILED,
        error,
    };
};

export const saveGeoFence = data => {
    return async dispatch => {
        dispatch(saveGeoFenceStart());
        if(data.geoFenceSaved) {
            dispatch(saveGeoFenceSuccess({geoFenceSaved: false, geofence: data.geofence}));
            return false;
        }
        const requestUrl = apis.dashboard.save_shipment_geofence;
        await AXIOS({
            method: 'post',
            url: requestUrl,
            responseType: 'json',
            data: data,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
        .then(response => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if(response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response){
                if(response.response.data && response.response.data.errors){
                    dispatch(saveGeoFenceFailed(response.response.data.errors));
                } else if(response.response.data && response.response.data.error){
                    dispatch(saveGeoFenceFailed(response.response.data.error));
                } else{
                    dispatch(saveGeoFenceFailed(`Could not process request. Please contact ${apis.help.api_help_email}`))
                }
            } else if (response.data?.error) {
                dispatch(saveGeoFenceFailed(response.data.error));
                if (data.callback && typeof data.callback === 'function') {
                    data.callback(response.data);
                }
            } else {
                dispatch(saveGeoFenceSuccess(response?.data?.data||{error: `Could not process request. Please contact ${apis.help.api_help_email}`}, data));
                if (data.callback && typeof data.callback === 'function') {
                    data.callback(response.data.data);
                }
            }
        });
    };
};

export const deleteGeoFenceStart = () => {
    return {
        type: actionTypes.DELETE_GEO_FENCE_START,
    };
};
export const deleteGeoFenceSuccess = (data, id) => {
    return {
        type: actionTypes.DELETE_GEO_FENCE_SUCCESS,
        data: data,
        id: id
    };
};
export const deleteGeoFenceFailed = error => {
    return {
        type: actionTypes.DELETE_GEO_FENCE_FAILED,
        error,
    };
};

export const deleteGeoFence = data => {
    return async dispatch => {
        dispatch(deleteGeoFenceStart());

        if(data.geoFenceDeleted) {
            dispatch(deleteGeoFenceSuccess({geoFenceDeleted: false}));
            return false;
        }

        const requestUrl = apis.dashboard.delete_shipment_geofence(data.id);
        await AXIOS({
            method: 'delete',
            url: requestUrl,
            responseType: 'json',
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
            .then(response => {
                if (response === undefined) {
                    dispatch(sendLogout(true));
                } else if(response.networkError) {
                    dispatch(sendNetworkError(true));
                } 
                else if (response.response){
                    if(response.response.data && response.response.data.errors){
                        dispatch(deleteGeoFenceFailed(response.response.data.errors));                    
                    } else if(response.response.data && response.response.data.error){
                        dispatch(deleteGeoFenceFailed(response.response.data.error));                    
                    } else{
                        dispatch(deleteGeoFenceFailed(`Could not process request. Please contact ${apis.help.api_help_email}`))
                    }
                }
                else {
                    dispatch(deleteGeoFenceSuccess(response.data, data.id));
                    if (data.callback && typeof data.callback === 'function') {
                        data.callback({});
                    }
                }
            })
            .catch(err => {
                dispatch(deleteGeoFenceFailed(err));
            });
    };
};

export const getShipmentConfigurationStart = (page) => {
    return {
        type: actionTypes.GET_SHIPMENT_CONFIGURATION_START,
        page: page
    };
};
export const getShipmentConfigurationSuccess = (data) => {
    return {
        type: actionTypes.GET_SHIPMENT_CONFIGURATION_SUCCESS,
        data
    };
};
export const getShipmentConfigurationFailed = error => {
    return {
        type: actionTypes.GET_SHIPMENT_CONFIGURATION_FAILED,
        error,
    };
};

export const getShipmentConfiguration = data => {
    return dispatch => {
        dispatch(getShipmentConfigurationStart());
        const requestUrl = apis.dashboard.get_shipment_configuration(data.shipment_id);
        AXIOS({
            url: requestUrl,
            responseType: 'json',
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
            .then(response => {
                if (response === undefined) {
                    dispatch(sendLogout(true));
                } else if(response.networkError) {
                    dispatch(sendNetworkError(true));
                } else {
                    //console.log('DATA: --- ', response.data && response.data.data && !response.data.data.length);
                    //response.data && response.data.data && !response.data.data.length ? response.data.data : 
                    const DATA = {...response.data.data};
                    //console.log('DATA: ', DATA);
                    dispatch(
                        getShipmentConfigurationSuccess(DATA)
                    );
                    
                    if (data.onComplete && typeof data.onComplete === 'function') {
                        data.onComplete(DATA);
                    }
                }
            })
            .catch(err => {
                dispatch(getShipmentConfigurationFailed(err));
            });
    };
};

export const updateShipmentConfigurationStart = () => {
    return {
        type: actionTypes.UPDATE_SHIPMENT_CONFIGURATION_START,
    };
};
export const updateShipmentConfigurationSuccess = data => {
    return {
        type: actionTypes.UPDATE_SHIPMENT_CONFIGURATION_SUCCESS,
        data
    };
};
export const updateShipmentConfigurationFailed = error => {
    return {
        type: actionTypes.UPDATE_SHIPMENT_CONFIGURATION_FAILED,
        error,
    };
};

export const updateShipmentConfiguration = data => {
    return async dispatch => {
        dispatch(updateShipmentConfigurationStart());
        const requestUrl = apis.dashboard.update_shipment_configuration(data.id);
        await AXIOS({
            method: 'put',
            url: requestUrl,
            responseType: 'json',
            data: data.configurationProfileDetails,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        }).then(response => {
            //console.log('---------------> ', response, response.response, response.response.data?.length);
            //dispatch(updateShipmentConfigurationSuccess({data: {message: 'Success'}}));
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if(response.networkError) {
                dispatch(sendNetworkError(true));
            } 
            else if (response.response){
                if(response.response.data?.data){
                    dispatch(updateShipmentConfigurationFailed(response.response.data));
                    if (data.onComplete && typeof data.onComplete === 'function') {
                        data.onComplete(response.response.data);
                    }
                } else if(response.response.data && response.response.data.error){
                    dispatch(updateShipmentConfigurationFailed(response.response.data.error));
                    if (data.onComplete && typeof data.onComplete === 'function') {
                        data.onComplete(response.response.data);
                    }
                } else{
                    dispatch(updateShipmentConfigurationFailed(`Could not process request. Please contact ${apis.help.api_help_email}`))
                }
            }
            else {
                dispatch(updateShipmentConfigurationSuccess(response.data));
                if (data.onComplete && typeof data.onComplete === 'function') {
                    data.onComplete(response.data);
                }
            }
        }).catch(err => {
            console.log('------------> ', err);
            dispatch(updateShipmentConfigurationSuccess({data: {message: 'Success'}}));
        });
    };
};

export const getShipmentReportsStart = () => {
    return {
        type: actionTypes.GET_SHIPMENT_REPORTS_START,
    };
};
export const getShipmentReportsSuccess = (data) => {
    return {
        type: actionTypes.GET_SHIPMENT_REPORTS_SUCCESS,
        data
    };
};
export const getShipmentReportsFailed = error => {
    return {
        type: actionTypes.GET_SHIPMENT_REPORTS_FAILED,
        error,
    };
};

export const getShipmentReports = data => {
    return dispatch => {
        dispatch(getShipmentReportsStart());
        const requestUrl = apis.dashboard.get_shipment_reports(data.shipmentID);
        AXIOS({
            url: requestUrl,
            responseType: 'json',
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
        .then(response => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if(response.networkError) {
                dispatch(sendNetworkError(true));
            } else {
                //console.log('DATA: --- ', response.data && response.data.data && !response.data.data.length);
                //response.data && response.data.data && !response.data.data.length ? response.data.data : 
                const DATA = [...response.data.data];
                //console.log('DATA: ', DATA);
                dispatch(
                    getShipmentReportsSuccess(DATA)
                );
                
                if (data.onComplete && typeof data.onComplete === 'function') {
                    data.onComplete(DATA);
                }
            }
        })
        .catch(err => {
            dispatch(getShipmentReportsFailed(err));
        });
    };
};

export const createShipmentReportStart = () => {
    return {
        type: actionTypes.CREATE_SHIPMENT_REPORT_START,
    };
};
export const createShipmentReportSuccess = (data) => {
    return {
        type: actionTypes.CREATE_SHIPMENT_REPORT_SUCCESS,
        data,
    };
};
export const createShipmentReportFailed = errors => {
    return {
        type: actionTypes.CREATE_SHIPMENT_REPORT_FAILED,
        errors,
    };
};

export const createShipmentReport = data => {
    return async dispatch => {
        dispatch(createShipmentReportStart());
        const requestUrl = apis.dashboard.create_shipment_report;
        await AXIOS({
            method: 'post',
            url: requestUrl,
            data: data.data,
            responseType: 'json',
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
        .then(response => {
            //console.log(response, response.response, response.message);
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if(response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response && response.response.data && response.response.data.errors){
                dispatch(createShipmentReportFailed(response.response.data.errors));
            } else {
                //console.log('DATA: --- ', response.data && response.data.data && !response.data.data.length);
                const DATA = {...response.data};
                //console.log('DATA: ', DATA);
                if(DATA.success) {
                    dispatch(
                        createShipmentReportSuccess(DATA)
                    );
                } else {
                    dispatch(
                        createShipmentReportFailed(DATA)
                    );
                }
                
                if (data.onComplete && typeof data.onComplete === 'function') {
                    data.onComplete(DATA);
                }
            }
        })
        .catch(err => {
            console.log('catch error: ', err, err.message);
            dispatch(createShipmentReportFailed(err));
        });
    };
};


export const deleteShipmentReportStart = () => {
    return {
        type: actionTypes.DELETE_SHIPMENT_REPORT_START,
    };
};
export const deleteShipmentReportSuccess = (message, id) => {
    return {
        type: actionTypes.DELETE_SHIPMENT_REPORT_SUCCESS,
        message,
        id
    };
};
export const deleteShipmentReportFailed = error => {
    return {
        type: actionTypes.DELETE_SHIPMENT_REPORT_FAILED,
        error,
    };
};

export const deleteShipmentReport = data => {
    return async dispatch => {
        dispatch(deleteShipmentReportStart());

        const requestUrl = apis.dashboard.delete_shipment_report(data.reportId);
        await AXIOS({
            method: 'delete',
            url: requestUrl,
            responseType: 'json',
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
            .then(response => {
                console.log(response.data);
                if (response === undefined) {
                    dispatch(sendLogout(true));
                } else if(response.networkError) {
                    dispatch(sendNetworkError(true));
                } 
                else if (response.response){
                    if(response.response.data && response.response.data.errors){
                        dispatch(deleteShipmentReportFailed(response.response.data.errors));                    
                    } else if(response.response.data && response.response.data.error){
                        dispatch(deleteShipmentReportFailed(response.response.data.error));                    
                    } else{
                        dispatch(deleteShipmentReportFailed(`Could not process request. Please contact ${apis.help.api_help_email}`))
                    }
                }
                else {
                    dispatch(deleteShipmentReportSuccess(response.data.message, data.reportId));
                    if (data.callback && typeof data.callback === 'function') {
                        data.callback(response.data.message, data.reportId);
                    }
                }
            })
            .catch(err => {
                dispatch(deleteShipmentReportFailed(err));
            });
    };
};

export const assignDeviceToShipmentStart = () => {
    return {
        type: actionTypes.ASSIGN_DEVICE_TO_SHIPMENT_START,
    };
};
export const assignDeviceToShipmentSuccess = data => {
    return {
        type: actionTypes.ASSIGN_DEVICE_TO_SHIPMENT_SUCCESS,
        data,
    };
};
export const assignDeviceToShipmentFailed = error => {
    return {
        type: actionTypes.ASSIGN_DEVICE_TO_SHIPMENT_FAILED,
        error,
    };
};

export const assignDeviceToShipment = data => {
    return dispatch => {
        dispatch(assignDeviceToShipmentStart());
        const requestUrl = apis.dashboard.assign_device_to_shipment(data.id);
        AXIOS({
            method: 'post',
            url: requestUrl,
            responseType: 'json',
            data: data,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        })
            .then(response => {
                if (response === undefined) {
                    dispatch(sendLogout(true));
                } else if(response.networkError) {
                    dispatch(sendNetworkError(true));
                }else if(response?.data?.data) {
                    dispatch(assignDeviceToShipmentSuccess(response.data.data));
                    if (data.callbackOnComplete && typeof data.callbackOnComplete === 'function') {
                        data.callbackOnComplete(response.data.data);
                    }
                } else {
                    dispatch(assignDeviceToShipmentFailed(response.response ));
                    if (data.callbackOnComplete && typeof data.callbackOnComplete === 'function') {
                        data.callbackOnComplete(response.response,response.data);
                    }
                }
            })
            .catch(err => {
                dispatch(assignDeviceToShipmentFailed(err));
            });
    };
};

export const getShipmentLocationStart = () => {
    return {
        type: actionTypes.GET_SHIPMENT_LOCATION_START
    };
};

export const getShipmentLocationSuccess = (data) => {
    return {
        type: actionTypes.GET_SHIPMENT_LOCATION_SUCCESS,
        data
    };
};

export const getShipmentLocationFailed = (error) => {
    return {
        type: actionTypes.GET_SHIPMENT_LOCATION_FAILED,
        error
    };
};

export const getShipmentLocation = data => async dispatch => {
    dispatch(getShipmentLocationStart());
    let requestUrl = apis.dashboard.shipments_location();
    await AXIOS({
        method: 'post',
        data: {location: data.location, geolocation: data.geolocation},
        url: requestUrl,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response.data && response.response.data.error) {
                dispatch(getShipmentLocationFailed(response.response.data));
            } else if (response.response.data && response.response.data.errors) {
                dispatch(
                    getShipmentLocationFailed(
                        `Could not process request. Please contact ${apis.help.api_help_email}`,
                        response.response.data
                    )
                );
            } else {
                dispatch(getShipmentLocationFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            dispatch(getShipmentLocationSuccess({ ...response.data }));
            if (data.onComplete && typeof (data.onComplete) === 'function') {
                data.onComplete(response.data);
            }
        }
    });
};
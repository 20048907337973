import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from 'redux-store/actions';
import { getUserList } from 'redux-store/actions/users';
import CODES from 'assets/json/CountryCodes.json';
import MODES from 'assets/json/Modes.json';
import INMOTION from 'assets/json/Inmotion.json';
import NOMOTION from 'assets/json/Nomotion.json';
import REPORTING from 'assets/json/Reporting.json';
import SAMPLING from 'assets/json/Sampling.json';
import { getDate, enableETA, getDateFromDuration, getSplitTimeConfig, formatPhone, getShortCode, getLastTwoValues } from '../../utility/utility';
//import moment from 'moment';
import { debounce } from 'lodash';
import DateTimePickerHOC from 'components/DateTimePickerHOC/DateTimePickerHOC';
import ShipmentProfileConfiguration from 'components/Shipments/shipment_profile_configuration';
import ShipmentMetricConfiguration from 'components/Shipments/shipment_metric_configuration';
import SweetAlert from 'components/SweetAlert/SweetAlert';
import PerfectScrollbar from 'components/ScrollBarHOC/scrollBarHOC';
import SelectBox from 'components/SelectBox/SelectBox';
import { googleAnalyticActions } from 'init-ga';
import TrashIcon from 'assets/images/Trash';
import EditIcon from 'assets/images/EditIcon';
import { getShipmentLocation } from "redux-store/actions";

const CARRIERS = {
    'USPS': [13, 22, 26],
    'FedEx': [12, 14, 15],
    'UPS': [18]
};

//const ROLES = localStorage.getItem('roles');
const companyId = localStorage.getItem('companyId');
const LOCATION_MAP = {origin: 'Origin', stop: 'Stop', destination: 'Destination'};

class CreateShipment extends Component {
    constructor(props) {
        super(props);
        /* eslint-disable */
        this.state = {
            newShipTrackingNumber: '',
            newShipDescription: '',
            newShipCarrier: '',
            newShipTrackingNumberError: '',
            shipmentCreatedPopup: '',
            shipmentCreatedMessage: 'Shipment created successfully.',
            shipmentCreatedStatus: 'success',
            childNodes: false,
            
            

            isGoogleSearch: false,
            config: {
                maxCarrierTypes: 100,
                maxUsers: 15,
                maxStops: 10,
            },
            shipmentData: {
                company_id: companyId,
                shipper_id: localStorage.getItem('email'),
                client_shipment_id: '', //from shipment profile
                tracker_id: '', //from shipment profile
                shipment_profile_id: '', //default shipment profile id
                shipment_description: '', //from shipment profile
                receiver_email: '', //from shipment profile
                receiver_country_code: '1', //from shipment profile
                receiver_contact_number: '', //from shipment profile
                round_trip: false, //from shipment profile
                auto_complete: true, //from shipment profile
                legs: [{leg_type: 'origin'},{leg_type: 'destination'}], //from shipment profile
            },
            shipmentAssetType: 'device',
            switchToRoundTripConfirm: false,
            showConfigurationProfilePop: false,
            showShipmentProfilePop: false,
            showShipmentMetricConfigurationPop: false,
            showSaveSuccess: false,
            showSuccessDuration: 2500,
            saveSuccessTitle: 'Saved successfully!',
            saveSuccessStatus: 'success',
            selectedConfigurationProfile: this.props.selectedConfigurationProfile || [],
        };
    };

    async componentDidMount  () {
        //console.log('----> ', this.props);
        await this.props.onGetCarrierTypes({});
        await this.props.onGetUsers({});
        if(this.props.shipmentEdit) {
            this.setShipmentProfileDetails(this.props.shipmentData);
        } else {
            this.props.getShipmentProfileList({
                companyId: companyId,
                onComplete: (data) => {
                    if(data.length) {
                        const {shipmentData} = this.state;
                        let defaultShipmentProfile = data[0].id;
                        if(data[0].default_profile) {} 
                        else {
                            const default_profile = data.filter(sp=>sp.default_profile);
                            defaultShipmentProfile = default_profile.length ? default_profile[0].id : defaultShipmentProfile;
                        }
                        shipmentData.shipment_profile_id = defaultShipmentProfile;
                        this.props.getShipmentProfile({
                            id: defaultShipmentProfile,
                            onComplete: (data) => {
                                this.setShipmentProfileDetails(data, true);
                            }
                        })
                        this.setState({shipmentData});
                    }
                }
            });
        }
    }
    componentWillUnmount() {
        
    }

    componentDidUpdate(prevProps) {
        // empty
    }

    addShipment = () => {
        if (MobileConfig.mobile) {
            this.responsive();
            this.shipmentsSelect();
        }
        this.props.onGetCarrierTypes({});
        this.props.onGetConfigurationProfileList({
            companyId: localStorage.getItem("companyId")
        });
        
        this.setState({
            newShipment: true,
            shipmentAssetType: 'device',    
            newShipTrackingNumber: '',
            newShipDescription: '',
            newShipCarrier: '',
            newShipTrackingNumberError: '',
            shipmentData: {
                company_id: companyId,
                shipper_id: localStorage.getItem('email'),
                client_shipment_id: '', //from shipment profile
                tracker_id: '', //from shipment profile
                shipment_profile_id: '', //default shipment profile id
                shipment_description: '', //from shipment profile
                receiver_email: '', //from shipment profile
                receiver_country_code: '1', //from shipment profile
                receiver_contact_number: '', //from shipment profile
                round_trip: false, //from shipment profile
                auto_complete: true, //from shipment profile
                legs: [{leg_type: 'origin'},{leg_type: 'destination'}], //from shipment profile
            },
        });
    };

    createShipment = (e) => {
        e.stopPropagation();
        const elem = e.target.className;
        if (elem === 'c-btn') {
            //console.log(this.state.newShipTrackingNumber, '|',this.state.newShipDescription,'|', this.state.newShipCarrier,'|', this.getCarriers());
            const d = {
                trackingId: this.state.newShipTrackingNumber,
                description: this.state.newShipDescription || '---',
                carrierName: this.state.newShipCarrier || this.getCarriers().length ? this.getCarriers()[0] : '',
            };
            if (Math.abs(d.trackingId) === 0 || !d.trackingId.match(/^(?=.*\d)[a-z0-9]+$/i)) {
                this.setState({ newShipTrackingNumberError: 'Please enter valid tracking number.' });
            } else {
                this.props.onCreateNewShipment({
                    data: d,
                    callback: (data) => {

                        //console.log('-====----> ', data);
                        // console.log('====> ', this.props.dashboard);
                        if (data && data.data) {
                            this.setState({ 
                                shipmentCreatedPopup: data.data ? data.data.shipmentId : false, 
                                shipmentCreatedMessage: 'Shipment created successfully.', 
                                shipmentCreatedStatus: 'success', 
                                newShipment: false 
                            });
                            setTimeout(() => {
                                /*Go to Shipment*/
                                const shipmentId = this.props.createShipment.data.shipmentId;
                                console.log('Go to Shipment..---------...', shipmentId);
                                if (this.props.goToShipment) {
                                    window.location.hash = `nship=${shipmentId}`;
                                    this.props.goToShipment(shipmentId);
                                } else {
                                    window.location.href = `/shipments/active#nship=${shipmentId}`;
                                }
                            }, 1000);
                        } else {
                            if (data.error && data.error[0].description) {
                                this.setState({ newShipTrackingNumberError: data.error[0].description });
                            } else if (data.error) {
                                this.setState({ newShipTrackingNumberError: data.error });
                            } else {
                                this.setState({ newShipTrackingNumberError: 'Unable to create shipment.' });
                            }
                        }
                    },
                });
            }
        } else {
            console.log('Create Shipment...btn disabled.....');
            return false;
        }
    };

    createNewDeviceShipment = async (e) => {
        e.stopPropagation();
        const {shipmentData} = this.state;
        const legs = shipmentData.legs.filter(leg => {
            if(leg.delete === 1 && !leg.id) {
                return false;
            }
            if(leg.due_date === null || leg.due_date === '') {
                delete(leg.due_date);
            }
            if(leg.mode !== 'Air') {
                delete(leg.awb);
            }
            if(leg.mode !== 'Water') {
                delete(leg.mmsi);
                delete(leg.container_id);
            }
            return true;
        });
        shipmentData.legs = legs;

        //console.log('shipmentData: ', shipmentData);
        if(shipmentData.receiver_contact_number === '') {
            shipmentData.receiver_country_code = '';
        }
        if(shipmentData.shipment_profile_id === '') {
            shipmentData.round_trip = 0;
        }
        if(shipmentData.tracker_id){}
        else {
            delete(shipmentData.tracker_id);
        }
        //console.log('-----shipmentData----> ', shipmentData);

        await this.props.onCreateNewDeviceShipment({
            data: shipmentData,
            callback: (response) => {
                //console.log('----response----> ', response);
                if(response?.success) {
                    if(this.props.shipmentEdit) {
                        // googleAnalyticActions.addEvent('Shipment', `${shipmentData.px_shipment_id} Edited`);

                        this.props.onGetShipmentDetails({
                            id: shipmentData.px_shipment_id
                        });

                        setTimeout(() => {
                            this.props.toggleAddShipment({stopPropagation:()=>{}, data: response.data});
                        }, 10);
                    } else {
                        // googleAnalyticActions.addEvent('Shipment', 'Shipment created');
                        setTimeout(() => {
                            /*Go to Shipment*/
                            const shipmentId = response.data.px_shipment_id;
                            const status = response.data.status;
                            console.log('Go to Shipment..---------...', shipmentId);
                            if (this.props.goToShipment) {
                                this.props.toggleAddShipment({stopPropagation:()=>{}});
                                // window.location.hash = `nship=${shipmentId}`;
                                this.props.goToShipment(shipmentId, status);
                            } else {
                                window.location.href = `/shipments/${status}#nship=${shipmentId}&s=${status}`;
                            }
                        }, 1000);
                    }
                    this.setState({ 
                        shipmentCreatedPopup: true, 
                        shipmentCreatedMessage: response.message, 
                        shipmentCreatedStatus: 'success', 
                        newShipment: false 
                    });
                } else if(response?.error) {
                    const {shipmentData} = this.state;
                    if(response.error.match(/device/ig)) {
                        shipmentData.errors.tracker_id = response.error;
                    }
                    this.setState({ 
                        shipmentData,
                        shipmentCreatedPopup: true, 
                        shipmentCreatedMessage: response.error ? response.error : 'Given data is invalid.', 
                        shipmentCreatedStatus: 'danger'
                    });
                } else if (response.message && !response.data) {
                    this.setState({ 
                        shipmentCreatedPopup: true, 
                        shipmentCreatedMessage: response.message || 'Unknown Error!', 
                        shipmentCreatedStatus: 'danger'
                    });
                } else if(response.data) {
                    const {shipmentData} = this.state;
                    shipmentData.errors = {};
                    Object.keys(response.data).forEach(errorKey => {
                        shipmentData.errors[errorKey] = response.data[errorKey][0];
                        const ERROR_KEY_OBJ = errorKey.split('.');
                        if(errorKey.indexOf('legs.')>=0) {
                            if(errorKey.indexOf('formatted_address') >= 0 || errorKey.indexOf('stop_name') >= 0 || errorKey.indexOf('leg_type') >= 0 || errorKey.indexOf('longitude') >= 0 || errorKey.indexOf('latitude') >= 0) {
                                shipmentData.legs[ERROR_KEY_OBJ[1]].edit = true;
                            }
                            if(errorKey.indexOf('notify_additional_users') >= 0 || errorKey.indexOf('mode') >= 0 || errorKey.indexOf('mmsi') >= 0 || errorKey.indexOf('awb') >= 0 || errorKey.indexOf('carrier') >= 0 || errorKey.indexOf('container_id') >= 0) {
                                shipmentData.legs[ERROR_KEY_OBJ[1]].edit_config = true;
                            }
                            if(errorKey === 'legs' && ERROR_KEY_OBJ.length === 1) {
                                shipmentData.errors[`legs.${i}`] = response.data[errorKey][0];
                            }
                        }
                    });

                    this.setState({ 
                        shipmentData,
                        shipmentCreatedPopup: true, 
                        shipmentCreatedMessage: response.message || 'Given data is invalid.', 
                        shipmentCreatedStatus: 'danger'
                    }, () => {
                        const errorFields = document.querySelectorAll('.new-ship .error_msg');
                        if(errorFields && errorFields.length) {
                            errorFields[0].closest('.fld-sec').scrollIntoView();
                        }
                    }); 
                }
                
            }
        });
    };

    closeCarrierTypePopup = (e) => {
        setTimeout(() => {
            if(e.target.closest('.sel-item')){}
            else {
                const {shipmentData} = this.state;
                shipmentData.legs.map(leg => {
                    delete(leg.showCarrierTypePopup);
                    if(leg.carrier) {
                        const CARRIER = this.props.carrierTypeList.filter(ct => ct.id === leg.carrier);
                        if(CARRIER.length) {
                            leg.carrier_name = CARRIER[0].carrier_name;
                        } else {
                            delete(leg.carrier);
                        }
                    }
                });
                this.setState({shipmentData});
                document.body.removeEventListener('click', this.closeCarrierTypePopup);
            }
        }, 10);
    }

    closeOverlay = (e) => {
        e.stopPropagation();
        const elem = e.target.className;
        /*elem === 'cntxt-overlay' || */
        if (elem.indexOf('close-icn') >= 0) {
            this.setState({
                newShipment: false,
                newShipTrackingNumber: '',
                newShipDescription: '',
                newShipCarrier: '',
                newShipTrackingNumberError: ''
            });
        }
    };

    handleNewInputChange = (e) => {
        //console.log('input change event............', e.target.value);
        const name = e.target.name;
        const value = e.target.value;
        const STATE = { ...this.state };

        if (name === 'newShipTrackingNumber') {
            if (value === '') {
                STATE.newShipTrackingNumberError = 'Please enter Tracking number';
            } else if (Math.abs(value) === 0 || !value.match(/^(?=.*\d)[a-z0-9]+$/i)) {
                STATE.newShipTrackingNumberError = 'Please enter valid tracking number.';
            } else {
                STATE.newShipTrackingNumberError = '';
            }
            const carrier = this.getCarriers();
            if (carrier.length) {
                STATE.newShipCarrier = carrier[0];
            } else {
                STATE.newShipCarrier = '';
            }
        }
        STATE[name] = value;
        this.setState(STATE);
    };

    toggleLegConfig = (legIndex) => {
        if(this.props.carrierTypeListLoading || this.props.usersLoading || (this.props.shipmentEdit ? false : (this.props.shipmentProfileLoading || this.props.shipmentProfileListLoading))) {}
        else {
            const {shipmentData} = this.state;
            shipmentData.legs[legIndex].edit_config = !shipmentData.legs[legIndex].edit_config;
            this.setState({shipmentData});
        }
    }

    setShipmentProfileDetails = (data, createShipment) => {
        const shipmentData = {...this.state.shipmentData, ...data.config};
        let {selectedConfigurationProfile} = this.state;

        if(createShipment) {
            shipmentData.shipment_profile_id = data.config.id;
            selectedConfigurationProfile = [{label: shipmentData.device_configuration_profile_name||'---', id: shipmentData.device_configuration_profile_id||''}];

            /*DELETE UNWANTED PARAMS FROM POST/PUT PAYLOAD*/
            delete(shipmentData.geofences);
            delete(shipmentData.geofence_time_interval);
            delete(shipmentData.geofence_time_increment);
            delete(shipmentData.geofence_alert);
            delete(shipmentData.geolocation_distance);
            delete(shipmentData.id);
            delete(shipmentData.profile_description);
            delete(shipmentData.profile_name);
            delete(shipmentData.color);
            delete(shipmentData.default_profile);
            //delete(shipmentData.device_configuration_profile_id);
            delete(shipmentData.device_configuration_profile_name);
            delete(shipmentData.updated_at);
            delete(shipmentData.updated_by);
            delete(shipmentData.x_duration);
        } else {
            selectedConfigurationProfile = [{label: shipmentData.configuration_profile_name||'---', id: shipmentData.configuration_profile_id||''}];
        }

        if(data.config.legs?.filter(l => l.leg_type === 'origin').length===0) {
            shipmentData.legs.unshift({leg_type: 'origin'});
        }
        if(data.config.legs?.filter(l => l.leg_type === 'destination').length===0) {
            shipmentData.legs.push({leg_type: 'destination'});
        }

        shipmentData.legs.forEach((leg) => {
            if(this.props.carrierTypeList?.length && leg.carrier) {
                const carrierIndex = this.props.carrierTypeList.findIndex(c => Number(c.id) === Number(leg.carrier));
                if(carrierIndex >= 0) {
                    leg.carrier_name = this.props.carrierTypeList[carrierIndex].carrier_name;
                }
            }
            if(data.config.px_shipment_id) {}
            else{
                delete(leg.id);
            }
        });

        this.setState({shipmentData, selectedConfigurationProfile});
    };

    handleDeviceShipmentInputChange = (e) => {
        //console.log('input change event............', e.target.value);
        const name = e.target.name;
        let value = e.target.value;
        const {shipmentData} = this.state;

        if(name === 'auto_complete') {
            value = !value;
        }
        if(name === 'receiver_contact_number') {
            shipmentData.receiver_contact_number = value.replace(/[\)\(\s\-]/g,'');
        } else {
            shipmentData[name] = value;
        }

        this.setState({shipmentData});

        if(name === 'shipment_profile_id'){
            if(value !== '') {
                this.props.getShipmentProfile({
                    id: value,
                    onComplete: (data) => {
                        this.setShipmentProfileDetails(data, true);
                    }
                });
            } else {
                
            }
        }
    };

    setCarrier = (e) => {
        e.stopPropagation();
        const elem = e.target.className;
        if (elem.indexOf('auto-item') === 0 && e.target.dataset) {
            //this.handleNewInputChange({target: { name: 'newShipCarrier', value: e.target.dataset.name }});
            this.setState({ newShipCarrier: e.target.dataset.name, showAllCarriers: false });
        }
    };

    getCarriers = () => {
        const TN = document.getElementById('tracking_number');
        //console.log('----this.props.carriers------> ', this.props.carriers);
        if (TN && TN.value !== '' && TN.value.match(/^(?=.*\d)[a-z0-9]+$/i)) {
            return this.props.carriers?.filter((c) => {
                const L = TN.value.length;
                if (L >= 0 && CARRIERS[c].indexOf(L) >= 0) {
                    return c;
                }
            }) || [];
        } else {
            return [];
        }
    };

    handleAllCarriers = () => {
        this.setState({ showAllCarriers: true });
    };

    showShipmentProfileConfiguration = () => {
        this.setState({showShipmentProfilePop: true});
    }

    closeShipmentProfileConfiguration = () => {
        this.setState({showShipmentProfilePop: false});
    }

    showShipmentMetricConfiguration = () => {
        this.setState({showShipmentMetricConfigurationPop: true});
    }

    closeShipmentMetricConfiguration = (closeShipmentEdit) => {
        this.setState({showShipmentMetricConfigurationPop: false});
        if(closeShipmentEdit) {
            this.props.toggleAddShipment({stopPropagation:()=>{}, close: true});
        }
    }

    showDateTimePicker = async (legIndex) => {
        const {shipmentData} = this.state;
        shipmentData.legs[legIndex].showDateTime = true;
        await this.setState({shipmentData});          
    }

    /*ADD MODIY STOPS/LEGS LOCATIONS*/
    addStop = () => {
        if(this.props.carrierTypeListLoading || this.props.usersLoading || (this.props.shipmentEdit ? false : (this.props.shipmentProfileLoading || this.props.shipmentProfileListLoading))) {
            return false;
        }
		const {shipmentData} = this.state;
        let legsCount = shipmentData.legs.filter(s => !s.delete).length-1;
        let newStopName = `Stop ${legsCount}`;
        const stopNames = shipmentData.legs.map(s => { if(!s.delete) { return (s.stop_name||'').toLowerCase()} return ''; });

        while(stopNames.indexOf(newStopName.toLowerCase())>=0) {
            newStopName = `Stop ${legsCount++}`
        }

        const lastLegIndex = shipmentData.legs.findLastIndex(s => !s.delete && s.leg_type !== 'destination');
        const legConfig = JSON.parse(JSON.stringify(shipmentData.legs[lastLegIndex]));

        const newLeg = {
            mode: 'road',
            ...legConfig,

            edit_config: true,
            edit: true,
            leg_type: "stop",
            stop_name: newStopName,
            formatted_address: '',
            latitude: '',
            longitude: '',
        };

        delete(newLeg.id);

        shipmentData.legs.splice(shipmentData.legs.length-1, 0, newLeg);
        this.setState({shipmentData}, () => {
            const STOP_NODES = document.querySelectorAll('.i-address.stp.editable');
            if(STOP_NODES.length) {
                STOP_NODES[STOP_NODES.length-1].scrollIntoView();
            }
        });        
    }

    getLocations = debounce(async (searchStr, legIndex, callback) => {
        const {shipmentData} = this.state;
        const setSuggestions = (addressSuggestions) => {
            if (legIndex === 'geofence_location') {
                this.setState({
                    geofenceSuggestions: addressSuggestions.features
                });
            } else {
                shipmentData.legs[legIndex].addressSuggestions = addressSuggestions.features;
                this.setState({shipmentData});
            }
        };
        if (searchStr && searchStr.length >= 3) {
            this.props.getShipmentLocation({
                location: searchStr,
                onComplete: (data) => {
                    if (data.hasOwnProperty('predictions')) {
                        if (data.predictions && data.predictions.length) {
                            const locationSuggestions = data.predictions.map(element => {
                                const item = {
                                    text: element.description,
                                    place_name: element.description,
                                    properties: { short_code: '' },
                                    short_address: element.terms.length ? getLastTwoValues(element.terms) : ''
                                };
                                return item;
                            });
                            setSuggestions({ features: locationSuggestions });
                        } else {
                            setSuggestions({ features: [] });
                        }
                    } else {
                        if (
                            data.features &&
                            data.features.length > 0
                        ) {
                            setSuggestions(data);
                        } else {
                            setSuggestions({ features: [] });
                        }
                    }
                }
            });
        } else {
            setSuggestions({ features: [] });
        }
        
    }, 250);

    handleLegsInputChange = (e) => {
        const fieldType = e.target.dataset.type;

        if(fieldType) {
            const val = e.target.value;
            const {shipmentData} = this.state;
            const index = e.target.dataset.index;
            const legs = shipmentData.legs;
            if((fieldType === 'stop' || fieldType === 'origin' || fieldType === 'destination')) {
                legs[index].formatted_address = val;
                legs[index].latitude = null;
                legs[index].longitude = null;
                legs[index].short_address = null;
            } else if(fieldType === 'stop_name'){
                shipmentData.legs[index].stop_name = val;
            }
            this.setState({shipmentData});
        }
    }

    setNewLocation = async (location, legIndex) => {
        //console.log(location, index);
        setTimeout(async () => {
            const {shipmentData} = this.state;

            if(location.center) {
                shipmentData.legs[legIndex].latitude = location.center[1] + '';
                shipmentData.legs[legIndex].longitude = location.center[0] + '';
            } else {
                this.props.getShipmentLocation({
                    location : location.place_name,
                    geolocation: true,
                    onComplete: (DATA) => {
                        const { results } = DATA;
                        const locationDetails = {latitude: '', longitude: ''};
                        if(results.length) {
                            locationDetails.latitude = results[0].geometry.location.lat;
                            locationDetails.longitude = results[0].geometry.location.lng;
                        }                        

                        shipmentData.legs[legIndex].latitude = locationDetails.latitude + '';
                        shipmentData.legs[legIndex].longitude = locationDetails.longitude + '';
                    }
                });
            }
            shipmentData.legs[legIndex].short_address = getShortCode(location);
            shipmentData.legs[legIndex].formatted_address = location.place_name;

            delete(shipmentData.legs[legIndex].edited);
            delete(shipmentData.legs[legIndex].edit);

            if(shipmentData.round_trip) {
                if(shipmentData.legs[legIndex].leg_type === 'destination') {
                    shipmentData.legs[0].latitude = shipmentData.legs[legIndex].latitude + '';
                    shipmentData.legs[0].longitude = shipmentData.legs[legIndex].longitude + '';
                    shipmentData.legs[0].formatted_address = location.place_name;
                    shipmentData.legs[0].short_address = getShortCode(location);
                    
                    delete(shipmentData.legs[0].edited);
                    delete(shipmentData.legs[0].edit);
                    
                } else if(shipmentData.legs[legIndex].leg_type === 'origin') {
                    const destIndex = shipmentData.legs.length-1;
                    shipmentData.legs[destIndex].latitude = shipmentData.legs[legIndex].latitude + '';
                    shipmentData.legs[destIndex].longitude = shipmentData.legs[legIndex].longitude + '';
                    shipmentData.legs[destIndex].formatted_address = location.place_name;
                    shipmentData.legs[destIndex].short_address = getShortCode(location);
                    
                    delete(shipmentData.legs[destIndex].edited);
                    delete(shipmentData.legs[destIndex].edit);
                }
                
            }

            shipmentData.updated = true;
            shipmentData.legs.map(leg => {
                leg.addressSuggestions = [];
            });
            if(shipmentData.errors?.[`legs.${legIndex}.formatted_address`]) {
                delete(shipmentData.errors[`legs.${legIndex}.formatted_address`]);
            }

            this.setState({shipmentData});
        }, 10);

    };

    modifySourceDestination = async (e, legIndex) => {
        const eleClass = e.target.className;

        if(e.target.closest('.d-t-p-container')) { return false; }

        const isDueDate = eleClass.indexOf('tol dhm') >= 0;
        const {shipmentData} = this.state;
        shipmentData.legs[legIndex] = shipmentData.legs[legIndex] || {};

        if(eleClass === 'done-icn') {

            if(shipmentData.legs[legIndex].lat && shipmentData.legs[legIndex].long && shipmentData.legs[legIndex].formatted_address && shipmentData.legs[legIndex].short_address) {
                if(shipmentData.legs[legIndex].edited) {}
                else {
                    shipmentData.legs[legIndex].edit = false;
                    shipmentData.legs[legIndex].error = false;
                }    
            } else {
                shipmentData.legs[legIndex].edit = true;
                shipmentData.legs[legIndex].error = true;
            }
            
        } else {
            if(isDueDate) {} 
            else {
                shipmentData.legs[legIndex].edit = true;
            }
        }
        shipmentData.updated = true;
        await this.setState({shipmentData});

        // if(shipmentData.legs[legIndex].edit && !isDueDate ) {
        //     document.getElementById(`${shipmentData.legs[legIndex].leg_type}_location_${legIndex}`).focus();
        // }
    }

    switchToRoundTrip = async (confirm) => {
        const {shipmentData} = this.state;
        const isRoundTrip = shipmentData.round_trip;

        const legs = shipmentData.legs || [];
        let legsCount = 0;

        legs.forEach(leg => {
            if(leg.delete !== 1){
                legsCount++;
            }
        });
        
        /*IT is round trip need to switch to regular*/
        if(isRoundTrip) {
            //debugger;
            const LEGS = legs.filter(s=>!s.delete);
            if(LEGS?.length && (!LEGS[0].error || !LEGS[0].nameEmptyError)) {
                shipmentData.legs[shipmentData.legs.length-1].latitude = LEGS[1].latitude + '';
                shipmentData.legs[shipmentData.legs.length-1].longitude = LEGS[1].longitude + '';
                shipmentData.legs[shipmentData.legs.length-1].formatted_address = LEGS[1].formatted_address;

                let shortTextAddress = null;
                await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${LEGS[0].longitude},${LEGS[0].latitude}.json?access_token=${process.env.REACT_APP_mapboxAccessCode}`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                }).then(response => response.json())
                .then(addressSuggestions => {                    
                    shortTextAddress = getShortCode(addressSuggestions.features[0]);
                });
                //console.log('shortTextAddress: ', shortTextAddress);
                shipmentData.legs[shipmentData.legs.length-1].short_address = LEGS[1].short_address || shortTextAddress;
            }

            legs.forEach((leg, index) => {
                if(leg.delete !== 1 && leg.leg_type === 'stop'){
                    if((shipmentData.device_configuration_profile_id || shipmentData.configuration_profile_id)) {
                        leg.delete = 1;
                    }                    
                }
            });
        } 
        /*IT is regular need to switch to round trip*/
        else {
            //const stop = {...this.state.shipmentData.destination};
            if(legsCount === 0 && !confirm) {
                legs.forEach((leg, index) => {
                    if(leg.delete !== 1 && leg.leg_type==='stop'){
                        if((shipmentData.device_configuration_profile_id || shipmentData.configuration_profile_id)) {
                            leg.delete = 1;
                        }
                    }
                });
                if(shipmentData.legs?.length > 1 && (shipmentData.legs[shipmentData.legs.length-1].latitude+'') === (shipmentData.legs[0].latitude+'') && (shipmentData.legs[shipmentData.legs.length-1].longitude+'') === (shipmentData.legs[0].longitude+'')) {}
                else {
                    legs.splice(1, 0, {
                        ...shipmentData.legs[0],
                        formatted_address: shipmentData.legs[shipmentData.legs.length-1].formatted_address,
                        short_address: shipmentData.legs[shipmentData.legs.length-1].short_address,
                        latitude: shipmentData.legs[shipmentData.legs.length-1].latitude + '', 
                        longitude: shipmentData.legs[shipmentData.legs.length-1].longitude + '',
                        stop_name: "Stop 1",
                        leg_type: "stop",
                    });
                    delete(legs[1].id);
                }

            } else {
                if(confirm) {
                    legs.forEach((leg, index) => {
                        if(leg.delete !== 1 && leg.leg_type === 'stop'){
                            if((shipmentData.device_configuration_profile_id || shipmentData.configuration_profile_id)) {
                                leg.delete = 1;
                            }
                        }
                    });
                    legs.splice(1, 0, {
                        ...shipmentData.legs[0],
                        formatted_address: shipmentData.legs[shipmentData.legs.length-1].formatted_address,
                        short_address: shipmentData.legs[shipmentData.legs.length-1].short_address,
                        latitude: shipmentData.legs[shipmentData.legs.length-1].latitude + '', 
                        longitude: shipmentData.legs[shipmentData.legs.length-1].longitude + '',
                        stop_name: "Stop 1",
                        leg_type: "stop",
                    });
                    delete(legs[1].id);
                } else {
                    this.setState({switchToRoundTripConfirm: true});
                    return false;
                }                
            }
            shipmentData.legs[shipmentData.legs.length-1].latitude = shipmentData.legs[0].latitude;
            shipmentData.legs[shipmentData.legs.length-1].longitude = shipmentData.legs[0].longitude;
            shipmentData.legs[shipmentData.legs.length-1].formatted_address = shipmentData.legs[0].formatted_address;
            shipmentData.legs[shipmentData.legs.length-1].short_address = shipmentData.legs[0].short_address;

        }

        if(shipmentData.legs[shipmentData.legs.length-1].formatted_address && shipmentData.legs[0].formatted_address) {
        } else {
            shipmentData.shipmentData.legs[shipmentData.legs.length-1].edit = true;
            shipmentData.shipmentData.legs[shipmentData.legs.length-1].error = true;

            if(shipmentData.shipmentData.legs[1].formatted_address) {}
            else {
                shipmentData.shipmentData.legs[1].edit = true;
                shipmentData.shipmentData.legs[1].error = true;
            }
        }

        shipmentData.round_trip = isRoundTrip ? 0 : 1;
        //shipmentData.stops = stops;  
        shipmentData.updated = true;      

        this.setState({shipmentData, switchToRoundTripConfirm: false});
    }
    /*end STOPS*/
    onAddCarrierType = (data) => {
        if(data.data.success) {
            const shipmentData = this.state.shipmentData;
            if(data.hasOwnProperty('legIndex')) {
                shipmentData.legs[data.legIndex].carrier = data.data.data.id;
                shipmentData.legs[data.legIndex].carrier_name = data.data.data.carrier_name;
            }
            this.setState({
                shipmentData,
                showSaveConfirmation: false,
                showSaveSuccess: true,
                saveSuccessTitle: data.data.message,
                saveSuccessStatus: 'success',
            });
        } else {
            this.setState({
                showSaveConfirmation: false,
                showSaveSuccess: true,
                saveSuccessTitle: data.data.message,
                saveSuccessStatus: 'danger',
            });
        }
    };

    onDeleteCarrierType = (data) => {
        if(data.success) {
            this.setState({
                shipmentData: data.shipmentData,
                showSaveConfirmation: false,
                showSaveSuccess: true,
                saveSuccessTitle: data.message,
                saveSuccessStatus: 'success',
            });
        } else {
            this.setState({
                showSaveConfirmation: false,
                showSaveSuccess: true,
                saveSuccessTitle: data.message,
                saveSuccessStatus: 'danger',
            });
        }
    };

    assignTrackerDevice = (data) => {
        const {tracker_id,px_shipment_id} = this.state.shipmentData;
        // this.props.assignDeviceToShipment({deviceID : tracker_id, id : px_shipment_id})
        this.props.assignDeviceToShipment({
            deviceID : tracker_id, 
            id : px_shipment_id,
            callbackOnComplete: (data,obj) => {
                const {shipmentData} = this.state;
                if(data?.data?.error || obj){                    
                    shipmentData.errors = shipmentData.errors || {};
                    shipmentData.errors.tracker_id = data?.data?.error || obj[0];

                    this.setState({
                        showDeleteWarning: false,
                        showSaveSuccess: true,
                        saveSuccessTitle: data?.data?.error || obj[0],
                        saveSuccessStatus: 'warning',
                        showSuccessDuration: 4000
                    });
                } else if (data){
                    this.setState({
                        showDeleteWarning: false,
                        showSaveSuccess: true,
                        saveSuccessTitle: data,
                        showSuccessDuration: 4000,
                        saveSuccessStatus: 'success',
                    });
                    setTimeout(() => {
                        this.props.toggleAddShipment({stopPropagation:()=>{}});
                        window.location.href = `/shipments/Active#nship=${px_shipment_id}`;
                        // location.reload();
                    }, 1000);
                }
            }
        });
    }

    render() {
        const CARRIER = this.getCarriers();
        const {shipmentData} = this.state;

        const LEG_USERS = [];
        let isDueDateNotified = false;
        for(let legIndex=0; legIndex<shipmentData.legs?.length||0; legIndex++){
            LEG_USERS[legIndex] = this.props.users.filter(user => {
                let validUser = `${user.email}`.toLowerCase().indexOf(shipmentData.legs[legIndex]?.addUserSearch?.toLowerCase()) >= 0;
                if(validUser && user.deleted === 0) return user;
                return false;
            }).slice(0,6);
            if(shipmentData.legs[legIndex].leg_type === 'destination') {
                isDueDateNotified = shipmentData.legs[legIndex].entry_notify || shipmentData.legs[legIndex].exit_notify || shipmentData.legs[legIndex].leg_entry_time || shipmentData.legs[legIndex].leg_exit_time;
            }
        };
        return (
            <>
            <div className="cntxt-overlay c-ship">
                <div className={`left-bar${enableETA() && this.state.shipmentAssetType === 'carrier' ? '' : ' extn'}`}>
                    <span className="material-icons-outlined close-icn" onClick={this.props.toggleAddShipment}>close</span>
                    <div className="n-form ship-det new-ship">
                        <div className="n-hdr">{`${this.props.shipmentEdit ? 'Edit Shipment' : 'Add Shipments'}`}</div>
                        {this.props.carrierTypeListLoading || this.props.usersLoading || (this.props.shipmentEdit ? false : (this.props.shipmentProfileLoading || this.props.shipmentProfileListLoading)) ? <div className="loading abs" /> : <></>}
                        <div className="v-scroll">
                        <PerfectScrollbar>
                        {
                            enableETA() && !this.props.shipmentEdit ?
                            <ul id="shipment_type_switch"
                                className="switch-btn s-t-switch"
                            >
                                <li id="deviceShipments"
                                    className={`btn-i ${this.state.shipmentAssetType === 'device' ? 'a' : ''}`}
                                    data-view="device"
                                    onClick={e=>{
                                        e.stopPropagation();
                                        if(this.state.shipmentAssetType !== 'device') {
                                            this.setState({shipmentAssetType: 'device'});
                                        }
                                    }}
                                >
                                    Device
                                </li>
                                <li id="caerrierShipments"
                                    className={`btn-i ${this.state.shipmentAssetType === 'carrier' ? 'a' : ''}`}
                                    data-view="satellite"
                                    onClick={e=>{
                                        e.stopPropagation();
                                        if(this.state.shipmentAssetType !== 'carrier') {
                                            this.props.onGetCarriers();
                                            this.setState({shipmentAssetType: 'carrier'});
                                        }
                                    }}
                                >
                                    Carrier
                                </li>
                            </ul>
                            : ''
                        }
                        {
                            enableETA() && this.state.shipmentAssetType === 'carrier' ?
                            <>
                            {/*CARRIER SHIPMENTS*/}
                            <div className="i-sec fld">
                                <input
                                    key={'tracking_number'}
                                    id="tracking_number"
                                    type="text"
                                    className="i-fld"
                                    placeholder=" "
                                    onChange={this.handleNewInputChange}
                                    name="newShipTrackingNumber"
                                    maxLength="26"
                                />
                                <label htmlFor="tracking_number">Tracking number</label>
                                <div className="error_msg">{this.state.newShipTrackingNumberError}</div>
                            </div>
                            <div className="i-sec fld">
                                <input
                                    key={'description'}
                                    id="newShipDescription"
                                    type="text"
                                    className="i-fld"
                                    placeholder=" "
                                    onChange={this.handleNewInputChange}
                                    maxLength="32"
                                    name="newShipDescription"
                                />
                                <label htmlFor="newShipDescription" className="optional">
                                    Description <span>(Characters Limit 32)</span>
                                </label>
                                <div></div>
                            </div>
                            <div className="i-sec fld">
                                <div
                                    key={'carrier_ship'}
                                    id="carrier"
                                    type="text"
                                    className="i-fld i-carrier"
                                    readOnly
                                    name="newShipCarrier"
                                >
                                    {this.state.newShipCarrier ? (
                                        <div
                                            data-name={this.state.newShipCarrier}
                                            className={`auto-item ${this.state.newShipCarrier
                                                    ? this.state.newShipCarrier.toLowerCase()
                                                    : ''
                                                }`}
                                        >
                                            <span className="auto-item-lbl">{this.state.newShipCarrier}</span>
                                            <span className="auto-item-logo"></span>
                                        </div>
                                    ) : (
                                            CARRIER.map((car) => {
                                                return (
                                                    <div data-name={car} className={`auto-item ${car.toLowerCase()}`}>
                                                        <span className="auto-item-lbl">{car}</span>
                                                        <span className="auto-item-logo"></span>
                                                    </div>
                                                );
                                            })
                                        )}
                                </div>
                                <label htmlFor="carrier" className={CARRIER.length ? 't' : 'b'}>
                                    Carrier <span>(Auto detect)</span>
                                </label>
                                {
                                    this.props.carriers.length === 0 && !this.props.carriersLoading ?
                                        <div className="error_msg">Carriers not available.</div> :
                                        ''
                                }

                                {/* {this.state.newShipCarrier !== '' ? <span className="change-carrier-lnk" onClick={this.handleAllCarriers}>Change</span> : ''}
                                {
                                    this.state.showAllCarriers ? 
                                        <div className="carrier-auto" onClick={this.setCarrier}>
                                        {    this.props.dashboard.carriers.map((car) => {
                                                return <div data-name={car.name} className={`auto-item ${car.name.toLowerCase()}`}>
                                                            <span className="auto-item-lbl">{car.name}</span>
                                                            <span className="auto-item-logo"></span>
                                                        </div>
                                            })
                                        }                                            
                                        </div>
                                    : ''
                                } */}
                            </div>
                            </>
                            :
                            <>
                            {/*DEVICE SHIPMENTS*/}
                            <div className="fld-sec ship-details-sec">
                                <div className="i-sec fld">
                                    <input
                                        id="client_shipment_id"
                                        key="client_shipment_id"
                                        autoComplete="off"
                                        type="text"
                                        className="i-fld"
                                        placeholder=" "
                                        onChange={this.handleDeviceShipmentInputChange}
                                        name="client_shipment_id"
                                        maxLength="26"
                                        value={shipmentData.client_shipment_id||''}
                                        disabled={this.props.disableEdit}
                                    />
                                    <label htmlFor="client_shipment_id" className="optional">Customer Shipment Id</label>
                                </div>
                                <div className="i-sec fld" style={{alignItems: 'center'}}>
                                    <div className="f-j-s-b">
                                        <SelectBox
                                            css={'sort-lbl i-fld'}
                                            popCss={'left'}
                                            name="shipment_profile_id"
                                            id="shipment_profile_id"
                                            key="shipment_profile_id"
                                            onChange={e => this.handleDeviceShipmentInputChange({target: {name: 'shipment_profile_id', value: e.id}})}
                                            useProps
                                            value={shipmentData.shipment_profile_id}
                                            list={this.props.shipmentEdit ? this.props.selectedShipmentProfile : this.props.shipmentProfileList.map(sp=>({label: sp.profile_name, id: sp.id}))}
                                            disable={this.props.shipmentEdit || this.props.disableEdit}
                                        >
                                        </SelectBox>
                                        <label htmlFor="shipment_profile_id">Shipment Profile</label>
                                        {
                                            this.props.shipmentEdit ? <></> :
                                            <span
                                                style={shipmentData.shipment_profile_id && shipmentData.hasOwnProperty('shipment_details') ? {color: '#999', position: 'static', marginLeft: '10px'} : {cursor: 'not-allowed', opacity: 0.5, color: '#999', position: 'static', marginLeft: '10px'}}
                                                className="material-icons-outlined visibility c-pointer"
                                                id='eye-icon'
                                                onClick={(e)=>{
                                                    e.stopPropagation();
                                                    if(shipmentData.shipment_profile_id && shipmentData.hasOwnProperty('shipment_details')) {
                                                        this.showShipmentProfileConfiguration();
                                                    }
                                                }}
                                            >
                                                visibility
                                            </span>
                                        }
                                    </div>                                    
                                    {
                                        shipmentData.errors?.shipment_profile_id ?
                                        <div className="error_msg">{shipmentData.errors.shipment_profile_id}</div>
                                        : ''
                                    }
                                </div>
                                {console.log("SHIPMENT DETAILS YOU NEED", shipmentData)}
                                <div className="i-sec fld" style={{display: this.props.shipmentEdit ? 'flex' : '', justifyContent: 'space-between'}}>
                                    <input
                                        id="tracker_id"
                                        key="tracker_id"
                                        autoComplete="off"
                                        type="text"
                                        className={`i-fld ${this.props.shipmentEdit && shipmentData.status === 'Inventory' /*&& (shipmentData.tracker_id === '' || shipmentData.tracker_id === null)*/ ? 'wd-at' : ''}`}
                                        placeholder=" "
                                        onChange={this.handleDeviceShipmentInputChange}
                                        name="tracker_id"
                                        maxLength="26"
                                        value={shipmentData.tracker_id||''}
                                        disabled={this.props.disableEdit || (this.props.shipmentEdit && shipmentData.status !== 'Inventory')}
                                    />
                                    <label htmlFor="tracker_id" className={`optional ${this.props.shipmentEdit && shipmentData.status === 'Inventory' /*&& (shipmentData.tracker_id === '' || shipmentData.tracker_id === null)*/ ? 'wd-at' : ''}`} style={{paddingTop: shipmentData.status === 'Inventory' ? '14px' : ''}}>Tracker Id</label>
                                    {console.log("----->",this.props.shipmentEdit ,shipmentData.status, shipmentData.tracker_id)}
                                    {
                                        this.props.shipmentEdit && shipmentData.status === 'Inventory' /*&& (shipmentData.tracker_id === '' || shipmentData.tracker_id === null)*/ ? 
                                        <div className={`c-btn`} style={{width: '30%', fontSize: '14px', padding: '14px'}} onClick={this.assignTrackerDevice}>Assign Tracker</div>
                                        : <></>
                                    }
                                    {
                                        shipmentData.errors?.tracker_id ?
                                        <div className="error_msg" style={{position: 'absolute', top: '46px'}}>{shipmentData.errors.tracker_id}</div>
                                        : ''
                                    }
                                </div>
                                <div className="i-sec fld">
                                    <input
                                        id="shipment_description"
                                        key="shipment_description"
                                        autoComplete="off"
                                        type="text"
                                        className="i-fld"
                                        placeholder=" "
                                        onChange={this.handleDeviceShipmentInputChange}
                                        maxLength="32"
                                        name="shipment_description"
                                        value={shipmentData.shipment_description||shipmentData.description||''}
                                    />
                                    <label htmlFor="shipment_description" className="optional">
                                        Description <span>(Characters Limit 32)</span>
                                    </label>
                                </div>
                                <div className="i-sec fld stat">
                                    <div className="config-item">
                                        Autocomplete
                                    </div>
                                    <div 
                                        className={`status-bar${shipmentData.auto_complete ? ' a' : ''}${this.props.disableEdit ? ' d': ''}`}
                                        onClick={ (e) => {
                                            e.stopPropagation();
                                            if(this.props.disableEdit){}
                                            else {
                                                this.handleDeviceShipmentInputChange({target:{name: 'auto_complete', value: shipmentData.auto_complete}});
                                            }
                                        }}
                                        style={{marginTop: '-4px'}}
                                    ></div>
                                </div>
                                <div className="i-sec fld">
                                    <input
                                        id="receiver_email"
                                        key="receiver_email"
                                        type="text"
                                        autoComplete="off"
                                        className="i-fld"
                                        placeholder=" "
                                        onChange={this.handleDeviceShipmentInputChange}
                                        maxLength="70"
                                        name="receiver_email"
                                        value={shipmentData.receiver_email||''}
                                        disabled={this.props.disableEdit}
                                    />
                                    <label htmlFor="receiver_email">
                                        Receiver Email
                                    </label>
                                    {
                                        shipmentData.errors?.receiver_email ?
                                        <div className="error_msg">{shipmentData.errors.receiver_email}</div>
                                        : ''
                                    }
                                </div>
                                <div className="i-sec fld">
                                    <div className="f-j-s-b">
                                        <SelectBox
                                            css={'sort-lbl i-fld p-dc'}
                                            popCss={'left'}
                                            name="receiver_country_code"
                                            id="receiver_country_code"
                                            value={shipmentData.receiver_country_code || '1'}
                                            onChange={e => this.handleDeviceShipmentInputChange({target: {name: 'receiver_country_code', value: e.id}})}
                                            list={CODES.map(c=>({label: c.dial_code, id: c.dial_code.replace(/[\+\s]/g,'')}))}
                                            disable={this.props.disableEdit}
                                            useProps
                                        >
                                        </SelectBox>
                                        <input
                                            id="receiver_contact_number"
                                            type="text"
                                            key="receiver_contact_number"
                                            className="i-fld contact"
                                            autoComplete="off"
                                            placeholder=" "
                                            maxLength="19"
                                            onChange={this.handleDeviceShipmentInputChange}
                                            name="receiver_contact_number"
                                            value={formatPhone(shipmentData.receiver_contact_number||'')}
                                            disabled={this.props.disableEdit}
                                        />
                                        <label htmlFor="receiver_contact_number" style={{width:'unset'}}>Receiver contact number</label>
                                    </div>
                                    {
                                        shipmentData.errors?.receiver_contact_number ?
                                        <div className="error_msg">{shipmentData.errors.receiver_contact_number}</div>
                                        : ''
                                    }
                                </div>
                                <div className="i-sec f-j-s-b sec-hdr stat" style={{marginBottom:'4%'}}>
                                    <div className="config-item">Round trip</div>
                                    <div className={`status-bar${shipmentData.round_trip ? ' a' : ''}${shipmentData.round_trip || shipmentData.legs?.length > 1 ? '' : ' d t-tip l'}${this.props.disableEdit ? ' d': ''}`}
                                        onClick={ (e) => {
                                            e.stopPropagation();
                                            if((!shipmentData.px_shipment_id || shipmentData.status === 'Inventory' || shipmentData.status === 'New') && ((shipmentData.legs?.length && shipmentData.legs[0].latitude && shipmentData.legs[0].longitude && shipmentData.legs[shipmentData.legs.length-1].latitude && shipmentData.legs[shipmentData.legs.length-1].longitude))) {
                                                this.switchToRoundTrip();
                                            }
                                        }}
                                    >
                                        {shipmentData.round_trip || shipmentData.legs?.length > 1 ? '' : <span className='t-txt'>Will be enabled after origin and destination added.</span>}
                                    </div>
                                    {
                                        shipmentData.errors?.round_trip ?
                                        <div className="error_msg a" style={{marginTop:'60px'}}>{shipmentData.errors.round_trip}</div>
                                        : ''
                                    }
                                </div>                                
                                <div className="i-sec f-j-s-b fld b-b" style={{alignItems: 'center'}}>
                                    <SelectBox
                                        css={'sort-lbl i-fld'}
                                        popCss={'left'}
                                        useProps
                                        allowSearch
                                        list={this.state.selectedConfigurationProfile}
                                        value={shipmentData.configuration_profile_id || shipmentData.device_configuration_profile_id || ''}
                                        disable={true}
                                    >
                                    </SelectBox>
                                    <label className="fld-lbl">Device Configuration Profile</label>
                                    {
                                        shipmentData.status === 'Inventory' ? <></> :
                                        this.props.shipmentEdit && (shipmentData.status === 'New' || shipmentData.status === 'In-Transit') ?
                                        <span 
                                            style={{marginLeft:'20px', cursor: 'pointer'}}
                                            data-type="edit"
                                            title="Edit Shipment Device Configuration"
                                            onClick={e => {
                                                e.stopPropagation();
                                                if((shipmentData.configuration_profile_id)) {
                                                    this.showShipmentMetricConfiguration(true);
                                                }
                                            }}
                                        >{EditIcon()}</span>
                                        :
                                        <span
                                            className="material-icons-outlined visibility"
                                            style={this.state.selectedConfigurationProfile?.length && (shipmentData.device_configuration_profile_id||shipmentData.configuration_profile_id) ? {marginLeft: '20px'} : {marginLeft: '20px', cursor: 'not-allowed', opacity: '0.6'}}
                                            title="View Shipment Device Configuration"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if((shipmentData.device_configuration_profile_id || shipmentData.configuration_profile_id)) {
                                                    this.showShipmentMetricConfiguration();
                                                }
                                            }}
                                        >
                                            visibility
                                        </span>
                                    }
                                    {
                                        shipmentData.errors?.device_configuration_profile_id || shipmentData.errors?.configuration_profile_id ?
                                        <div className="error_msg a" style={{marginTop:'46px'}}>{shipmentData.errors.device_configuration_profile_id || shipmentData.errors.configuration_profile_id}</div>
                                        : ''
                                    }
                                </div>
                            </div>
                            <div className="fld-sec ship-det">
                                <div className="i-sec sec-hdr">
                                    Legs
                                </div>
                                <div className="ship-src-dest vert">
                                    <div className="ship-path">
                                        {
                                            shipmentData.legs.map((leg, legIndex) => {
                                                const disableEdit = (leg.entry_notify || leg.exit_notify) || this.props.disableEdit || (leg.leg_type === 'origin' && (shipmentData.status === 'In-Transit' || shipmentData.status === 'Destination' || shipmentData.status === 'Completed')) || (this.props.carrierTypeListLoading || this.props.usersLoading || (this.props.shipmentEdit ? false : (this.props.shipmentProfileLoading || this.props.shipmentProfileListLoading)));
                                                const disableIntransitEdit = leg.leg_exit_time || this.props.disableEdit;
                                                if(leg.delete) {
                                                    return <></>;
                                                }
                                                return (
                                                    <>
                                                    <div 
                                                        className={`i-address ${leg.leg_type==='origin' ? 'src' : leg.leg_type==='destination' ? 'dest': 'stp'} editable`}
                                                        id={`_address_loc_${legIndex}_`}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if(!leg.edit && !disableEdit) {
                                                                this.modifySourceDestination(e, legIndex);
                                                            }
                                                        }}
                                                    >
                                                        {leg.leg_type==='origin' ? <div className="ship-src"/> : leg.leg_type==='destination' ? <><div className="ship-dest"><span className="icn loc-icn"/></div><div className="w-brdr"/></> : <div className={`stop-box${shipmentData.round_trip ? ' r-t' : ''}`}/>}
                                                        <div className="l i-loc" style={disableEdit ? {cursor: 'not-allowed'} : {}}>
                                                            <div className="l2">
                                                            {
                                                            leg.leg_type==='origin' ? 'ORIGIN' : 
                                                            leg.leg_type==='destination' ? "DESTINATION": 
                                                            !(leg.edit || (shipmentData.errors && shipmentData.errors[`legs.${legIndex}.stop_name`])) ?
                                                            leg.stop_name || `Stop ${leg.stop_name}`
                                                            : <></>
                                                            }
                                                            </div>
                                                            {leg.leg_type==='stop' && !disableEdit ? 
                                                                <span 
                                                                    className="del-icn" 
                                                                    title="Delete"
                                                                    onClick={e => {
                                                                        e.stopPropagation();
                                                                        const {shipmentData} = this.state;
                                                                        shipmentData.updated = true;
                                                                        shipmentData.legs[legIndex].delete = 1;
                                                                        this.setState({shipmentData});
                                                                    }}
                                                                ></span> : <></>
                                                            }
                                                            {
                                                            leg.edit || (shipmentData.errors && shipmentData.errors[`legs.${legIndex}.stop_name`]) ?
                                                            <>
                                                            {
                                                            leg.leg_type === 'stop' ?
                                                            <div className="i-fld-c">
                                                                <input 
                                                                    type="text" 
                                                                    autoComplete="off"
                                                                    className="i-fld" 
                                                                    placeholder="Stop Name" 
                                                                    id={`stop_name_${legIndex}`} 
                                                                    name={`stop_name_${legIndex}`} 
                                                                    value={leg.stop_name||''}
                                                                    onChange={this.handleLegsInputChange}
                                                                    data-index={legIndex}
                                                                    data-type={'stop_name'}
                                                                    maxLength="32"
                                                                    disabled={disableEdit}
                                                                />
                                                                {
                                                                    shipmentData.errors && shipmentData.errors[`legs.${legIndex}.stop_name`] ?
                                                                    <div className="error_msg">{shipmentData.errors[`legs.${legIndex}.stop_name`]}</div>
                                                                    : ''
                                                                }
                                                            </div>
                                                            : <></>
                                                            }
                                                            <div className="i-fld-c">
                                                                <input 
                                                                    type="text" 
                                                                    autoComplete="off"
                                                                    className="i-fld" 
                                                                    placeholder={`${LOCATION_MAP[leg.leg_type]} Location`}
                                                                    id={`${leg.leg_type}_location_${legIndex}`}
                                                                    name={`${leg.leg_type}_location_${legIndex}`}
                                                                    onChange={(e) => {
                                                                            this.handleLegsInputChange(e);
                                                                            this.getLocations(e.target.value, legIndex);
                                                                        }
                                                                    }
                                                                    value={leg.formatted_address||''}
                                                                    data-index={legIndex}
                                                                    data-type={leg.leg_type}
                                                                    disabled={disableEdit}
                                                                />
                                                                {leg.loading ? <div className="loading abs" style={{zIndex:999999}}/> : ''}
                                                            </div>
                                                            {
                                                                leg.formatted_address?.length && !disableEdit ?
                                                                <span 
                                                                    className="done-icn" 
                                                                    style={(leg.leg_type === 'origin' || leg.leg_type === 'destination') ? {top: '28px'} : {}}
                                                                    title="Done"
                                                                    onClick={e => {
                                                                        e.stopPropagation();
                                                                        const {shipmentData} = this.state;
                                                                        shipmentData.updated = true;
                                                                        if(shipmentData.legs[legIndex].latitude && shipmentData.legs[legIndex].longitude) {
                                                                            shipmentData.legs[legIndex].edit = false;
                                                                            if(shipmentData.errors) {
                                                                                delete(shipmentData.errors[`legs.${legIndex}.formatted_address`]);
                                                                            }
                                                                        } else {
                                                                            shipmentData.errors = shipmentData.errors || {};
                                                                            shipmentData.errors[`legs.${legIndex}.formatted_address`] = 'Stop location is not valid.';
                                                                            shipmentData.legs[legIndex].addressSuggestions = [];
                                                                        }
                                                                        this.setState({shipmentData});
                                                                    }}
                                                                ></span> 
                                                                : <></>
                                                            }
                                                            {
                                                                leg.addressSuggestions?.length && !disableEdit ?
                                                                <ul className="loc-s-list">
                                                                    {
                                                                        leg.addressSuggestions?.map((address, index) => {
                                                                            return (
                                                                                <li
                                                                                    key={`loc_suggestion_${index}`}
                                                                                    title={address.place_name}
                                                                                    onClick={ (e) => {
                                                                                        e.stopPropagation();
                                                                                        this.setNewLocation(address, legIndex);
                                                                                    }}

                                                                                >{address.place_name}</li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                                : 
                                                                ''
                                                            }
                                                            {
                                                                shipmentData.errors && (shipmentData.errors[`legs.${legIndex}.formatted_address`] || shipmentData.errors[`legs.${legIndex}.longitude`] || shipmentData.errors[`legs.${legIndex}.latitude`] || shipmentData.errors[`legs.${legIndex}.leg_type`]) ?
                                                                <div className="error_msg" style={{margin:0,position:'static'}}>{shipmentData.errors[`legs.${legIndex}.formatted_address`] || shipmentData.errors[`legs.${legIndex}.longitude`] || shipmentData.errors[`legs.${legIndex}.latitude`] || shipmentData.errors[`legs.${legIndex}.leg_type`]}</div>
                                                                : ''
                                                            }
                                                            </>
                                                            :
                                                            <div className="l3">{leg.formatted_address || '---'}</div>
                                                            }
                                                        </div>
                                                    {
                                                    leg.leg_type !== 'origin' ?
                                                    <>
                                                        <>
                                                        <div className={`a-a-f${leg.showDateTime ? ' show' : ""}`}>
                                                            <label className="a-a-l">Anticipated arrival:</label>
                                                            <div className={`a-a-t i-fld`}>
                                                                <span 
                                                                    style={disableEdit ? {flexGrow:1, cursor: 'not-allowed'} : {flexGrow:1}}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        if(disableEdit){}
                                                                        else{
                                                                            this.showDateTimePicker(legIndex);
                                                                        }
                                                                    }}
                                                                >{leg.due_date ? getDateFromDuration(shipmentData.shipment_created_date ? new Date(shipmentData.shipment_created_date.replace(/-/g,'/')) : new Date(), leg.due_date, shipmentData.shipment_created_date ? false : true) : '---'}</span> 
                                                                <span 
                                                                    className="material-icons-outlined"
                                                                    style={disableEdit ? {cursor: 'not-allowed'} : {}}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        if(disableEdit) {}
                                                                        else {
                                                                            this.showDateTimePicker(legIndex);
                                                                        }                                                                        
                                                                    }}
                                                                >event</span> 
                                                                <span 
                                                                    className={`material-icons c-a-a-t`}
                                                                    style={disableEdit ? {cursor: 'not-allowed'} : {}}
                                                                    title="Clear Anticipated arrival"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        if(disableEdit) {}
                                                                        else {
                                                                            let {shipmentData} = this.state;
                                                                            shipmentData.legs[legIndex].due_date = null;
                                                                            shipmentData.legs[legIndex].due_dateError = false;
                                                                            shipmentData.legs[legIndex].showDateTime = false;
                                                                            this.setState({shipmentData});
                                                                        }
                                                                        
                                                                    }}
                                                                >close</span>
                                                            </div>
                                                            {
                                                                shipmentData.errors?.[`legs.${legIndex}.due_date`] ?
                                                                <div className="error_msg a" style={{marginTop:'40px'}}>{shipmentData.errors?.[`legs.${legIndex}.due_date`]}</div>
                                                                : ''
                                                            }
                                                        </div>
                                                        {
                                                        leg.showDateTime && !disableEdit ?
                                                        <DateTimePickerHOC
                                                            key={`aniticipated_arrival_${legIndex}`}
                                                            index={legIndex}
                                                            date={getDate(shipmentData.shipment_created_date ? new Date(shipmentData.shipment_created_date): new Date(), true, shipmentData.shipment_created_date?false:true, true)}
                                                            duration={leg.due_date}
                                                            onClose={() => {
                                                                const {shipmentData} = this.state;
                                                                shipmentData.legs[legIndex].showDateTime = false;
                                                                this.setState({shipmentData});
                                                            }}
                                                            onChange={(dateObj) => {
                                                                const {shipmentData} = this.state;
                                                                const due_date = getDate(dateObj.date, true, true, true).getTime();
                                                                const createdDate = getDate(shipmentData.shipment_created_date ? shipmentData.shipment_created_date : new Date(), true, shipmentData.shipment_created_date?false:true, true).getTime();
                                                                shipmentData.legs[legIndex].showDateTime = false;
                                                                shipmentData.legs[legIndex].due_date = Math.ceil((due_date - createdDate)/1000);
                                                                this.setState({shipmentData});
                                                            }}
                                                        />
                                                        : ""
                                                        }
                                                        </>                                                            
                                                    </>
                                                    : <></>
                                                    }
                                                    </div>
                                                    {
                                                        leg.leg_type !== 'destination' ?
                                                        <>
                                                        {
                                                            leg.edit_config ?
                                                            <div className="leg-sec">
                                                                <span 
                                                                    className="material-icons i-config-icn"
                                                                    onClick={e => {
                                                                        e.stopPropagation();
                                                                        this.toggleLegConfig(legIndex);
                                                                    }}
                                                                >settings</span>
                                                                <div className="i-sec s-row">
                                                                    <div className="i-sec i-mode">
                                                                        <label className="i-sec-lbl" htmlFor={`mode-${legIndex}`}>
                                                                            Mode
                                                                        </label>
                                                                        <SelectBox
                                                                            style={{ width: '112px' }}
                                                                            useProps
                                                                            list={MODES}
                                                                            value={leg.mode || 'auto'}
                                                                            onChange={o => {
                                                                                const {shipmentData} = this.state;
                                                                                if(shipmentData.legs[legIndex].mode !== o.id) {
                                                                                    shipmentData.legs[legIndex].carrier_name = '';
                                                                                    shipmentData.legs[legIndex].carrier = '';
                                                                                }
                                                                                shipmentData.legs[legIndex].mode = o.id;
                                                                                shipmentData.updated = true;
                                                                                this.setState({shipmentData});
                                                                            }}
                                                                            disable={disableEdit}
                                                                        />
                                                                        {
                                                                            shipmentData.errors && shipmentData.errors[`legs.${legIndex}.mode`] ?
                                                                            <div className="error_msg">{shipmentData.errors[`legs.${legIndex}.mode`]}</div>
                                                                            : <></>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        leg.mode === 'Water' ?
                                                                        <>
                                                                        <div className="i-sec">
                                                                            <label className="i-sec-lbl" htmlFor={`mmsi-${legIndex}`}>
                                                                                MMSI
                                                                            </label>
                                                                            <input
                                                                                className="ip"
                                                                                id={`mmsi-${legIndex}`}
                                                                                type="text"
                                                                                placeholder=""
                                                                                maxLength="32"
                                                                                autoComplete="off"
                                                                                value={leg.mmsi}
                                                                                onChange={(e) => {
                                                                                    const {shipmentData} = this.state;
                                                                                    shipmentData.legs[legIndex].mmsi = e.target.value;
                                                                                    shipmentData.updated = true;
                                                                                    this.setState({shipmentData});
                                                                                }}
                                                                                disabled={disableEdit}
                                                                            />
                                                                            {
                                                                                shipmentData.errors && shipmentData.errors[`legs.${legIndex}.mmsi`] ?
                                                                                <div className="error_msg a">{shipmentData.errors[`legs.${legIndex}.mmsi`]}</div>
                                                                                :
                                                                                shipmentData.errors && shipmentData.errors[`legs.${legIndex}.container_id`] ?
                                                                                <div className="error_msg a">{shipmentData.errors[`legs.${legIndex}.container_id`]}</div>
                                                                                :
                                                                                <></>
                                                                            }
                                                                        </div>
                                                                        {/*<div className="i-sec i-or">or</div>
                                                                        <div className="i-sec">
                                                                            <label className="i-sec-lbl" htmlFor={`container-id-${legIndex}`}>
                                                                                Container id
                                                                            </label>
                                                                            <input
                                                                                className="ip"
                                                                                id={`container-id-${legIndex}`}
                                                                                type="text"
                                                                                placeholder=""
                                                                                maxLength="32"
                                                                                autoComplete="off"
                                                                                value={leg.container_id}
                                                                                onChange={(e) => {
                                                                                    const {shipmentData} = this.state;
                                                                                    shipmentData.legs[legIndex].container_id = e.target.value;
                                                                                    shipmentData.updated = true;
                                                                                    this.setState({shipmentData});
                                                                                }}
                                                                                disabled={disableEdit}
                                                                            />
                                                                            {
                                                                                shipmentData.errors && shipmentData.errors[`legs.${legIndex}.mmsi`] ?
                                                                                <div className="error_msg">{shipmentData.errors[`legs.${legIndex}.mmsi`]}</div>
                                                                                :
                                                                                shipmentData.errors && shipmentData.errors[`legs.${legIndex}.container_id`] ?
                                                                                <div className="error_msg">{shipmentData.errors[`legs.${legIndex}.container_id`]}</div>
                                                                                :
                                                                                <></>
                                                                            }
                                                                            </div>*/}
                                                                        </>
                                                                        :
                                                                        leg.mode === 'Air' ?
                                                                        <>
                                                                        <div>
                                                                            <label className="i-sec-lbl" htmlFor={`awb-${legIndex}`}>
                                                                                AWB #
                                                                            </label>
                                                                            <input
                                                                                className="ip"
                                                                                id={`awb-${legIndex}`}
                                                                                type="text"
                                                                                placeholder=""
                                                                                autoComplete="off"
                                                                                maxLength="32"
                                                                                value={leg.awb}
                                                                                onChange={(e) => {
                                                                                    const {shipmentData} = this.state;
                                                                                    shipmentData.legs[legIndex].awb = e.target.value;
                                                                                    shipmentData.updated = true;
                                                                                    this.setState({shipmentData});
                                                                                }}
                                                                                disabled={disableEdit}
                                                                            />
                                                                            {
                                                                            shipmentData.errors && shipmentData.errors[`legs.${legIndex}.awb`] ?
                                                                            <div className="error_msg a">{shipmentData.errors[`legs.${legIndex}.awb`]}</div>
                                                                            : <></>
                                                                            }
                                                                        </div>
                                                                        </>
                                                                        : ''
                                                                    }
                                                                </div>
                                                                <div className="i-sec i-carrier-sec">
                                                                    <label className="i-sec-lbl" htmlFor={`mode-${legIndex}`}>
                                                                        Carrier
                                                                    </label>
                                                                    <input
                                                                        className="ip"
                                                                        id={`carrier-${legIndex}`}
                                                                        type="text"
                                                                        placeholder=""
                                                                        maxLength="32"
                                                                        autoComplete="off"
                                                                        value={leg.carrier_name||''}
                                                                        onChange={(e) => {
                                                                            document.body.removeEventListener('click', this.closeCarrierTypePopup);
                                                                            document.body.addEventListener('click', this.closeCarrierTypePopup);
                                                                            const {shipmentData} = this.state;
                                                                            shipmentData.legs[legIndex].carrier_name = e.target.value;
                                                                            shipmentData.legs[legIndex].showCarrierTypePopup = true;
                                                                            // const CARRIER = this.props.carrierTypeList.filter(ct => ct.carrier_name === leg.carrier);
                                                                            // if(CARRIER.length > 0) {
                                                                            //     shipmentData.legs[legIndex].carrier = CARRIER[0].id;
                                                                            // }
                                                                            shipmentData.updated = true;
                                                                            this.setState({shipmentData});
                                                                        }}
                                                                        disabled={disableEdit}
                                                                    />
                                                                    {
                                                                        leg.showCarrierUpdate && !disableEdit ?
                                                                        <span 
                                                                        className={`add-new-u carrier${leg.carrier_name ? '' : ' d'}`}
                                                                            onClick={e => {
                                                                                e.stopPropagation();
                                                                                if(leg.carrier_name?.length) {
                                                                                    this.props.saveCarrierType({
                                                                                        id: leg.carrier, 
                                                                                        carrier_name: leg.carrier_name,
                                                                                        onComplete: (data) => {
                                                                                            const {shipmentData} = this.state;
                                                                                            shipmentData.legs[legIndex].showCarrierUpdate = false;
                                                                                            this.onAddCarrierType({data: data, shipmentData, legIndex});
                                                                                        }
                                                                                    });
                                                                                }
                                                                            }}
                                                                        >Update</span>
                                                                        :
                                                                        (
                                                                            this.props.carrierTypeList.filter(c => c.mode === leg.mode && c.carrier_name === leg.carrier_name).length && disableEdit ?
                                                                            <></>
                                                                            :
                                                                            <span 
                                                                                className={`add-new-u carrier${leg.carrier_name ? '' : ' d'}`}
                                                                                onClick={e => {
                                                                                    e.stopPropagation();
                                                                                    if(leg.carrier_name?.length) {
                                                                                        this.props.saveCarrierType({
                                                                                            mode: leg.mode, 
                                                                                            carrier_name: leg.carrier_name,
                                                                                            onComplete: (data) => {
                                                                                                const {shipmentData} = this.state;
                                                                                                shipmentData.legs[legIndex].showCarrierUpdate = false;
                                                                                                this.onAddCarrierType({data: data, shipmentData, legIndex});
                                                                                            }
                                                                                        });
                                                                                    }
                                                                                }}
                                                                            >Add New</span>
                                                                        )
                                                                    }
                                                                    { leg.showCarrierTypePopup && !disableEdit ?
                                                                    <ul className="search-cat-pop pro-type">
                                                                        {this.props.carrierTypeList.filter(c => c.mode === leg.mode && c.carrier_name?.toLowerCase().indexOf(leg.carrier_name?.toLowerCase())>=0).map((carrier, i) => (
                                                                            <li
                                                                                className={`sel-item prod-t-item`}
                                                                                key={`carrier-type-${legIndex}-${i}`}
                                                                                data-id={carrier.carrier_name}
                                                                            >
                                                                                <div 
                                                                                    className="prod-lbl" 
                                                                                    data-type="add"
                                                                                    value={carrier.carrier_name}
                                                                                    key={`p-t-i-${i}`}
                                                                                    onClick={e => {
                                                                                        e.stopPropagation();
                                                                                        const {shipmentData} = this.state;
                                                                                        shipmentData.legs[legIndex].carrier_name = carrier.carrier_name;
                                                                                        shipmentData.legs[legIndex].carrier = carrier.id;
                                                                                        shipmentData.legs[legIndex].showCarrierTypePopup = false;
                                                                                        delete(shipmentData.legs[legIndex].showCarrierUpdate);
                                                                                        this.setState({shipmentData});
                                                                                    }}
                                                                                >{carrier.carrier_name}</div>
                                                                                <div style={{width:'48px', marginRight: '6px'}}>
                                                                                    <span 
                                                                                        className="prod-action e" 
                                                                                        data-type="edit"
                                                                                        onClick={e => {
                                                                                            e.stopPropagation();
                                                                                            const {shipmentData} = this.state;
                                                                                            shipmentData.legs[legIndex].carrier_name = carrier.carrier_name;
                                                                                            shipmentData.legs[legIndex].carrier = carrier.id;
                                                                                            shipmentData.legs[legIndex].showCarrierUpdate = true;
                                                                                            shipmentData.legs[legIndex].showCarrierTypePopup = false;
                                                                                            this.setState({shipmentData});
                                                                                        }}
                                                                                    >{EditIcon({fill: '#979797'})}</span>
                                                                                    <span 
                                                                                        className="prod-action d" 
                                                                                        data-type="delete"
                                                                                        onClick={async e => {
                                                                                            e.stopPropagation();
                                                                                            if(this.props.carrierTypeSaving) {}
                                                                                            else {
                                                                                                //console.log('deleting carrier type');
                                                                                                await this.props.deleteCarrierType({
                                                                                                    id: carrier.id,
                                                                                                    onComplete: (data, error) => {
                                                                                                        if(data.success || !error) {
                                                                                                            const carrierTypeList = [...this.props.carrierTypeList];
                                                                                                            const cIndex = carrierTypeList.findIndex(c => c.id === carrier.id);
                                                                                                            carrierTypeList.splice(cIndex, 1);
                                                                                                            this.props.getCarrierTypeListSuccess(carrierTypeList);
                                                                                                            //console.log(false, false, data, 'delete', '<<<<<<<<<------------');

                                                                                                            const {shipmentData} = this.state;
                                                                                                            shipmentData.legs.map(leg => {
                                                                                                                if(leg.carrier === carrier.carrier_name) {
                                                                                                                    leg.carrier = '';
                                                                                                                    leg.carrier_name = '';
                                                                                                                    leg.showCarrierTypePopup = false;
                                                                                                                    leg.showCarrierUpdate = false;
                                                                                                                }
                                                                                                            });                                                                                            
                                                                                                            
                                                                                                            this.setState({shipmentData});
                                                                                                            this.onDeleteCarrierType({message: data.message, success: true, shipmentData});
                                                                                                        } else {
                                                                                                            this.onDeleteCarrierType({message: data.message, success: false});
                                                                                                        }                                            
                                                                                                    },
                                                                                                });
                                                                                            }
                                                                                        }}
                                                                                    >{TrashIcon({fill:'#979797'})}</span>
                                                                                </div>
                                                                            </li>
                                                                        ))
                                                                        }                                                       
                                                                    </ul>
                                                                    : <></>
                                                                    }
                                                                    {
                                                                        shipmentData.errors && shipmentData.errors[`legs.${legIndex}.carrier`] ?
                                                                        <div className="error_msg">{shipmentData.errors[`legs.${legIndex}.carrier`]}</div>
                                                                        : <></>
                                                                    }
                                                                </div>
                                                                <div className="i-sec">
                                                                    <div htmlFor="profile_name" className="fld-lbl" style={{marginRight: '20px', position: 'unset', width: 'unset'}}>Communication interval <i className="material-icons t-tip">info<span className="t-txt">Configure device communication. InMotion and NoMotion applies for TRAK-X devices. Sampling and Reporting applies for TRAK-X1, TRAK-NXT devices</span></i></div>
                                                                    <div className="config-sec-2-col">
                                                                        <div className="config-sec-col-l">
                                                                            <div className="fld-lbl-2">TRAK-X:</div>
                                                                            <div className="f-l">
                                                                                <div className="fld-lbl">Inmotion:</div>
                                                                                <SelectBox
                                                                                    style={{ width: '160px' }}
                                                                                    useProps
                                                                                    list={INMOTION}
                                                                                    value={leg.in_motion_interval || ''}
                                                                                    onChange={o => {
                                                                                        const {shipmentData} = this.state;
                                                                                        shipmentData.legs[legIndex].in_motion_interval = o.id;
                                                                                        shipmentData.updated = true;
                                                                                        this.setState({shipmentData});
                                                                                    }}
                                                                                    disable={disableIntransitEdit}
                                                                                />
                                                                                {
                                                                                    shipmentData.errors && shipmentData.errors[`legs.${legIndex}.in_motion_interval`] ?
                                                                                    <div className="error_msg a" style={{marginTop:'40px'}}>{shipmentData.errors[`legs.${legIndex}.in_motion_interval`]}</div>
                                                                                    : <></>
                                                                                }
                                                                            </div>
                                                                            <div className="f-l">
                                                                                <div className="fld-lbl">Nomotion:</div>
                                                                                <SelectBox
                                                                                    style={{ width: '160px' }}
                                                                                    useProps
                                                                                    list={NOMOTION}
                                                                                    value={leg.no_motion_interval || ''}
                                                                                    onChange={o => {
                                                                                        const {shipmentData} = this.state;
                                                                                        shipmentData.legs[legIndex].no_motion_interval = o.id;
                                                                                        shipmentData.updated = true;
                                                                                        this.setState({shipmentData});
                                                                                    }}
                                                                                    disable={disableIntransitEdit}
                                                                                />
                                                                                {
                                                                                    shipmentData.errors && shipmentData.errors[`legs.${legIndex}.no_motion_interval`] ?
                                                                                    <div className="error_msg a" style={{marginTop:'40px'}}>{shipmentData.errors[`legs.${legIndex}.no_motion_interval`]}</div>
                                                                                    : <></>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="config-sec-col-r">
                                                                            <div className="fld-lbl-2">TRAK-X1, TRAK-NXT:</div>
                                                                            <div className="f-l">
                                                                                <div className="fld-lbl">Sampling:</div>
                                                                                <SelectBox
                                                                                    style={{ width: '160px' }}
                                                                                    useProps
                                                                                    list={SAMPLING}
                                                                                    value={leg.monitor_period || ''}
                                                                                    onChange={o => {
                                                                                        const {shipmentData} = this.state;
                                                                                        shipmentData.legs[legIndex].monitor_period = o.id;
                                                                                        shipmentData.updated = true;
                                                                                        this.setState({shipmentData});
                                                                                    }}
                                                                                    disable={disableIntransitEdit}
                                                                                />
                                                                                {
                                                                                    shipmentData.errors && shipmentData.errors[`legs.${legIndex}.monitor_period`] ?
                                                                                    <div className="error_msg a" style={{marginTop:'40px'}}>{shipmentData.errors[`legs.${legIndex}.monitor_period`]}</div>
                                                                                    : <></>
                                                                                }
                                                                            </div>
                                                                            <div className="f-l">
                                                                                <div className="fld-lbl">Reporting:</div>
                                                                                <SelectBox
                                                                                    style={{ width: '160px' }}
                                                                                    useProps
                                                                                    list={REPORTING}
                                                                                    value={leg.reporting_period || ''}
                                                                                    onChange={o => {
                                                                                        const {shipmentData} = this.state;
                                                                                        shipmentData.legs[legIndex].reporting_period = o.id;
                                                                                        shipmentData.updated = true;
                                                                                        this.setState({shipmentData});
                                                                                    }}
                                                                                    disable={disableIntransitEdit}
                                                                                />
                                                                                {
                                                                                    shipmentData.errors && shipmentData.errors[`legs.${legIndex}.reporting_period`] ?
                                                                                    <div className="error_msg a" style={{marginTop:'40px'}}>{shipmentData.errors[`legs.${legIndex}.reporting_period`]}</div>
                                                                                    : <></>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="i-sec" style={{paddingTop:'8px'}}>
                                                                    <div className="d-f-a-c">
                                                                        <div className={`status-bar s${leg.allow_sensor_data ? ' a' : ''}${disableEdit ? ' d': ''}`}
                                                                            onClick={ (e) => {
                                                                                e.stopPropagation();
                                                                                if(disableEdit) {}
                                                                                else {
                                                                                    const {shipmentData} = this.state;
                                                                                    shipmentData.legs[legIndex].allow_sensor_data = leg.allow_sensor_data ? 0 : 1;
                                                                                    shipmentData.updated = true;
                                                                                    this.setState({shipmentData});
                                                                                }
                                                                                
                                                                            }}
                                                                        ></div>
                                                                        <div className="fld-lbl" style={{marginLeft: '16px'}}>Enable sensor recording and alerts</div>
                                                                    </div>
                                                                    {
                                                                        shipmentData.errors && shipmentData.errors[`legs.${legIndex}.allow_sensor_data`] ?
                                                                        <div className="error_msg">{shipmentData.errors[`legs.${legIndex}.allow_sensor_data`]}</div>
                                                                        : <></>
                                                                    }
                                                                </div>
                                                                <div className="i-sec n-b" style={{alignItems: "flex-start"}}>
                                                                    <div className="i-sec-lbl">Alert additional users</div>
                                                                    <div className="i-fld notify-users">
                                                                    {
                                                                    leg.notify_additional_users?.length ?
                                                                        <div className="selected-items">
                                                                            <ul className="group-selected-companies no-scroll" key={`selected-users-list-${this.props.shipmentID}-${legIndex}`}>
                                                                                {leg.notify_additional_users.map((user, uIndex) => (
                                                                                    <li
                                                                                        key={`ship-user-${uIndex}`}
                                                                                        className={`group-selected-companies-li`}
                                                                                        style={disableIntransitEdit ? {cursor: 'not-allowed'} : {}}
                                                                                        title={user}
                                                                                    >
                                                                                        {user}
                                                                                        {
                                                                                            disableIntransitEdit ?
                                                                                            <></>
                                                                                            :
                                                                                            <span
                                                                                                onClick={(e) => {
                                                                                                    e.stopPropagation();
                                                                                                    const {shipmentData} = this.state;
                                                                                                    let userIndex = shipmentData.legs[legIndex].notify_additional_users.findIndex(u => u === user);
                                                                                                    if(userIndex >= 0) {
                                                                                                        shipmentData.legs[legIndex].notify_additional_users.splice(userIndex, 1);
                                                                                                    }
                                                                                                    
                                                                                                    this.setState({shipmentData});
                                                                                                }}
                                                                                            >x</span>
                                                                                        }
                                                                                    </li>
                                                                                )
                                                                                )}
                                                                            </ul>
                                                                            {
                                                                                leg.notify_additional_users.filter(u => !u.delete).length > 0 && !disableIntransitEdit ?
                                                                                <span
                                                                                    className="group-select-delete-all"
                                                                                    onClick={() => {
                                                                                        const {shipmentData} = this.state;
                                                                                        if(shipmentData.legs[legIndex].notify_additional_users?.length) {
                                                                                            shipmentData.legs[legIndex].notify_additional_users.length = [];
                                                                                            this.setState({shipmentData});
                                                                                            document.getElementById(`add_additional_users_${legIndex}`).focus();
                                                                                        }
                                                                                    }}
                                                                                >Clear all</span> : ''
                                                                            }                            
                                                                        </div> : ''
                                                                    }
                                                                    <input
                                                                        type="text"
                                                                        className="ip-fld"
                                                                        placeholder="Add Email..."
                                                                        autoComplete='off'
                                                                        name="users"
                                                                        maxLength="70"
                                                                        id={`add_additional_users_${legIndex}`}
                                                                        value={leg.addUserSearch || ''}
                                                                        onChange={(e) => {
                                                                            const {shipmentData} = this.state;
                                                                            shipmentData.legs[legIndex].addUserSearch = e.target.value;
                                                                            this.setState({shipmentData});
                                                                        }}
                                                                        disabled={(leg.notify_additional_users?.length > 0 && leg.notify_additional_users.length >= this.state.config.maxUsers) || disableIntransitEdit ? true : false}
                                                                    />
                                                                    {                                                        
                                                                        leg.addUserSearch?.length >= 1 && !disableIntransitEdit ?
                                                                        <ul className="search-cat-pop" style={{ top: 'unset', bottom: '96px' }}>                                            
                                                                            {LEG_USERS[legIndex]?.map((user, i) => (
                                                                                <li
                                                                                    className={`sel-item`}
                                                                                    key={`search-user-${i}`}
                                                                                    data-id={user.id}
                                                                                    title={user.email}
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        const {shipmentData} = this.state;
                                                                                        const userIndex = leg.notify_additional_users?.findIndex(u => u === user.email) || -1;
                                                                                        if(userIndex >= 0) {
                                                                                        } else {
                                                                                            shipmentData.legs[legIndex].notify_additional_users = shipmentData.legs[legIndex].notify_additional_users || [];
                                                                                            shipmentData.legs[legIndex].notify_additional_users.push(user.email);
                                                                                        }
                                                                                        shipmentData.legs[legIndex].addUserSearch = '';
                                                                                        this.setState({shipmentData});
                                                                                        document.getElementById(`add_additional_users_${legIndex}`).focus();
                                                                                    }
                                                                                    }
                                                                                >
                                                                                    {user.email}
                                                                                </li>
                                                                            ))}
                                                                            {
                                                                                LEG_USERS[legIndex].length === 0 ?
                                                                                <>
                                                                                <li
                                                                                    className="no-item"
                                                                                    key={`search-user-no-users`}>No users found...</li>
                                                                                </>
                                                                                : ''
                                                                            }
                                                                        </ul>
                                                                        : ''
                                                                    }
                                                                    {(leg.notify_additional_users?.length || 0) < this.state.config.maxUsers && LEG_USERS[legIndex].length === 0 && new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i).test(leg.addUserSearch) && !disableIntransitEdit ? 
                                                                    <span 
                                                                        className="add-new-u u"
                                                                        onClick={e => {
                                                                            e.stopPropagation();
                                                                            const {shipmentData} = this.state;
                                                                            shipmentData.legs[legIndex].notify_additional_users = shipmentData.legs[legIndex].notify_additional_users || [];
                                                                            const userIndex = shipmentData.legs[legIndex].notify_additional_users.findIndex( u => u === leg.addUserSearch);
                                                                            if(userIndex >= 0 ) {
                                                                                
                                                                            } else {
                                                                                shipmentData.legs[legIndex].notify_additional_users.push(leg.addUserSearch);
                                                                            }
                                                                            
                                                                            shipmentData.legs[legIndex].addUserSearch = '';
                                                                            this.setState({shipmentData});
                                                                            document.getElementById(`add_additional_users_${legIndex}`).focus();
                                                                        }}
                                                                    >Add Email</span> : ''}
                                                                    </div>
                                                                </div>
                                                                {LEG_USERS[legIndex].length >= this.state.config.maxUsers ? 
                                                                    <div className="error_msg a" style={{marginTop:'-32px'}}>* Maximum user limit({this.state.config.maxUsers}) reached!</div> 
                                                                    : 
                                                                    shipmentData.errors && shipmentData.errors[`legs.${legIndex}.notify_additional_users`] ?
                                                                    <div className="error_msg a" style={{marginTop:'-32px'}}>{shipmentData.errors[`legs.${legIndex}.notify_additional_users`]}</div>
                                                                    : <></>
                                                                }
                                                            </div>
                                                            : 
                                                            <div 
                                                                className="s-p-edit-config"
                                                                onClick={e => {
                                                                    e.stopPropagation();
                                                                    this.toggleLegConfig(legIndex);
                                                                }}
                                                            >
                                                                <span className="material-icons i-config-icn">settings</span>
                                                                <span className="lbl">Edit Leg-{legIndex+1} Configuration</span>
                                                            </div>
                                                        }
                                                        </>
                                                        : 
                                                        <></>
                                                    }
                                                    {
                                                        shipmentData.errors && (shipmentData.errors[`legs.${legIndex}`] || shipmentData.errors[`legs.${legIndex}.id`]) ?
                                                        <div className="error_msg" style={leg.leg_type==='destination'?{margin:'20px 0 0 24px'}:{margin:'20px 0 0 40px'}}>{shipmentData.errors[`legs.${legIndex}`] || shipmentData.errors[`legs.${legIndex}.id`]}</div>
                                                        : <></>
                                                    }
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                {
                                    shipmentData.errors && shipmentData.errors[`legs`] ?
                                    <div className="error_msg" style={{marginLeft:"66px"}}>{shipmentData.errors[`legs`]}</div>
                                    : <></>
                                }
                                {
                                    shipmentData.legs.filter(l=>!l.delete)?.length < (this.state.config.maxStops + 2) && !shipmentData.round_trip && !this.props.disabledEdit && (shipmentData.status !== 'Destination' && shipmentData.status !== 'Completed') && !isDueDateNotified ?
                                    <div style={{margin:'32px'}}>
                                        <div 
                                            className={`c-btn s`}
                                            style={this.props.carrierTypeListLoading || this.props.usersLoading || (this.props.shipmentEdit ? false : (this.props.shipmentProfileLoading || this.props.shipmentProfileListLoading)) ? {cursor: 'not-allowed', opacity: '0.5'} : {}}
                                            onClick={(e)=>{
                                                e.stopPropagation();
                                                this.addStop();                                         
                                            }}
                                        >+ Add Stop</div>
                                    </div>
                                    : <></>
                                }
                            </div>
                            </>                                
                        } 
                        </PerfectScrollbar>
                        </div>
                        <div className="n-form-btm">
                            {
                                enableETA() && this.state.shipmentAssetType === 'carrier' ?
                                <>
                                <div style={{ height: '5px' }}>
                                    {this.props.dashboard && this.props.dashboard.createShipmentLoading ? (<div className="loading" />) : <></>}
                                </div>
                                <div
                                    className={
                                        `c-btn${this.state.newShipTrackingNumber !== '' &&
                                            this.state.newShipTrackingNumber.match(/^(?=.*\d)[a-z0-9]+$/i) &&
                                            (this.state.newShipCarrier !== '' || (CARRIER && CARRIER.length)) &&
                                            !(this.props.dashboard && this.props.dashboard.createShipmentLoading) ?
                                            '' :
                                            ' invalid'
                                        }`
                                    }
                                    onClick={this.createShipment}
                                >
                                    Create Shipment
                                </div>
                                </>
                                :
                                <>
                                <div style={{ height: "5px", position: "absolute", left: "20px", right: "20px", top: "-8px" }}>
                                    {this.props.createNewDeviceShipmentLoading ? <div className="loading" /> : ''}
                                </div>
                                <div
                                    className={
                                        `c-btn${this.props.createNewDeviceShipmentLoading || this.props.carrierTypeListLoading || this.props.usersLoading || (this.props.shipmentEdit ? false : (this.props.shipmentProfileLoading || this.props.shipmentProfileListLoading)) ? ' invalid' : ''}`
                                    }
                                    onClick={e => {
                                        if(this.props.createNewDeviceShipmentLoading || this.props.carrierTypeListLoading || this.props.usersLoading || (this.props.shipmentEdit ? false : (this.props.shipmentProfileLoading || this.props.shipmentProfileListLoading))) {}
                                        else {
                                            this.createNewDeviceShipment(e);
                                        }
                                    }}
                                >
                                    {this.props.shipmentEdit ? 'Update' : 'Create'} Shipment
                                </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <SweetAlert
                show={this.state.shipmentCreatedPopup}
                title={this.state.shipmentCreatedMessage}
                status={this.state.shipmentCreatedStatus}
                displayDuration={this.state.showSuccessDuration}
                className={'shipment-created'}
                onClose={() => {
                    this.setState({ shipmentCreatedPopup: false });
                }}
            />
            {
                this.state.showShipmentProfilePop ?
                <>
                <ShipmentProfileConfiguration
                    key={`shipment-profile-preview-${shipmentData.shipment_profile_id}`}
                    SHIPMENT_PROFILE_DETAILS={this.props.shipmentProfile}
                    shipment_profile_id={shipmentData.shipment_profile_id}
                    carrierTypeList={shipmentData?.legs?.filter(l => l.carrier)?.map(l=>({carrier_name: l.carrier_name || '---', id: l.carrier}))}
                    configurationProfileList={[{profile_name: this.props.shipmentProfile?.config?.device_configuration_profile_name, id: this.props.shipmentProfile?.config.device_configuration_profile_id}]}
                    closeShipmentProfileConfiguration={this.closeShipmentProfileConfiguration}
                />
                </>
                : ''
            }
            {
                this.state.showShipmentMetricConfigurationPop ?
                <>
                <ShipmentMetricConfiguration
                    key={`shipment-metric-configuration-preview-${shipmentData.shipment_profile_id}`}
                    shipment_id={shipmentData.id}
                    configuration_profile_id={shipmentData.device_configuration_profile_id}
                    status={shipmentData.status}
                    closeShipmentMetricConfiguration={this.closeShipmentMetricConfiguration}
                    disableEdit={this.props.disableEdit || !this.props.shipmentEdit}
                />
                </>
                : ''
            }
            {
            this.state.switchToRoundTripConfirm ?
            <SweetAlert
                title={<>Round trip can only have one stop. Enabling this will delete the additional stops. <br/>Please confirm to proceed?</>}
                status='warning'
                show={this.state.switchToRoundTripConfirm}
                showCancelBtn={true}
                cancelBtnText="CANCEL"
                confirmBtnText="CONFIRM"
                className="shipment-round-trip"
                onCancelClick={() => {
                    this.setState({
                        switchToRoundTripConfirm: false
                    })
                }}
                onConfirmClick={()=>{
                    this.switchToRoundTrip(true);
                }}
                ></SweetAlert>
            :
            ''
            }
            <SweetAlert
                show={this.state.showSaveSuccess}
                title={this.state.saveSuccessTitle}
                status={this.state.saveSuccessStatus}
                onClose={() => {
                    this.setState({ showSaveSuccess: false });
                }}
                className="carrier-type-status"
            />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        carriers: state.dashboard.carriers,
        carriersLoading: state.dashboard.carriersLoading,
        createShipment: state.dashboard.createShipment,
        companyShipmentCarrierConfiguration: state.configuration.getCompanyShipmentCreationForCarrierData,
        shipmentProfileListLoading: state.configuration.shipmentProfileListLoading,
        shipmentProfileList: state.configuration.shipmentProfileList,
        shipmentProfileLoading: state.configuration.shipmentProfileLoading,
        shipmentProfile: state.configuration.shipmentProfile,
        carrierTypeListLoading: state.configuration.carrierTypeListLoading,
        carrierTypeList: state.configuration.carrierTypeList,
        usersLoading: state.user.loading,
        users: state.user.users,
        createNewDeviceShipmentLoading: state.dashboard.createNewDeviceShipmentLoading,
        assignDeviceToShipmentLoading: state.dashboard.assignDeviceToShipmentLoading,
        assignDeviceToShipmentErrors: state.dashboard.assignDeviceToShipmentErrors,
        assignDeviceToShipmentMessage: state.dashboard.assignDeviceToShipmentMessage
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCreateNewShipment: (obj) => dispatch(actions.createNewShipment(obj)),
        onGetCarriers: (obj) => dispatch(actions.getCarriers(obj)),
        getShipmentProfileList: (obj) => dispatch(actions.getShipmentProfileList(obj)),
        getShipmentProfile: (obj) => dispatch(actions.getShipmentProfile(obj)),
        onGetCarrierTypes: (obj) => dispatch(actions.getCarrierTypeList(obj)),
        getCarrierTypeListSuccess: (obj) => dispatch(actions.getCarrierTypeListSuccess(obj)),
        onGetUsers:(obj)=>dispatch(getUserList(obj)),
        onCreateNewDeviceShipment: (obj) => dispatch(actions.createNewDeviceShipment(obj)),
        deleteCarrierType: (obj) => dispatch(actions.deleteCarrierType(obj)),
        saveCarrierType: (obj) => dispatch(actions.saveCarrierType(obj)),
        onGetShipmentDetails: (obj) => dispatch(actions.getShipmentDetails(obj)),
        assignDeviceToShipment: (obj) => dispatch(actions.assignDeviceToShipment(obj)),
        getShipmentLocation: (obj) => dispatch(actions.getShipmentLocation(obj)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateShipment);

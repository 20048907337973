const apis = ()=> {
    return {
        "asset_tracking": {
            "refresh_token" : "users/refreshToken",
            "get_company_device_list" : (a) => `asset-tracking/company/${a}/devices`,
            "get_company_device_statuses" : (a) => `asset-tracking/company/${a}/devices/statuses`,
            "get_device_details" : (a,b,c) => `asset-tracking/company/${a}/device/${b}/messages?startDateTime=${c}`,
            "update_device_details" : (a,b) => `asset-tracking/company/${a}/device/${b}/metadata`,
            "get_company_geofences" : (a) => `asset-tracking/company/${a}/geofences`,
            "update_company_geofences" : (a) => `asset-tracking/company/${a}/geofences`,
            "get_product_types" : (a) => `asset-tracking/company/${a}/product_types`,
            "update_product_type" : (a) => `asset-tracking/company/${a}/product_types`
        },
        "company" : {
            "get_company_list" : (a) => `company/index?limit=50000${a}`,
            "get_company_users_list": (a) => `users/index?company=${a}&limit=50000`,
            "add_new_company" : `company/save`,
            "add_new_sso_company" : `companies/sso`,
            "update_company" : (a) => `company/update/${a}`,
            "get_devices_by_company" : (a) => `device/index?limit=50000&company=${a}`,
            "get_shipment_count_by_company" : (a) => `dashboard/shipmentStatistics?companyID=${a}`,
            "delete_company" : (a) => `company/delete/${a}`,
            "get_company_details" : (a) => `company/details/${a}`,
            "get_group_list": 'groups/index?limit=50000',
            "get_group_users_list" : (a) => `users/index?group=${a}&limit=50000`,
            "get_companies_by_group" : (a) => `company/index?group=${a}&limit=50000`,
            "add_new_group" : `groups/save`,
            "update_group" : (a) => `groups/update`,
            "get_devices_by_group" : (a) => `device/index?limit=50000&group=${a}`,
            "get_shipment_count_by_group" : (a) => `dashboard/shipmentStatistics?groupID=${a}`,
            "delete_group" : (a) => `groups/delete?groupId=${a}`,
            "view_group" : (a) => `groups/view?groupId=${a}`,
            "assign_company_to_group" : (a) => `groups/assignCompanyToGroup`,
            "remove_company_from_group" : (a) => `groups/removeAssignedCompany`,
            "make_as_group_admin" : (a) => `groups/makeCompanyAsGroupAdmin`,
            "get_company_features" : (a) => `company/configure?companyId=${a}`,
            "update_company_features" : (a) => 'company/configure',
            "get_company_product_status_template" : (a) => `product-status-config?company_id=${a}`,
            "update_company_product_status_template" : (a) => `product-status-config${a}`
        },
        "configuration" : {
            "update_profile" : (a) => `users/${a}`,
            "get_company_list" : 'company/index?limit=50000',
            "get_configuration_profile_list" : (a) => `configuration-profiles?company_id=${a}&size=200`,
            "get_configuration_profile" : (a) => `configuration-profiles/${a}`,
            "save_configuration_profile" : (a) => `configuration-profiles${a}`,
            "delete_configuration_profile" : (a) => `configuration-profiles/${a}`,
            "get_alert_profile_list" : (a) => `alertprofiles?companyID=${a}`,
            "get_alert_profile" : (a) => `alertprofiles/${a}`,
            "save_alert_profile" : `alertprofiles`,
            "delete_alert_profile" : (a) => `alertprofiles/${a}`,
            "get_product_profile_list" : (a) => `product-profiles?${a}`,
            "get_product_profile" : (a) => `product-profiles/${a}`,
            "save_product_profile" : (a) => `product-profiles${a}`,
            "delete_product_profile" : (a) => `product-profiles/${a}`,
            "get_product_type_list" : (a) => `product-types?company_id=${a}&size=50000`,
            "save_product_type" : (a) => `product-types${a}`,
            "delete_product_type" : (a) => `product-types/${a}`,
            "get_product_type" : (a) => `product-types/${a}`,
            "get_shipment_profile_list" : (a) => `shipment-profiles?company_id=${a}&size=200`,
            "get_shipment_profile" : (a) => `shipment-profiles/${a}`,
            "save_shipment_profile" : (a) => `shipment-profiles${a}`,
            "delete_shipment_profile" : (a) => `shipment-profiles/${a}`,
            "get_carrier_type_list" : `carriers?size=80000`,
            "save_carrier_type" : (a) => `carriers${a}`,
            "delete_carrier_type" : (a) => `carriers/${a}`,
            "get_carrier_type" : (a) => `carriers/${a}`,
            "get_lane_id_list" : (a) => `lanes?company_id=${a}`,
            "save_lane_id" : (a) => `lanes${a}`,
            "delete_lane_id" : (a) => `lanes/${a}`,
            "get_lane_id" : (a) => `lanes/${a}`,
            "get_package_type_list" : (a) => `package-types?company_id=${a}`,
            "save_package_type" : (a) => `package-types${a}`,
            "delete_package_type" : (a) => `package-types/${a}`,
            "get_package_type" : (a) => `package-types/${a}`,
        },
        "dashboard" : {
            "get_dashboard_details" : `dashboard/index`,
            "get_dashboard_config_list" : `dashboard/configure`,
            "set_dashboard_config" : `dashboard/configure`,
            "set_dashboard_config_order" : `dashboard/configorder`,
            "get_shipment_list" : `shipment/list`,
            "get_dashboard_shipment_list" : `dashboard/main`,
            "get_company_list" : `company/index`,
            "get_device_shipment_details" : (a) => `shipments/${a}`,
            "get_carrier_shipment_details" : (a) => `shipment/details/${a}`,
            "get_shipment_log" : (a) => `shipment/log/${a}`,
            "get_shipment_geo_json" : (a) => `shipment/geoJson/${a}`,
            "get_shipment_tracking_map" : (a) => `shipment/tracking/map/${a}`,
            "create_new_shipment" : `shipment/createWithTrackingId`,
            "create_new_device_shipment" : (a) => `shipments${a}`,
            "get_shipment_tracker_data" : (a) => `shipments/trackerdata/${a}`,
            "get_carriers_list" : `carrier/list`,
            "get_notification_list" : (a) => `notification/list?page=${a}`,
            "get_unread_notification_count" : `notification/unreadCount`,
            "set_notification_read" : `notification/markRead`,
            "update_shipment_status" : 'shipment/updateStatus',
            "update_shipment_details" : 'shipment/update',
            "save_shipment_geofence" : 'shipment/saveGeofence',
            "delete_shipment_geofence" : (a) => `shipment/deleteGeofence/${a}`,
            "get_shipment_configuration" : (a) => `shipment/configuration/${a}`,
            "update_shipment_configuration" : (a) => `shipment/configuration/${a}`,
            "get_shipment_reports" : (a) => `shipment/reports/${a}`,
            "create_shipment_report" : `shipment/reports`,
            "delete_shipment_report" : (a) => `shipment/reports/${a}`,
            "assign_device_to_shipment" : (a) => `shipment/${a}/assignDevice`,
            "shipments_location": () => `getShipmentLocation`,
        },
        "devices" : {
            "get_device_types" : 'device_type/index',
            "get_devices_list" : 'device/index?limit=50000',
            "save_device" : 'device/save',
            "multi_delete_device" : (a) => `device/inactiveDevices?ids=${a}`,
            "upload_devices" : 'device/upload_devices',
            "update_device_details" : (a) => `device/update/${a}`,
            "return_assign_device" : 'device/returnAssignedDeviceFromCompany',
            "assign_device_to_company" : 'device/assignDeviceToCompany',
        },
        "insights" : {
            "get_shipment_reports" : 'reports/shipment',
            "get_location_countries" : `region/countries`,
            "get_country_states" : (a) => `region/countries/${a}/states`,
            "get_state_cities" : (a,b) => `region/countries/${a}/states/${b}/cities`,
            "get_location_routes" : `region/routes`,
        },
        "login" : {
            "login": 'users/login',
            "update_profile" : (a) => `users/${a}`,
            "does_user_exists" : (a) => 'users/doesUserExists',
            "password_create": 'password/create',
            "password_token_verification" : (a) => `password/${a}`,
            "password_reset" : () => 'password/reset',
            "password_token_verification": (a) => `password/find/${a}`,
            "user_register" : 'users',
            "check_user_company" : 'users/company',
            "validate_user_code" : 'users/validateUserCode',
            "resend_user_code" : 'users/resendEmail',
            "resend_password_code" : 'password/resend_email',
            "update_password" : (a) => 'users/updatePassword',
            "get_pages_data" : (a) => `pages/details/${a}`,
            "accept_terms" : (a) => `users/accept_terms`,
            "get_user_details": (a) => `/users/logged-in`,
        },
        "pdf" : {
            "bulk_download": (a) => `/v1/download-csvzip/${a}`,
            "upload_document" : 'v1/store/document',
            "get_pdf_list" : (a) => `v1/documents?${a}`,
            "get_pdf_details" : (a) => `v1/documents/${a}`,
            "get_filters_list" : (a) => `v1/filters?${a}`,
            "bulk_upload_documents" : 'v1/bulk-uploads',
            "get_bulk_pdfs_list" : (a) => `v1/bulk-uploads?${a}`,
            "get_zip_pdfs_list" : (a) => `v1/bulk-uploads/${a}`,
            "get_pdf_parser_profile_list" : (a) => `v1/parser-profiles?company_id=${a}&size=500`,
            "get_pdf_parser_profile" : (a) => `v1/parser-profiles/${a}`,
            "save_pdf_parser_profile" : (a) => `v1/parser-profiles${a}`,
            "delete_pdf_parser_profile" : (a) => `v1/parser-profiles/${a}`,
            "get_pdf_product_profile_list" : (a) => `v1/product-profiles?company_id=${a}&size=500&page=1&sort=DESC`,
            "get_pdf_product_profile" : (a) => `v1/product-profiles/${a}`,
            "save_pdf_product_profile" : (a) => `v1/product-profiles${a}`,
            "delete_pdf_product_profile" : (a) => `v1/product-profiles/${a}`,
            "get_active_ingredient_list" : (a) => `v1/active-ingredients?company_id=${a}&size=50000`,
            "save_active_ingredient_list" : (a) => `v1/active-ingredients${a}`,
            "delete_active_ingredient_list" : (a) => `v1/active-ingredients/${a}`,
            "get_active_ingredient" : (a) => `v1/active-ingredients/${a}`,
            "get_strength_list" : (a) => `v1/strengths?company_id=${a}&size=50000`,
            "save_strength" : (a) => `v1/strengths${a}`,
            "delete_strength" : (a) => `v1/strengths/${a}`,
            "get_strength" : (a) => `v1/strengths/${a}`,
            "get_pdf_lane_id_list" : (a) => `v1/lanes?company_id=${a}`,
            "save_pdf_lane_id" : (a) => `v1/lanes${a}`,
            "delete_pdf_lane_id" : (a) => `v1/lanes/${a}`,
            "get_pdf_lane_id" : (a) => `v1/lanes/${a}`,
            "get_pdf_package_type_list" : (a) => `v1/package-types?company_id=${a}`,
            "save_pdf_package_type" : (a) => `v1/package-types${a}`,
            "delete_pdf_package_type" : (a) => `v1/package-types/${a}`,
            "get_pdf_package_type" : (a) => `v1/package-types/${a}`,
            "guest_token": "v1/guest-token",
            "auth_end_point": "/api/broadcasting/auth",
            "dashboard_token": "dashboard-token"
        },
        "riskiq" : {
            "get_lanes" : `v1/lanes`,
            "get_lane_details" : (a) => `v1/lanes/${a}`,
            "get_lane_stability" : (a,b) => `v1/lanes/${a}/stability${b?`/${b}`:''}`,
            "get_lane_insights" : (a) => `v1/lanes/${a}/insights`,
            "get_route_details" : (a) => `v1/routes/${a}`,
            "get_route_stability" : (a,b) => `v1/routes/${a}/stability${b?`/${b}`:''}`,
            "get_route_insights" : (a) => `v1/routes/${a}/insights`,
            "get_leg_stability" : (a,b) => `v1/legs/${a}/stability${b?`/${b}`:''}`,
            "get_leg_insights" : (a) => `v1/legs/${a}/insights`,
        },
        "users" : {
            "update_profile": (a) => `users/${a}`,
            "delete_user" : (a) => `users/disable/${a}`,
            "get_shipment_statistics" : (a) => `dashboard/shipmentStatistics?userID=${a}`,
            "activate_user" : (a) => `users/enable/${a}`,
            "get_user_list" : (a) => `users/index?limit=50000&${a?`company_id=${a}&companyId=${a}`:''}`
        },
        "help" : {
            "i_frame_src": 'https://contxt-help.paxafe.com/knowledge',
            "support_email": 'contact@paxafe.com',
            "api_help_email": "support@paxafe.com"
        }
    }
};
export default apis();